<template>
  <div class="c-error">
    <div class="c-error__effect">
      <Icon name="meforma" size="150" />
      <slot />
    </div>

    <Button class="c-error__btn v--bg-meforma-pink" to="/inicio">
      Ir para o início
    </Button>
  </div>
</template>
<script>
export default {
  name: 'error'
}
</script>
<style lang="stylus" scoped>
.c-error
  text-align center
  padding 60px 30px
  &__effect
    filter grayscale(1)
  &__btn
    font-size 22px !important
    padding 24px 32px !important
    margin-top 16px
</style>
