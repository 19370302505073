<template>
  <div class="p-home">
    <ProfileCard :user="$store.auth.user" />
    <HomeSwitcher @select="selectTab" :selected="tab" />
    <Spacing
      v-if="tab === 'Progresso'"
      class="p-home__grid p-home__grid-container"
      top="2"
    >
      <div class="p-home__grid--vertical">
        <ProgressCard
          title="Progresso"
          :loading="$wait.is('user/progress')"
          :percentage="useAbsoluteTotal ? undefined : total"
          :subtitle="useAbsoluteTotal ? '' : `${total}%`"
          :total="absoluteTotal"
          :value="absoluteCompletion"
          :theme="useAbsoluteTotal ? 'horas' : ''"
          :show-percentage="useAbsoluteTotal"
        >
          <template v-slot:options>
            <Button
              to="/reorganizar-disciplinas"
              v-tooltip="
                `Reorganize as disciplinas entre as carga-horárias. Útil para quando uma disciplina está sendo contada no tipo errado de carga-horária.`
              "
            >
              <Icon name="trello" />
            </Button>
            <Dropdown
              tooltip="Altere o cálculo de progresso"
              title="Escolha como calcular o progresso"
              hide-indicator
              placement="bottom-end"
              :changeNameOnSelect="false"
              :items="settings"
              @change="changeProgressCalc"
            >
              <Icon name="settings" />
            </Dropdown>
          </template>
        </ProgressCard>
        <div class="p-home__grid">
          <ProgressCard
            title="Obrigatórias"
            show-percentage
            :to="{
              path: '/disciplinas',
              query: { tipo: 'Disciplinas Obrigatórias' }
            }"
            :theme="requiredsTheme"
            :loading="$wait.is('user/progress')"
            :percentage="$store.auth.progress[this.type]?.percentage"
            :total="$store.auth.progress[this.type]?.total"
            :value="$store.auth.progress[this.type]?.done"
            :help="requiredsHelp"
          />
          <template v-for="hour in hours">
            <ProgressCard
              v-if="$store.auth.progress[hour.key]?.total"
              :key="hour.key"
              :title="hour.name"
              :to="
                hour.queryKey
                  ? { path: '/disciplinas', query: { tipo: hour.queryKey } }
                  : null
              "
              show-percentage
              :theme="hour.theme"
              :loading="$wait.is('user/progress')"
              :percentage="$store.auth.progress[hour.key]?.percentage"
              :total="$store.auth.progress[hour.key]?.total"
              :value="$store.auth.progress[hour.key]?.done"
            />
          </template>
        </div>
      </div>
      <div class="p-home__grid">
        <ComponentsTruthTable
          title="Semestres"
          :table="$store.component.truthTable"
          :loading="$wait.is('user/progress')"
        />
        <SemestersMap
          title="Aproveitamento"
          :table="$store.semester.mapTable()"
          :loading="$wait.is('user/progress')"
        />
      </div>
    </Spacing>

    <Spacing v-if="tab === 'Sugestões & Previsões'" top="2">
      <SuggestionsAndPredictions />
    </Spacing>

    <Spacing v-if="tab === 'Arquivos'" top="2">
      <UserPosts />
    </Spacing>
  </div>
</template>
<script>
import ProfileCard from '@/components/home/ProfileCard/ProfileCard'
import HomeSwitcher from '@/components/home/HomeSwitcher/HomeSwitcher'
import ProgressCard from '@/components/home/ProgressCard/ProgressCard'
import UserPosts from '@/components/home/UserPosts/UserPosts'
import ComponentsTruthTable from '@/components/home/ComponentsTruthTable/ComponentsTruthTable'
import SemestersMap from '@/components/home/SemestersMap/SemestersMap.vue'
import SuggestionsAndPredictions from '@/components/home/SuggestionsAndPredictions/SuggestionsAndPredictions.vue'

export default {
  title: 'Início - MeForma!',
  name: 'home',
  route: '/inicio',
  layout: 'painel',
  middleware: ['auth'],
  components: {
    ProfileCard,
    HomeSwitcher,
    ProgressCard,
    ComponentsTruthTable,
    SemestersMap,
    SuggestionsAndPredictions,
    UserPosts
  },
  beforeMount() {
    this.$store.auth.getProgress()
    this.$store.component.getComponentsMap()
    this.$store.semester.getSemestersMap()
  },
  data() {
    return {
      type:
        localStorage.percentageCalcMode &&
        localStorage.percentageCalcMode === 'required'
          ? 'required'
          : 'required_by_hour', // count or hours
      calcMode: localStorage.percentageCalcMode
        ? localStorage.percentageCalcMode
        : 'required',
      tab: 'Progresso',
      hours: [
        {
          name: 'Optativas',
          queryKey: 'Disciplinas Optativas',
          key: 'optional',
          theme: 'horas'
        },
        {
          name: 'Op. Científicas',
          queryKey: 'Disciplinas Op. Científicas',
          key: 'scientific_optional',
          theme: 'horas'
        },
        {
          name: 'Op. Grande Área',
          queryKey: 'Disciplinas Op. Grande Área',
          key: 'large_area_optional',
          theme: 'horas'
        },
        {
          name: 'Op. Humanísticas',
          queryKey: 'Disciplinas Op. Humanísticas',
          key: 'humanistic_optional',
          theme: 'horas'
        },
        {
          name: 'Op. Artísticas',
          queryKey: 'Disciplinas Op. Artísticas',
          key: 'artistic_optional',
          theme: 'horas'
        },
        {
          name: 'Op. Area de Concentração',
          queryKey: 'Op. Área Concentração',
          key: 'concentration_area_optional',
          theme: 'horas'
        },
        {
          name: 'Complementares',
          key: 'supplementary',
          theme: 'horas'
        },
        { name: 'Livres', key: 'free', theme: 'horas' }
      ],
      settings: [
        {
          name: 'Obrigatórias por unidade',
          value: 'required',
          description: 'Componentes com pesos iguais'
        },
        {
          name: 'Obrigatórias por horas',
          value: 'required_by_hours',
          description: 'Componentes com pesos iguais'
        },
        {
          name: 'Utilizar horas absolutas',
          value: 'absolute',
          description: 'O peso de um componente é o total de horas'
        }
      ]
    }
  },
  computed: {
    requiredsTheme() {
      return this.type === 'required_by_hour' ? 'horas' : 'disciplinas'
    },
    requiredsHelp() {
      return `Contador de ${
        this.type === 'required_by_hour' ? 'horas' : 'disciplinas'
      } obrigatórias`
    },
    total() {
      const progress = this.$store.auth.progress
      let types = [
        this.type,
        'free',
        'optional',
        'supplementary',
        'scientific_optional',
        'large_area_optional',
        'humanistic_optional',
        'artistic_optional',
        'concentration_area_optional'
      ]

      let total = 0
      let length = 0

      types.forEach((type) => {
        if (progress && progress[type] && progress[type].total > 0) {
          total +=
            progress[type].percentage > 100 ? 100 : progress[type].percentage
          length = length + 1
        }
      })

      return (length > 0 ? total / length : 0).toFixed(2)
    },
    absoluteTotal() {
      const progress = this.$store.auth.progress
      let total = 0
      let types = [
        'required_by_hour',
        'free',
        'optional',
        'supplementary',
        'scientific_optional',
        'large_area_optional',
        'humanistic_optional',
        'artistic_optional',
        'concentration_area_optional'
      ]
      types.forEach((type) => {
        if (progress && progress[type] && progress[type].total > 0) {
          total += progress[type].total
        }
      })
      return total
    },
    absoluteCompletion() {
      const progress = this.$store.auth.progress
      let total = 0
      let types = [
        'required_by_hour',
        'free',
        'optional',
        'supplementary',
        'scientific_optional',
        'large_area_optional',
        'humanistic_optional',
        'artistic_optional',
        'concentration_area_optional'
      ]
      types.forEach((type) => {
        if (progress && progress[type] && progress[type].total > 0) {
          total += progress[type].done
        }
      })
      return total
    },
    useAbsoluteTotal() {
      return this.calcMode === 'absolute'
    }
  },
  methods: {
    changeProgressCalc(item) {
      this.type = item.value === 'required' ? 'required' : 'required_by_hour'
      this.calcMode = item.value
      localStorage.setItem('percentageCalcMode', item.value)
    },
    selectTab(item) {
      this.tab = item.name
    }
  }
}
</script>
<style lang="stylus" scoped>
.p-home {
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 72px;
  @media (max-width 768px) {
    padding 0
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    grid-template-rows: min-content
    &--vertical {
      display: grid;
      grid-gap: 16px;
      grid-template-rows: min-content;
    }
    &-container {
      grid-template-columns: .85fr 1.15fr;
      @media(max-width: 1200px) {
        grid-template-columns: 1fr;
      }
    }
    @media(max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
