<template>
  <div class="c-home-switcher" :class="{ 'v--dark': $store.settings.darkMode }">
    <Button
      v-for="item in items"
      :key="item.name"
      class="c-home-switcher__item v--bg-white"
      :class="{ 'c-home-switcher__item--active': selected === item.name }"
      @click="$emit('select', item)"
    >
      {{ item.name }}
    </Button>
  </div>
</template>
<script>
export default {
  name: 'HomeSwitcher',
  props: {
    selected: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      items: [
        { name: 'Progresso' },
        { name: 'Sugestões & Previsões' }
        // { name: 'Arquivos' }
      ]
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
