<template>
  <div v-on:click="isOpenedCard = !isOpenedCard" class="c-toggleCard">
    <div class="v--flex">
      <h3>{{ title }}</h3>
      <icon
        class="v--flex-align-right"
        :name="isOpenedCard ? 'chevron-up' : 'chevron-down'"
      />
    </div>
    <Spacing v-if="isOpenedCard == true" top="2">
      <p>{{ description }}</p>
    </Spacing>
  </div>
</template>
<script>
export default {
  name: 'ToggleCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    openCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpenedCard: this.openCard
    }
  }
}
</script>

<style lang="stylus" src="./style.styl"></style>
