<template>
  <div
    class="p-semester"
    :class="{ 'v--disable-pointer-events': $wait.is('semester/update') }"
  >
    <Spacing bottom="2">
      <Button to="/semestre"><Icon name="arrow-left" /></Button>
    </Spacing>
    <div class="c-create-semester-modal__header">
      <ShortMessage>
        <p>
          PS: Nós recomendamos que você importe seus dados do SIAC. Gerenciar os
          semestres manualmente pode ser muito chato. Se quiser continuar com
          isso, lembre de inserir os semestres mais antigos primeiro. :)
        </p>
      </ShortMessage>
      <Spacing vertical="2" class="c-create-semester-modal__toolbar">
        <div class="c-create-semester-modal__toolbar-dropdowns">
          <Dropdown
            :items="dropdown"
            :initial="dropdown[0]"
            :disabled="!!q.length"
            @change="selectDisciplineType"
          />
          <Dropdown
            :items="filters"
            :initial="filters[0]"
            :disabled="!!q.length"
            @change="selectFilter"
          />
        </div>

        <Field
          type="search"
          autocapitalize="off"
          autocomplete="off"
          autocorrect="off"
          name="q"
          placeholder="Pesquise em todos os cursos"
          v-model="q"
          @input="search"
        />
      </Spacing>
    </div>
    <div class="c-create-semester-modal__body">
      <CreateSemesterLoading
        v-if="
          $wait.is('redirecting') ||
          $wait.is('component/getAll') ||
          $wait.is(`discipline/search`) ||
          $wait.is('semester/update')
        "
      />
      <template v-else>
        <div
          v-for="discipline in disciplines"
          :key="discipline.id"
          class="c-create-semester-modal__discipline"
        >
          <input
            :id="`select-${discipline.id}`"
            type="checkbox"
            :checked="isSelected(discipline)"
            @input="input($event, discipline)"
          />
          <label :for="`select-${discipline.id}`">
            <Discipline :item="discipline" horizontal>
              {{ discipline.name }}
            </Discipline>
          </label>
        </div>
      </template>
    </div>

    <SelectionBar
      v-show="!$wait.is('semester/get') && !$wait.is('semester/getComponents')"
      ref="selecteds"
      :options="[{ name: 'Salvar', value: 'create', icon: 'check' }]"
      @click="clickSelectionBar"
    />
  </div>
</template>
<script>
import Discipline from '@/components/disciplines/Discipline/Discipline.vue'
import CreateSemesterLoading from '../../../components/semester/CreateSemesterLoading/CreateSemesterLoading.vue'

export default {
  title: 'Editar semestre - MeForma!',
  name: 'EditSemester',
  route: '/semestre/editar/:code',
  layout: 'painel',
  middleware: ['auth'],
  components: { Discipline, CreateSemesterLoading },
  emits: ['close'],
  data() {
    return {
      step: 2,
      filter: 'pendent',
      type: null,
      q: '',
      items: [],
      timeout: null,
      notFound: false,
      searching: false
    }
  },
  beforeMount() {
    this.$wait.start('component/getAll')
    this.$store.semester
      .getSemester({ code: this.$route.params.code })
      .then((data) => {
        this.$wait.start('semester/getComponents')
        return this.$store.component.getComponents().then(() => {
          this.$refs.selecteds.items = data?.components || []
        })
      })
      .finally(() => {
        this.$wait.end('semester/getComponents')
      })
  },
  computed: {
    filters() {
      return [
        {
          name: 'Apenas não concluídas',
          icon: 'circle-icon',
          value: 'pendent'
        },
        { name: 'Apenas concluídas', icon: 'check-circle', value: 'done' },
        {
          name: 'Apenas independentes',
          icon: 'arrow-right-circle',
          value: 'independent'
        },
        { name: 'Mostrar tudo', value: null }
      ]
    },
    dropdown() {
      return this.$store.component.typesWithContent('pendent')
    },
    code() {
      if (!(this.$store.semester?.codes && this.$store.semester.codes.length)) {
        return '2019.2'
      }

      const arr = this.$store.semester.codes[0].code.split('.')

      if (arr[1] === '1') {
        return `${arr[0]}.${parseInt(arr[1]) + 1}`
      }
      return `${parseInt(arr[0]) + 1}.1`
    },
    disciplines() {
      if ((this.items && this.items.length) || this.dropdown) {
        const type =
          this.type ||
          (this.dropdown && this.dropdown[0] && this.dropdown[0].key)

        return this.q && type
          ? this.items
          : this.$store.component.filtereds(type, this.filter)
      }
      return null
    }
  },
  methods: {
    save() {
      this.$wait.start('redirecting')

      const disciplines = this.$refs.selecteds?.items.map((item) => item.id)
      const code = this.$route.params.code
      this.$store.semester
        .update({ code, disciplines })
        .then(() => {
          this.$toast.success('Semestre atualizado!')
          this.$router.push(`/semestre?chave=${code}`).then(() => {
            this.$wait.end('redirecting')
          })
        })
        .catch(() => {
          this.$toast.error('Houve um erro. Tente novamente.')
          this.$wait.end('redirecting')
        })
    },
    clickSelectionBar(e) {
      if (e.type === 'create') {
        this.save()
      }
    },
    isSelected(item) {
      return this.$refs.selecteds?.items.find((i) => i.id === item.id)
    },
    selectDisciplineType(e) {
      if (e.key) {
        this.type = e.key
      }
    },
    selectFilter(e) {
      this.filter = e.value
    },
    input(e, item) {
      if (e.target.checked) {
        this.$refs.selecteds?.add(item)
      } else {
        this.$refs.selecteds?.remove(item)
      }
    },
    close() {
      this.$emit('close')
    },
    search() {
      this.$wait.start(`discipline/search`)
      this.notFound = false
      if (!this.q) {
        this.$wait.end(`discipline/search`)
        this.items = []
        return
      }
      if (this.q.trim().length >= 5) {
        this.items = []
        this.searching = true
        if (this.timeout) {
          clearTimeout(this.timeout)
          this.timeout = null
        }

        this.timeout = setTimeout(() => {
          this.$store.discipline
            .search({ q: this.q })
            .then((items) => {
              this.items = items
            })
            .catch((e) => {
              if (e.message && e.message === 'canceled') {
                return
              }
              if (e.response.status === 404) {
                this.notFound = true
              } else {
                let message = 'Houve um erro, por favor recarregue a página'

                if (e.response && e.response.data && e.response.data.message) {
                  message = e.response.data.message
                }

                this.$toast.error(message)
              }
            })
            .finally(() => (this.searching = false))
        }, 300)
      }
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
<style lang="stylus" scoped>
.p-semester {
  max-width: 1016px;
  margin: 0 auto;
  padding-bottom 80px
  &__hours {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px
    width: 100%;
  }
}
</style>
