<template>
  <div
    v-if="discipline"
    class="c-discipline-resume"
    :class="{
      'v--dark__card': $store.settings.darkMode
    }"
  >
    <h2 class="c-discipline-resume__title">Informações</h2>
    <p class="c-discipline-resume__item">
      Departamento: <b>{{ discipline.department }}</b>
    </p>
    <p class="c-discipline-resume__item">
      Carga horária total: <b>{{ discipline.hours }} horas</b>
    </p>
    <p class="c-discipline-resume__item">
      Página oficial:
      <b>
        <a :href="discipline.page">{{ discipline.code }}</a>
      </b>
    </p>
  </div>
</template>
<script>
export default {
  name: 'DisciplineResume',
  props: {
    discipline: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
