<template>
  <Card
    class="c-components-truth-table"
    :class="{
      'v--dark__card': $store.settings.darkMode,
      'v--loading': loading
    }"
  >
    <template v-slot:header>
      <Spacing bottom="1">
        <p class="c-components-truth-table__title">{{ title }}</p>
        <p class="c-components-truth-table__subtitle">
          {{ (percentage && percentage.toFixed(2)) || 0 }}%
        </p>
      </Spacing>

      <Button
        circle
        fit
        v-tooltip="
          `Aqui você pode visualizar o seu aproveitamento geral com base na quantidade de disciplina que pegou em cada semestre, e no número de aprovações`
        "
        class="v--flex-place-self-start v--flex-align-right"
      >
        <Icon name="help-circle" />
      </Button>
    </template>
    <div class="c-components-truth-table__false-content"></div>
    <p v-if="table?.length === 0">
      Quando você criar semestres, verá aqui seu aproveitamento em cada um
      deles.
    </p>
    <template v-else>
      <div
        v-for="row in table"
        :key="row.code"
        class="c-components-truth-table__row"
      >
        <span class="c-components-truth-table__row-title">
          {{ row.code }}
        </span>
        <span
          class="c-components-truth-table__columns"
          :style="{ 'grid-template-columns': `repeat(${size}, 1fr)` }"
        >
          <span
            v-for="(column, c) in row.map"
            class="c-components-truth-table__column"
            :class="{
              'c-components-truth-table__column--done':
                column === 'AP' || column === 'DI' || column === 'DU',
              'c-components-truth-table__column--fail':
                column === 'RR' || column === 'RF',
              'c-components-truth-table__column--quit': column === 'TR'
            }"
            :key="`i-${row.code}-${c}`"
          />

          <span v-if="row.length < size" />
        </span>
      </div>
      <Spacing top="2">
        <Legend
          :items="[
            { name: 'Aprovação e Dispensa', color: '#5FDC8A' },
            { name: 'Reprovação', color: '#FF5757' },
            { name: 'Trancamento', color: '#6e8392' },
            { name: 'Não feito', color: '#D1D3D2' }
          ]"
        />
      </Spacing>
    </template>
  </Card>
</template>
<script>
export default {
  name: 'SemestersMap',
  props: {
    title: {
      type: String,
      default: ''
    },
    table: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    size() {
      return (
        this.table &&
        this.table.reduce((a, b) => {
          a = b.map.length > a ? (a = b.map.length) : a
          return a
        }, 0)
      )
    },
    percentage() {
      return (
        this.table &&
        this.table.length &&
        this.table.reduce((a, b) => {
          return (a +=
            (b.map.reduce((sum, item) => {
              return (sum +=
                item === 'AP' || item === 'DI' || item === 'DU' ? 1 : 0)
            }, 0) *
              100) /
              b.map.filter((item) => item !== '--').length || 1)
        }, 0) / this.table.length
      )
    }
  }
}
</script>
<style src="./style.styl" lang="stylus" scoped />
