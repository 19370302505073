<template>
  <div class="p-help">
    <h1>Perguntas Frequentes</h1>
    <p class="description">
      Essas são as perguntas que recebemos com maior frequência. Se permanecer
      com dúvida, entra em contato com a gente através do chat à sua direita.
    </p>

    <ToggleCard
      :title="`O MeForma pertence à UFBA?`"
      :description="`Não! O MeForma é uma iniciativa independente, que partiu de um estudante que precisava gerenciar sua vida acadêmica sem tantas dores de cabeça, e é mantido sem apoio financeiro.`"
      :openCard="true"
    />

    <ToggleCard
      :title="`Não consigo importar meus dados do SIAC!`"
      :description="`O servidor do SIAC pode estar lento por ter muitos acessos, ou o seu SIAC pode ter alguma coisinha que o robô do MeForma não sabe ler, então o recomendado é esperar uns minutos e tentar novamente. Caso não obtenha sucesso, manda um print do que foi exibido pra a gente, que vamos fazer o possível pra te ajudar. Na pior hipótese, você pode cadastrar os dados manualmente.`"
    />

    <ToggleCard
      :title="`Todo semestre eu tenho que atualizar meus dados?`"
      :description="`Sim. O MeForma precisa que você alimente ele com seu progresso. Para a maioria das coisas você só precisará usar a função de importar do SIAC.`"
    />

    <ToggleCard
      :title="`Minha senha do SIAC está segura com o MeForma?`"
      :description="`Suas credenciais não são armazenadas. Pode ficar tranquilo! Assim que nosso robô recupera seus dados, suas credenciais são descartadas.`"
    />

    <ToggleCard
      :title="`O que o MeForma consulta do SIAC?`"
      :description="`Bom, até a data de hoje, consultamos apenas as disciplinas obrigatórias e as optativas que estão no seu histórico e as disciplinas que você cursa no semestre atual, além da tabela de aproveitamento de atividades extra. Além disso aprendemos e buscamos novos cursos e currículos cadastrados.`"
    />

    <ToggleCard
      :title="`Esse FAQ não me respondeu, o que fazer?`"
      :description="`Aí na sua tela, no canto direito, tem um icone de chat. Tá vendo? Então, vai lá! Vamos te atender assim que possível.`"
    />
  </div>
</template>

<script>
export default {
  name: 'Help',
  route: '/ajuda',
  layout: 'painel',
  middleware: ['auth']
}
</script>

<style lang="stylus" scoped>
.p-help {
    max-width 1100px
    margin 0 auto
}
.description {
  margin-bottom: 32px;
}
</style>
