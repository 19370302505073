<template>
  <div class="c-toolbar">
    <Dropdown
      title="Tipos de disciplina"
      @change="$emit('change-discipline-type', $event)"
      :items="dropdown"
      :initial="type"
    />
    <div class="v--flex-align-right c-toolbar__right">
      <DisciplineSearch @click-discipline="$emit('click-discipline', $event)" />
      <Button
        v-tooltip="`Visualização de grid`"
        @click="setTemplate('grid')"
        class="c-toolbar__button"
        :class="{ 'c-toolbar__button--active': template === 'grid' }"
      >
        <Icon name="grid" size="16" />
      </Button>
      <Button
        v-tooltip="`Visualização de lista`"
        @click="setTemplate('list')"
        class="c-toolbar__button"
        :class="{ 'c-toolbar__button--active': template === 'list' }"
      >
        <Icon name="list" size="16" />
      </Button>
      <Dropdown
        :items="filters"
        :class="{ 'c-toolbar__filter--active': hasFilter }"
        hide-indicator
        title="Filtros rápidos"
        placement="bottom-start"
        class="c-toolbar__button"
        @change="filterDisciplines"
      >
        <Icon name="funnel" size="16" />
        <Spacing left="1" hide-xs>
          <span> Filtrar </span>
        </Spacing>
      </Dropdown>
      <Button
        v-tooltip:bottom="
          `<div class='c-tooltip__legend'>
            <div>Legenda:</div>
            <div class='c-tooltip__legend-item'><span class='c-tooltip__legend-item-tag' style='background-color: #5FDC8A'></span>Concluída</div>
            <div class='c-tooltip__legend-item'><span class='c-tooltip__legend-item-tag' style='background-color: #FFFFFF'></span>Não conclúida</div>
            <div class='c-tooltip__legend-item'><span class='c-tooltip__legend-item-tag' style='background-color: #DED957'></span>Pré-requisito</div>
            <div class='c-tooltip__legend-item'><span class='c-tooltip__legend-item-tag' style='background-color: #5F7BDC'></span>Dependente</div>
          </div>`
        "
      >
        <Icon name="help-circle" size="16" />
      </Button>
    </div>
  </div>
</template>
<script>
import DisciplineSearch from '@/components/disciplines/DisciplineSearch/DisciplineSearch'
export default {
  name: 'DisciplinesToolbar',
  components: {
    DisciplineSearch
  },
  emits: ['change-discipline-type', 'click-discipline'],
  data() {
    return {
      template: 'grid',
      hasFilter: false
    }
  },
  computed: {
    dropdown() {
      return (
        (this.$store.component &&
          this.$store.component.types &&
          this.$store.component.types.filter(
            (item) =>
              this.$store.component.state[item.key] &&
              this.$store.component.state[item.key].length
          )) ||
        []
      )
    },
    type() {
      if (this.$route && this.$route.query && this.$route.query.tipo) {
        const type = this.dropdown.find(
          (item) => item.name === this.$route.query.tipo
        )
        return type
      }

      return this.dropdown && this.dropdown[0]
    },
    filters() {
      return [
        { name: 'Apenas concluídas', icon: 'check-circle', value: 'done' },
        {
          name: 'Apenas não concluídas',
          icon: 'circle-icon',
          value: 'pendent'
        },
        {
          name: 'Apenas independentes',
          icon: 'arrow-right-circle',
          value: 'independent'
        },
        { name: 'Mostrar tudo', value: null }
      ]
    }
  },
  created() {
    this.template = localStorage.getItem('disciplines-template') || 'grid'
  },
  methods: {
    setTemplate(type) {
      this.template = type
      localStorage.setItem('disciplines-template', type)
      this.$emit('change-template', type)
    },
    filterDisciplines($event) {
      this.hasFilter = true
      this.$emit('filter-disciplines', $event)
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped />
