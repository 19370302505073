import { reactive } from 'vue'
export default class Hour {
  constructor(ctx) {
    this.ctx = ctx
    this.state = reactive({
      hours: null
    })
  }

  getHours() {
    this.ctx.config.globalProperties.$wait.start(`hour/getAll`)

    return this.ctx.config.globalProperties.$axios
      .get('hours')
      .then((result) => {
        this.setHours(result.data && result.data.data)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`hour/getAll`)
      })
  }

  setHours(hours) {
    this.state.hours = hours
  }

  hours(type) {
    return this.state && this.state.hours && this.state.hours[type]
  }

  create(payload) {
    this.ctx.config.globalProperties.$wait.start(`hour/create`)

    return this.ctx.config.globalProperties.$axios
      .post('hours', payload)
      .then(() => {
        return this.getHours()
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`hour/create`)
      })
  }

  remove(payload) {
    this.ctx.config.globalProperties.$wait.start(`hour/remove`)
    return this.ctx.config.globalProperties.$axios
      .delete(`hours/${payload.id}`, {
        params: { type: payload.type }
      })
      .then(() => {
        return this.getHours()
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`hour/remove`)
      })
  }

  update(payload) {
    this.ctx.config.globalProperties.$wait.start(`hour/update`)
    return this.ctx.config.globalProperties.$axios
      .put(`hours/${payload.id}`, payload)
      .then(() => {
        return this.getHours()
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`hour/update`)
      })
  }
}
