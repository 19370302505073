<template>
  <div class="c-toolbar">
    <Dropdown
      title="Tipos de carga horária"
      @change="$emit('change-hour-type', $event)"
      :items="dropdown"
      :initial="type"
    >
      Horas
    </Dropdown>

    <div class="v--flex-align-right c-toolbar__right">
      <Button class="v--bg-bright-blue" @click="$emit('create-hour')">
        <Icon name="plus" size="14" />
        <Spacing left="1" hide-xs>Novo aproveitamento</Spacing>
      </Button>
      <Button v-tooltip:bottom="help">
        <Icon name="help-circle" size="16" />
      </Button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HoursToolbar',
  emits: ['create-hour', 'change-hour-type'],
  props: {
    dropdown: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: ''
    }
  },
  computed: {
    help() {
      return `Cuidado para não duplicar o conteúdo lido do SIAC! As horas marcadas como "Pendentes" não entram no cálculo de porcentagem automaticamente.`
    },
    type() {
      const type =
        this.dropdown &&
        this.dropdown.find((item) => item.value === this.selected)
      return type ? type : this.dropdown && this.dropdown[0]
    }
  },
  data() {
    return {
      template: 'grid'
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped />
