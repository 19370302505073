import { createPopper } from '@popperjs/core'

export default (el, options) => {
  el.removeEventListener('mouseenter', show)
  el.removeEventListener('mousemove', stopCounters)
  el.removeEventListener('click', click)
  el.removeEventListener('mouseout', hide)

  const placement = (options && options.placement) || 'top'
  const tooltip = window.document.createElement('div')
  const tooltipContent = window.document.createElement('div')
  const arrow = window.document.createElement('div')

  tooltip.appendChild(arrow)
  tooltip.classList.add('c-tooltip')

  arrow.classList.add('c-tooltip__arrow')

  let timeout = null
  let isOpen = false

  const show = (e) => {
    if (e) {
      e.stopPropagation()
    }

    stopCounters()

    if (isOpen) {
      return
    }

    const timestamp = new Date().getTime()
    const tooltipId = `c-tooltip-popcorn-${timestamp}`
    tooltipContent.innerHTML = el._tooltipValue
    tooltip.appendChild(tooltipContent)
    tooltip.id = tooltipId
    tooltip.addEventListener('mousemove', stopCounters)
    tooltip.addEventListener('mouseout', hide)

    document.getElementById('app').appendChild(tooltip)

    el._tooltipPopper && el._tooltipPopper.destroy()

    el._tooltipPopper = createPopper(el, tooltip, {
      placement: placement,
      positionFixed: true,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        },
        {
          name: 'arrow',
          options: {
            element: arrow
          }
        }
      ]
    })

    el._tooltipPopper.update().then(() => {
      isOpen = true
    })
  }

  const hide = () => {
    stopCounters()
    timeout = window.setTimeout(() => {
      close()
    }, 200)
  }

  const close = () => {
    isOpen = false
    el._tooltipPopper.destroy()
    tooltip.removeEventListener('mousemove', stopCounters)
    tooltip.removeEventListener('mouseout', hide)
    tooltip.remove()
  }

  const stopCounters = () => {
    if (timeout) {
      window.clearTimeout(timeout)
      timeout = null
    }
  }

  const click = () => {
    stopCounters()
    show()
  }

  el.addEventListener('mouseenter', show)
  el.addEventListener('mousemove', stopCounters)
  el.addEventListener('click', click)
  el.addEventListener('mouseout', hide)
}
