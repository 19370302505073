<template>
  <div class="c-toolbar">
    <Dropdown
      title="Tipos de disciplina"
      @change="$emit('change-discipline-type', $event)"
      :items="dropdown"
      :initial="dropdown[0]"
      :disabled="$wait.is('planning/hasSearch')"
    />
    <div class="v--flex-align-right c-toolbar__right">
      <Field
        type="search"
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        name="q"
        placeholder="Pesquise em todos os cursos"
        v-model="q"
        @input="search"
      />
      <Button
        v-tooltip="`Lista do que você escolheu`"
        class="c-toolbar__button"
        @click="$emit('show-report')"
      >
        Relatório
      </Button>
      <Dropdown
        :items="filters"
        :class="{ 'c-toolbar__filter--active': hasFilter }"
        hide-indicator
        title="Filtros rápidos"
        placement="bottom-start"
        :disabled="$wait.is('planning/hasSearch')"
        class="c-toolbar__button"
        @change="filterDisciplines"
      >
        <Icon name="funnel" size="16" />
        <Spacing left="1" hide-xs>
          <span> Filtrar </span>
        </Spacing>
      </Dropdown>
      <Button
        v-tooltip:bottom="
          `Utilize a barra de seleção (a barrinha preta) para salvar o seu planejamento `
        "
      >
        <Icon name="help-circle" size="16" />
      </Button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DisciplinesToolbar',
  emits: [
    'change-discipline-type',
    'click-discipline',
    'filter-disciplines',
    'show-report',
    'search'
  ],
  data() {
    return {
      template: 'grid',
      hasFilter: false,
      q: ''
    }
  },
  computed: {
    dropdown() {
      return (
        (this.$store.planning &&
          this.$store.planning.types &&
          this.$store.planning.types.filter(
            (item) =>
              this.$store.planning.state[item.key] &&
              this.$store.planning.state[item.key].length
          )) ||
        []
      )
    },
    filters() {
      return [
        {
          name: 'Apenas as que estão liberadas',
          value: 'opened'
        },
        { name: 'Mostrar tudo', value: null }
      ]
    }
  },
  methods: {
    search() {
      this.$emit('search', this.q)
    },
    filterDisciplines($event) {
      this.hasFilter = true
      this.$emit('filter-disciplines', $event)
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped />
