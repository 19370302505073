<template>
  <div class="p-login">
    <main class="p-login__main">
      <form class="p-login__form" @submit.prevent="submit">
        <div class="v--align-center">
          <Icon name="meforma" :size="120" />
        </div>
        <template v-if="done === false">
          <Spacing vertical="3">
            <p>
              Informe o e-mail da sua conta no MeForma, e enviaremos instruções
              para recuperação de senha.
            </p>
          </Spacing>
          <Field
            label="E-mail"
            name="email"
            type="email"
            v-model="email"
            :disabled="!!$wait.is('forgot')"
            required
          />
          <Button
            sprawled
            class="v--bg-amaranth"
            :loading="!!$wait.is('forgot')"
          >
            Solicitar recuperação
          </Button>
          <Spacing :vertical="1.5">
            <Line />
          </Spacing>
          <p>
            Ainda não tem uma conta?
            <router-link to="/cadastro">Cadastre-se</router-link>
          </p>
          <p>
            Já lembrou a senha?
            <router-link to="/login">Faça login</router-link>
          </p>
        </template>
        <ShortMessage v-else>
          Enviamos as instruções de recuperação da sua conta para o seu e-mail.
          Basta segui-las para voltar a usufruir do MeForma.
        </ShortMessage>
      </form>
    </main>
  </div>
</template>
<script>
import ShortMessage from '@/components/global/ShortMessage/ShortMessage.vue'
export default {
  title: 'Esqueci a senha - MeForma!',
  components: { ShortMessage },
  name: 'Forgot',
  middleware: ['guest'],
  route: '/recuperar-senha',
  data() {
    return {
      email: '',
      password: '',
      done: false
    }
  },
  methods: {
    submit() {
      this.$wait.start('forgot')
      this.$store.auth
        .recovery({ email: this.email })
        .then(() => {
          this.done = true
        })
        .catch(() => this.$toast.error('Esse e-mail não está cadastrado'))
        .finally(() => this.$wait.end('forgot'))
    }
  }
}
</script>
<style lang="stylus" scoped>
.p-login {
  display: grid;
  min-height: 100vh;
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding 0 5%
  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {
    margin-top: -120px;
    width: 400px;
    display: block;
    @media (max-width 768px) {
      width 100%
      max-width: 400px;
    }
  }
}
</style>
