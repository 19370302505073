<template>
  <div class="p-discipline">
    <DisciplineCard :discipline="$store.discipline.discipline" />
    <Spacing top="2" class="p-discipline__grid v--flex">
      <div>
        <CreatePost
          :class="{
            'v--dark__card': $store.settings.darkMode
          }"
          ref="createPost"
          :discipline-id="$store.discipline.discipline?.id"
        />
        <Loading v-if="$wait.is(`post/getAll`) && !$store.post.posts?.length" />
        <Spacing
          v-else-if="$store.post.posts.length"
          vertical="2"
          class="p-discipline__posts"
        >
          <Post
            v-for="post in $store.post.posts"
            :key="post.id"
            :can-show-comments="post.id === showingComments"
            :item="post"
            @show-comments="showingComments = $event"
          />
        </Spacing>
        <Spacing v-else vertical="2">
          <Empty @button-click="createPost" />
        </Spacing>
      </div>
      <div class="p-discipline__grid-resume">
        <DisciplineResume :discipline="$store.discipline.discipline" />
        <DisciplineRelateds
          :relateds="$store.discipline.relateds"
          @click-discipline="clickDiscipline"
        />
      </div>
    </Spacing>
  </div>
</template>
<script>
import DisciplineCard from '@/components/discipline/DisciplineCard/DisciplineCard.vue'
import DisciplineResume from '@/components/discipline/DisciplineResume/DisciplineResume.vue'
import DisciplineRelateds from '@/components/discipline/DisciplineRelateds/DisciplineRelateds.vue'
import CreatePost from '@/components/posts/CreatePost/CreatePost'
import Post from '@/components/posts/Post/Post.vue'
import Loading from '@/components/posts/Loading/Loading.vue'
import Empty from '@/components/posts/Empty/Empty.vue'

export default {
  name: 'Discipline',
  route: '/disciplinas/:university/:discipline',
  layout: 'painel',
  beforeMount() {
    document.title = `${this.$route.params.discipline} - MeForma!`
    this.callData()
  },
  components: {
    DisciplineCard,
    CreatePost,
    DisciplineResume,
    DisciplineRelateds,
    Post,
    Loading,
    Empty
  },
  data() {
    return {
      showingComments: 0,
      tab: 'Posts'
    }
  },
  methods: {
    selectTab(item) {
      this.tab = item.name
    },
    createPost() {
      this.$refs && this.$refs.createPost && this.$refs.createPost.openModal()
    },
    clickDiscipline(discipline) {
      this.$store.discipline.reset()
      this.$store.post.reset()
      this.$router.push(`/disciplinas/UFBA/${discipline.code}`).then(() => {
        this.callData()
      })
    },
    callData() {
      const { params } = this.$route
      this.$store.discipline.getDiscipline(params)
      this.$store.discipline.getRelateds(params)
      this.$store.post.getPosts(params)
    }
  },
  beforeUnmount() {
    this.$store.discipline.reset()
    this.$store.post.reset()
  }
}
</script>
<style lang="stylus" scoped>
.p-discipline {
  max-width: 1680px;
  margin: 0 auto;
  text-align center
  &__grid {
    text-align left
    display: inline-grid;
    grid-template-columns: 650px 350px;
    grid-gap: 16px;
    margin-left auto!important
    margin-right auto!important
    @media (max-width 768px)  {
      grid-template-columns 1fr;
    }
    &--vertical {
      grid-gap: 8px;
      display: grid;
    }
    &-resume {
      display flex
      flex-direction column
      & >>> > * {
        margin-top 16px
        &:first-child {
          margin-top 0
        }
      }
    }
  }
  &__posts {
    display: grid;
    grid-gap: 8px;
  }
}
</style>
