<template>
  <label class="c-slider">
    <input type="checkbox" />
    <span class="c-slider__tool">Aprovado</span>
  </label>
</template>
<script>
export default {
  name: 'Slider'
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
