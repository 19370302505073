import { createApp } from 'vue'
import Toaster from '@meforma/vue-toaster'
import copyText from '@meforma/vue-copy-to-clipboard'
import wait from '@meforma/vue-wait-for'
import App from './App.vue'
import router from './router'
import store from './store'
import global from './components/global'
import axios from './plugins/axios.js'
import tooltip from './directives/tooltip'
import './assets/css/main.styl'

const app = createApp(App)

global.keys().forEach((file) => {
  const component = global(file).default
  if (component.name) {
    app.component(component.name, component)
  } else {
    throw 'Global components requires name attribute'
  }
})

app.directive('tooltip', {
  mounted(el, bindings) {
    if (bindings.value) {
      el._tooltipValue = bindings.value
      tooltip(el, { placement: bindings.arg })
    }
  },
  updated(el, bindings) {
    if (bindings.value) {
      el._tooltipValue = bindings.value
    }
  }
})
app
  .use(router)
  .use(store)
  .use(wait)
  .use(axios)
  .use(copyText)
  .use(Toaster)
  .mount('#app')
