<template>
  <div>
    <Field
      label="Senha atual"
      name="currentPassword"
      type="password"
      v-model="oldPassword"
      visibility-switch
      :disabled="$wait.is('login')"
      required
    />
    <Spacing vertical="2">
      <Line />
    </Spacing>
    <Spacing
      v-if="passwordsDontMeet"
      horizontal="1"
      bottom="2"
      class="v--text-color-fail"
    >
      <p>Você digitou duas senhas diferentes!</p>
    </Spacing>
    <Field
      label="Nova senha"
      sublabel="Mínimo de 6 caracters. Utitlize maiúsculas, minúsculas, números e caracteres especiais"
      name="password"
      type="password"
      v-model="password"
      visibility-switch
      :disabled="$wait.is('login')"
      required
    />
    <Field
      label="Repita a nova senha"
      name="password-confirm"
      type="password"
      v-model="passwordConfirmation"
      visibility-switch
      :disabled="$wait.is('login')"
      required
    />
    <Spacing vertical="2">
      <Button
        type="button"
        style="width: 94px"
        class="v--bg-meforma-pink"
        @click="submit"
        :loading="$wait.is('auth/password')"
        :disabled="$wait.is('auth/password')"
      >
        Salvar
      </Button>
    </Spacing>
  </div>
</template>
<script>
export default {
  name: 'PasswordForm',
  data() {
    return {
      password: '',
      oldPassword: '',
      passwordConfirmation: '',
      passwordsDontMeet: false
    }
  },
  methods: {
    submit() {
      if (this.password !== this.passwordConfirmation) {
        this.passwordsDontMeet = true
        return
      }

      if (this.password.length < 6) {
        this.$toast.error('A senha precisa ter no mínimo 6 caracteres')
        this.password = ''
        this.passwordConfirmation = ''
        return
      }

      this.$store.auth
        .changePassword({
          password: this.password,
          old_password: this.oldPassword,
          password_confirmation: this.passwordConfirmation
        })
        .then(() => {
          this.oldPassword = ''
          this.password = ''
          this.passwordConfirmation = ''
          this.$toast.success('Senha atualizada!')
        })
        .catch(() => {
          this.$toast.error('Houver um erro. Tente novamente.')
          this.password = ''
          this.passwordConfirmation = ''
        })
    }
  }
}
</script>
