<template>
  <div class="schedule-grid-component">
    <table class="schedule-grid">
      <tbody>
        <tr>
          <th></th>
          <th>SEG</th>
          <th>TER</th>
          <th>QUA</th>
          <th>QUI</th>
          <th>SEX</th>
          <th>SAB</th>
        </tr>
        <tr v-for="(hour, index) in hours" :key="hour">
          <th>{{ hour }}</th>
          <td
            v-for="i in 6"
            :key="`d${i}h${index + 1}`"
            @click="click(`d${i}h${index + 1}`)"
            :ref="`d${i}h${index + 1}`"
            :class="{
              'v--filled': planningGrid[`d${i}h${index + 1}`],
              'v--loading': loading
            }"
          >
            <Tag
              :style="{
                cursor: 'default',
                marginTop: '4px',
                backgroundColor: planningGrid[`d${i}h${index + 1}`]?.code
                  ? string2Hex(`d${i}h${index + 1}`)
                  : 'inherit',
                color: planningGrid[`d${i}h${index + 1}`]?.code
                  ? colorContrast(`d${i}h${index + 1}`)
                  : 'inherit'
              }"
            >
              {{ planningGrid[`d${i}h${index + 1}`]?.code || '' }}
            </Tag>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import color from '@/helpers/color'
export default {
  name: 'ScheduleGrid',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      planningGrid: {},
      discipline: {},
      tds: [],
      hours: [
        '07:00',
        '07:55',
        '08:50',
        '09:45',
        '10:40',
        '11:35',
        '13:00',
        '13:55',
        '14:50',
        '15:45',
        '16:40',
        '17:35',
        '18:30',
        '19:25',
        '20:20',
        '21:15'
      ]
    }
  },
  mounted() {
    this.tds = [].slice.call(document.getElementsByTagName('td'))
    this.tds.forEach((item) => item.addEventListener('mouseenter', this.hover))
    this.tds.forEach((item) => item.addEventListener('mouseout', this.blur))
  },
  unmounted() {
    this.tds.forEach((item) =>
      item.removeEventListener('mouseenter', this.hover)
    )
    this.tds.forEach((item) => item.removeEventListener('mouseout', this.blur))
    this.tds = []
  },
  methods: {
    colorContrast(str) {
      str =
        this.planningGrid[str]?.id +
        this.planningGrid[str]?.code +
        this.planningGrid[str]?.group.id
      return color.colorContrast(color.string2Hex(str), '#FFF', 'inherit')
    },
    string2Hex(str) {
      str =
        this.planningGrid[str]?.id +
        this.planningGrid[str]?.code +
        this.planningGrid[str]?.group.id
      return color.string2Hex(str)
    },
    hover(item) {
      if (item.target.textContent) {
        this.tds.forEach((td) => {
          td.classList.remove('v--hover')
          if (td.textContent === item.target.textContent) {
            td.classList.add('v--hover')
          }
        })
      }
    },
    blur(item) {
      if (item.target.textContent) {
        this.tds.forEach((td) => {
          td.classList.remove('v--hover')
        })
      }
    },
    click(code) {
      if (this.planningGrid[code]) {
        this.$emit('click', this.planningGrid[code])
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.schedule-grid {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 11px;
  border-radius: 3px;
  table-layout: fixed;
  font-weight: 500;
  margin-bottom: 80px;
  @media (max-width: 512px) {
    font-size: 10px;
  }
  tbody {
    background-color: #fefefe;
    tr {
      text-align center
      border: 1px solid #f1f1f1;
      border-left 0
      border-right 0
      td, th {
        border-left: 1px solid #f1f1f1;
        border-right: 1px solid #f1f1f1;
        &:first-child {
          border-left 0
        }
        &:last-child {
          border-right 0
        }
      }
      &:first-child {
        border-top 0
        th, td {
        }
      }
      &:last-child {
        border-bottom 0
      }
    }
  }


  td.v--filled {
    transition: all .2s;
  }

  td.v--loading {
    transition all 0.2s easy
    animation-name color
    animation-duration 1.5s
    animation-timing-function ease-out
    animation-iteration-count infinite
    & >>> .c-tag {
      display none
    }
  }
  td {
    & >>> .c-tag {
      @media (max-width 768px) {
        padding: 0;
        border-radius: 0;
        font-size: 10px;
        height: 100%;
      }
    }
  }

  th {
    text-align: center;
  }
}

@-webkit-keyframes color {
  0% {
    background-color rgba(233, 233, 233, .40)
    color: #FFF;
  }
  50% {
    background-color rgba(233, 233, 233, .40)
    color: #FFF;
  }
  51% {
    background-color: inherit;
    color: inherit;
  }
  100% {
    background-color: inherit;
    color: inherit;
  }
}
</style>
