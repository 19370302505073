<template>
  <div class="p-login">
    <main class="p-login__main">
      <form class="p-login__form" @submit.prevent="submit">
        <div class="v--align-center">
          <Icon name="meforma" :size="120" />
        </div>

        <Field
          label="Senha"
          name="password"
          type="password"
          v-model="password"
          visibility-switch
          :disabled="!!$wait.is('login')"
          required
        />
        <Field
          label="Repita a senha"
          name="password-confirm"
          type="password"
          v-model="passwordConfirmation"
          visibility-switch
          :disabled="!!$wait.is('login')"
          required
        />
        <Button sprawled class="v--bg-amaranth" :loading="!!$wait.is('login')">
          Alterar senha
        </Button>
        <Spacing :vertical="1.5">
          <Line />
        </Spacing>
        <p>
          Ainda não tem uma conta?
          <router-link to="/cadastro">Cadastre-se</router-link>
        </p>
      </form>
    </main>
  </div>
</template>
<script>
export default {
  title: 'Nova senha - MeForma!',
  name: 'Reset',
  middleware: ['guest'],
  route: '/resetar-senha/:code',
  data() {
    return {
      email: '',
      password: '',
      passwordConfirmation: ''
    }
  },
  methods: {
    submit() {
      this.$wait.start('login')
      this.$store.auth
        .resetPassword({
          email: this.$route.query.email,
          token: this.$route.params.code,
          password: this.password,
          password_confirmation: this.passwordConfirmation
        })
        .then(() => {
          this.$toast.success('Tudo certo! Faça login.')
          this.$router.push('/login')
        })
        .catch(() =>
          this.$toast.error(
            'O seu token não é válido! Faça uma nova solicitação.'
          )
        )
        .finally(() => this.$wait.end('login'))
    }
  }
}
</script>
<style lang="stylus" scoped>
.p-login {
  display: grid;
  min-height: 100vh;
  // background-image: url('../../assets/bg.png');
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding 0 5%
  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {
    margin-top: -120px;
    width: 400px;
    display: block;
    @media (max-width 768px) {
      width 100%
      max-width: 400px;
    }
  }
}
</style>
