import { reactive } from 'vue'
export default class Component {
  constructor(ctx) {
    this.ctx = ctx
    this.state = reactive({
      types: [
        {
          key: 'required',
          type: 'OB',
          name: 'Disciplinas Obrigatórias',
          value: 'RequiredsContainer'
        },
        {
          key: 'optional',
          type: 'OP',
          name: 'Disciplinas Optativas',
          value: 'OptionalsContainer'
        },
        {
          key: 'scientific_optional',
          type: 'OY',
          name: 'Disciplinas Op. Científicas',
          value: 'OptionalsContainer'
        },
        {
          key: 'humanistic_optional',
          type: 'OH',
          name: 'Disciplinas Op. Humanísticas',
          value: 'OptionalsContainer'
        },
        {
          key: 'artistic_optional',
          type: 'OZ',
          name: 'Disciplinas Op. Artísticas',
          value: 'OptionalsContainer'
        },
        {
          key: 'large_area_optional',
          type: 'OG',
          name: 'Disciplinas Op. Grande Área',
          value: 'OptionalsContainer'
        },
        {
          key: 'concentration_area_optional',
          type: 'OC',
          name: 'Op. Área de Concentração',
          value: 'OptionalsContainer'
        },
        {
          key: 'naoClassificada',
          type: 'NAO_CLASSIFICADA',
          name: 'Disciplinas sem classificação',
          value: 'OptionalsContainer'
        },
        {
          key: 'free',
          type: 'LV',
          name: 'Disciplinas livres',
          value: 'OptionalsContainer'
        }
      ],
      truthTable: null,
      semesters: null,
      optional: null,
      required: null,
      components: []
    })
  }

  reset() {
    this.state.truthTable = null
    this.state.semesters = null
    this.state.types.forEach((item) => {
      this.state[item.key] = null
    })
  }

  typesWithContent(filter = null) {
    return (
      (this.state &&
        this.state.types &&
        this.state.types.filter(
          (item) =>
            this.state[item.key] && this.filtereds(item.key, filter).length
        )) ||
      []
    )
  }

  setComponentsMap(truthTable) {
    this.state.truthTable = truthTable
  }

  filtereds(type = 'required', filter = null) {
    if (this.state && this.state[type]) {
      if (filter) {
        let disciplines = this.state[type].filter((discipline) => {
          if (filter === 'done') {
            return discipline.done
          } else if (filter === 'pendent') {
            return !discipline.done
          } else if (filter === 'independent') {
            return (
              !discipline.prerequisites.length && !discipline.dependents.length
            )
          } else {
            return discipline
          }
        })
        return disciplines
      }
      return this.state && this.state[type]
    }
    return this.state && this.state['required']
  }

  semesters(filter = null) {
    if (filter) {
      const output = {}

      if (this.state && this.state.semesters) {
        for (let key in this.state.semesters) {
          let disciplines = this.state.semesters[key].filter((discipline) => {
            if (filter === 'done') {
              return discipline.done
            } else if (filter === 'pendent') {
              return !discipline.done
            } else if (filter === 'independent') {
              return (
                !discipline.prerequisites.length &&
                !discipline.dependents.length
              )
            }
          })
          if (disciplines.length) {
            output[key] = disciplines
          }
        }
      }
      return output
    }
    return this.state && this.state.semesters
  }

  requiredsToDo() {
    return (
      this.state &&
      this.state.required &&
      this.state.required.filter((i) => !i.done)
    )
  }

  optionalsByDepartment(key, filter) {
    const groupedOptionals = {}

    this.state[key].forEach((component) => {
      const department = component.department || 'Sem categoria'
      if (!groupedOptionals[department]) {
        groupedOptionals[department] = []
        groupedOptionals[department].push(component)
      } else {
        groupedOptionals[department].push(component)
      }
    })

    if (filter) {
      const output = {}

      for (let i in groupedOptionals) {
        let disciplines = groupedOptionals[i].filter((discipline) => {
          if (filter === 'done') {
            return discipline.done
          } else if (filter === 'pendent') {
            return !discipline.done
          } else if (filter === 'independent') {
            return (
              !discipline.prerequisites.length && !discipline.dependents.length
            )
          }
        })
        if (disciplines.length) {
          output[i] = disciplines
        }
      }

      return output
    }

    return groupedOptionals
  }

  getComponentsMap() {
    this.ctx.config.globalProperties.$wait.start(`component/map`)
    return this.ctx.config.globalProperties.$axios
      .get('user/components/map')
      .then((result) => {
        this.setComponentsMap(result.data && result.data.data)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`component/map`)
      })
  }

  setComponents(components) {
    this.state.components = components

    let semesters = {}

    this.state.types.forEach((item) => {
      this.state[item.key] = components.filter((i) => i.type === item.type)
    })

    this.state.required.forEach((component) => {
      if (!semesters[component.semester]) {
        semesters[component.semester] = []
        semesters[component.semester].push(component)
      } else {
        semesters[component.semester].push(component)
      }
    })

    this.state.semesters = semesters
  }

  getComponents() {
    this.ctx.config.globalProperties.$wait.start(`component/getAll`)

    return this.ctx.config.globalProperties.$axios
      .get('user/components')
      .then((result) => {
        this.setComponents(result.data && result.data.items)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`component/getAll`)
      })
  }

  updateComponents(payload) {
    this.ctx.config.globalProperties.$wait.start(`component/update`)

    return this.ctx.config.globalProperties.$axios
      .put('user/components', payload)
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`component/update`)
      })
  }
}
