<template>
  <div>
    <Spacing bottom="2">
      <h2>Reorganizador de disciplinas</h2>
      <p>
        Arraste e solte disciplinas para as posições que você considera como
        corretas, e não esqueça de salvar no final.
      </p>
    </Spacing>
    <LoadingList
      v-if="
        $wait.is('organizer/getAll') ||
        $wait.is('user/progress') ||
        $wait.is('organizer/update')
      "
    />
    <Kanban v-else-if="types.length" :items="types" />
  </div>
</template>
<script>
import Kanban from '../../components/Kanban/Kanban.vue'
import LoadingList from '../../components/Kanban/LoadingList/LoadingList.vue'

export default {
  components: { Kanban, LoadingList },
  name: 'reorganizeDisciplines',
  route: '/reorganizar-disciplinas',
  layout: 'painel',
  middleware: ['auth'],
  beforeMount() {
    this.$store.organizer.getComponents()
    this.$store.auth.getProgress()
  },
  computed: {
    types() {
      if (this.$store.auth?.progress) {
        return this.$store.organizer.types.filter((item) => {
          return this.$store.auth.progress[item.key]?.total
        })
      }
      return []
    }
  }
}
</script>
