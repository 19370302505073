<template>
  <Modal
    title="Reportar"
    :subtitle="`Você está reportando o comentário de ${comment.author?.name}`"
    small
    @close="$emit('close')"
  >
    <Spacing vertical="1">
      <Spacing vertical="1">
        <label for="opt-1" class="v--flex v--flex-align-middle">
          <input
            id="opt-1"
            name="reason"
            value="Linguajar inadequado"
            type="radio"
            v-model="reason"
          />
          <Spacing left="1">Linguajar inadequado</Spacing>
        </label>
      </Spacing>
      <Spacing vertical="1">
        <label for="opt-2" class="v--flex v--flex-align-middle">
          <input
            id="opt-2"
            name="reason"
            value="Vírus ou spam"
            type="radio"
            v-model="reason"
          />
          <Spacing left="1">Vírus ou spam</Spacing>
        </label>
      </Spacing>
      <Spacing vertical="1">
        <label for="opt-3" class="v--flex v--flex-align-middle">
          <input
            id="opt-3"
            name="reason"
            value="Outro"
            type="radio"
            v-model="reason"
          />
          <Spacing left="1">Outro</Spacing>
        </label>
      </Spacing>
    </Spacing>
    <FieldText
      label="Motivo"
      rows="5"
      placeholder="Explique melhor o seu motivo"
      v-model="description"
    />
    <p>
      Se alguém estiver em perigo imediato, ligue para o serviço de emergência
      local. Não espere.
    </p>
    <template v-slot:footer>
      <div class="v--flex v--flex-align-right">
        <Button @click="$emit('close')">Cancelar</Button>
        <Spacing left="1">
          <Button
            style="width: 90px"
            class="v--bg-meforma-pink"
            @click="report"
            :loading="$wait.is('comments/report')"
            >Reportar</Button
          >
        </Spacing>
      </div>
    </template>
  </Modal>
</template>
<script>
export default {
  name: 'ReportModal',
  emits: ['close'],
  props: {
    comment: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      reason: '',
      description: ''
    }
  },
  methods: {
    report() {
      this.$store.post
        .reportComment({
          id: this.comment.id,
          reason: this.reason,
          description: this.description
        })
        .then(() => {
          this.$toast.success('Reclamação anotada!')
          this.$emit('close')
        })
        .catch(() => {
          this.$toast.error('Houve um erro. Por favor, tente novamente.')
        })
    }
  }
}
</script>
