<template>
  <div
    :id="`discipline-${item.id}`"
    class="c-discipline"
    :class="{
      'v--dark': $store.settings.darkMode,
      'v--checked': item.done && !loading,
      'v--hoverable': hoverable,
      'c-discipline--horizontal': horizontal
    }"
    @mouseenter.stop="highlightAdjacents"
    @mousemove="highlightAdjacents"
    @mouseout="normalizeAdjacents"
    @click="$emit('click', item)"
    :draggable="draggable"
  >
    <div class="c-discipline__content" :class="{ 'v--loading': loading }">
      <slot name="more" />
      <h2 class="c-discipline__title">
        {{ item.code }}
        <small>
          <span>•</span>
          {{ item.hours }}hs
        </small>
      </h2>
      <p class="c-discipline__subtitle">
        {{ item.name }}
      </p>
      <Spacing v-if="item.department" top="1" class="c-discipline__hashtag">
        <Hashtag :hash-color="background">
          {{ item.department }}
        </Hashtag>
      </Spacing>
    </div>
  </div>
</template>
<script>
import color from '@/helpers/color'
export default {
  name: 'Discipline',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: String,
      default: 'false'
    },
    hoverable: {
      type: Boolean,
      default: true
    }
  },
  emits: ['click'],
  computed: {
    background() {
      return color.string2Hex(this.item.department)
    }
  },
  methods: {
    highlightAdjacents() {
      if (this.item.dependents) {
        this.item.dependents.forEach((x) => {
          const d = document.getElementById('discipline-' + x.id)
          if (d) {
            d.style.background = '#98c7ff'
            d.style.color = '#2c3e50'
            if (!d.classList.contains('v--checked')) {
              d.style.borderColor = '#98c7ff'
            }
          }
        })
      }
      if (this.item.prerequisites) {
        this.item.prerequisites.forEach((x) => {
          const d = document.getElementById('discipline-' + x.id)
          if (d) {
            d.style.background = '#fff4a3'
            d.style.color = '#2c3e50'
            if (!d.classList.contains('v--checked')) {
              d.style.borderColor = '#fff4a3'
            }
          }
        })
      }
    },
    normalizeAdjacents() {
      if (this.item.dependents) {
        this.item.dependents.forEach((x) => {
          const d = document.getElementById('discipline-' + x.id)
          if (d) {
            d.style.color = null
            d.style.background = null
            d.style.borderColor = null
          }
        })
      }
      if (this.item.prerequisites) {
        this.item.prerequisites.forEach((x) => {
          const d = document.getElementById('discipline-' + x.id)
          if (d) {
            d.style.color = null
            d.style.background = null
            d.style.borderColor = null
          }
        })
      }
      this.showFileButton = false
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
