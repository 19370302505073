<template>
  <ul class="c-legend">
    <li class="c-legend__item" v-for="item in items" :key="item.name">
      <span
        class="c-legend__item-square"
        :style="{ 'background-color': item.color }"
      />
      <span>
        {{ item.name }}
      </span>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'Legend',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style src="./style.styl" lang="stylus" scoped />
