<template>
  <div class="l-painel" :class="{ 'v--dark': $store.settings.darkMode }">
    <Nps v-if="!$store.auth?.user?.settings?.nps" />
    <header class="l-painel__header">
      <Navbar />
    </header>
    <div class="l-painel__content">
      <slot></slot>
    </div>
    <CookiesBanner
      v-if="
        !$store.auth?.user?.settings?.crisp &&
        !$store.auth?.user?.settings?.google &&
        !$store.auth?.user?.settings?.hotjar
      "
    />
    <Ga v-if="$store.auth?.user?.settings?.google" />
    <Hotjar v-if="$store.auth?.user?.settings?.hotjar" />
    <Modal
      @close="close"
      medium
      v-if="!$store.auth?.user?.settings?.update_2_2021"
    >
      <h2>Sorria e beba água!<br />Já bebeu água hoje?</h2>
      <Spacing top="3">
        <p>
          Se liga que o MeForma mudou! E essa atualização trouxe um monte de
          <b>coisa boa</b> pra muita gente.
        </p>
      </Spacing>
      <Spacing top="1">
        <p>
          A partir de hoje, nós estamos assumindo a responsabilidade de dar
          suporte aos cursos de bacharelado interdisciplinar da UFBA. Os
          queridos <b>BI's</b> (L) &lt;3 #amor
        </p>
      </Spacing>
      <img style="width: 160px; margin: 8px auto" src="@/assets/friends.gif" />
      <Spacing top="1">
        <p>
          Trouxemos um layout mais legal,
          <b>novas formas de calcular a conclusão do curso</b> e novos gráficos
          para acompanhamento!
        </p>
      </Spacing>
      <img style="width: 160px; margin: 8px auto" src="@/assets/bob.gif" />
      <Spacing top="1">
        <p>
          A úuuunica parte triste é que não conseguimos manter os dados de
          disciplinas, nem os dados de planejamento de semestre. Você vai ter
          que importar do SIAC novamente, e vai ter que planejar novamente. Ah,
          mas é bom que você vê como tá o novo MeForma, né?
        </p>
      </Spacing>
      <img
        style="width: 160px; margin: 8px auto"
        src="@/assets/bob-dance.gif"
      />
      <Spacing top="3">
        <p>
          É isto! Estamos felizes por ter você conosco,<br />e estamos torcendo
          por você!
        </p>
      </Spacing>
      <template v-slot:footer>
        <Button
          style="width: 100%"
          class="v--bg-meforma-pink"
          @click="close"
          :loading="$wait.is('user/settings')"
          >VALEU!</Button
        >
      </template>
    </Modal>
  </div>
</template>
<script>
import CookiesBanner from '../components/CookiesBanner/CookiesBanner.vue'
import Ga from '../components/Ga/Ga.vue'
import Hotjar from '../components/Hotjar/Hotjar.vue'
import Navbar from '../components/Navbar/Navbar'
import Nps from '../components/Nps/Nps.vue'
export default {
  name: 'Painel',
  components: {
    Navbar,
    Hotjar,
    CookiesBanner,
    Ga,
    Nps
  },
  methods: {
    close() {
      this.$store.auth.settings({ update_2_2021: true }).catch(() => {
        this.$toast.error('Houve um erro. Por favor, recarregue a página.')
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.l-painel {
  &.v--dark {
    background-color: #06090f;
    * {
      color #FFF !important
      border-color #30363d !important
    }
  }

  &__header {
    height: 80px;
  }

  &__content {
    padding: 40px 5%;
    min-height: calc(100vh - 80px);
    padding-bottom: 80px;

    @media (max-width 768px) {
      padding 16px 5%;
      padding-bottom: 80px;
    }
  }
}
</style>
