<template>
  <!-- <?xml version="1.0" encoding="utf-8"?> -->
  <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1042 1023.8"
    style="enable-background: new 0 0 1042 1023.8"
    xml:space="preserve"
  >
    <g>
      <g>
        <g id="XMLID_2_">
          <path
            id="XMLID_22_"
            class="st1"
            d="M282,450.4c16.3-15.1,32.2-26.7,47.7-38.5c54.4-41.4,108.5-83.1,164.4-122.7
				c12.9-9.1,15.8-17.5,11.4-30.2c-3.1-8.8-12.2-14-21.5-12.1c-7.2,1.5-13.4,4.8-19.4,8.9c-56.6,39.1-110.6,81.3-165,123.5
				c-18.6,14.4-36.8,29.7-54.7,45c-33.1,28.1-44.2,64.1-40.6,106c0.9,11.4,2.6,22.8,4,34.2c0.5,5.3,2.8,8.5,8.4,11
				c25.8,11.3,36,41.2,23.1,66.6c-3.2,6-2.6,10.6-0.1,16.5c15.7,37.3,31.4,74.5,37.6,114.7c5.4,34-1.1,65.6-23,93
				c-10,12.6-23.1,16.9-38.8,15c-17.3-2.1-34.4-4.9-51.1-10.3c-11.4-3.7-12-5.1-6.6-15.8c15.9-32.1,30.4-64.8,35.3-100.8
				c3.5-27,1.9-54-0.6-80.8c-0.5-5.3-4.1-6.8-8.4-8.5c-25.1-9.7-38-32.3-32.7-56.7c1.3-6.2,4.2-11.9,7.8-16.8
				c5.8-7.7,6.3-16,4.6-24.8c-7.4-37.5-7.8-74.7,4.3-111.4c2.1-6.2,0.2-8-6.1-8.4c-31.9-1.6-64.1-3.3-95.9-5.2
				c-7.9-0.5-16.2-1.6-19.2-11.6c-2-6.4-1.5-11.2,3.6-16c14.1-13.3,30.2-24,46-34.9c95.2-65.7,190.6-131,285.9-196.3
				c19.5-13.3,39.4-26,58.9-39.4c5.7-4,11.5-5.2,18.2-5c119,3.1,237.9,9.3,356.7,14.4c36.9,1.5,74,2.6,110.8,7
				c8.5,1.1,17.7,0.8,20.1,12.5c2.4,11.2-4.2,16.7-11.8,22c-106,73-212.2,145.9-318.2,218.7c-22.5,15.6-45.2,30.8-67.7,46.5
				c-4.4,3-8.5,4.2-13.8,4c-78.6-3.8-157.2-6.4-235.8-11.5C294.4,451.7,289.6,451.1,282,450.4z"
          />
          <path
            id="XMLID_21_"
            class="st1"
            d="M838.4,405.8c5,17.4,9.2,35,15.1,52c5.8,16.8,1.7,31.8-5.3,46.8
				c-12.2,25.8-32.5,44.6-54.4,62c-34.1,26.8-72.2,47.1-112.2,63.5c-34.1,14-68.5,26-104.2,35c-66.3,16.8-133.5,25.5-202,17.5
				c-20.5-2.5-40-8.2-59.3-15.9c-8.8-3.5-10.7-8.8-7.9-17.5c10.1-32,0.5-58.6-22.8-81.3c-5.2-5.1-11.5-9-18.3-10.9
				c-6.6-2-9.6-5.6-11-12c-2.3-10-3.6-20.1-4.6-30.3c-1.3-12.6-0.8-13.2,12-13c48.3,0.4,96.3,3.2,144.6,5.1
				c44.8,1.8,89.5,3.8,134.3,4.4c10.5,0.1,19.2-3,27.9-8.5c74.1-48,146-99.4,219.3-148.7c6.7-4.4,13.6-8.9,20-13.7
				c6.2-4.7,9.6-3.9,11.6,4.1C826.6,364.5,832.7,385.1,838.4,405.8C838.6,405.7,838.6,405.7,838.4,405.8z"
          />
        </g>
        <g id="XMLID_3_">
          <path
            id="XMLID_53_"
            class="st2"
            d="M861.3,578.5c-37.1,12.3-74.9,18.7-113.9,12C664.1,576,596.2,509.9,582.9,430
				c-15.2-90.6,23.6-173.8,100.7-216.6c4.2-2.4,8.4-4.5,12.4-7c4-2.3,3.7-5.9,2.7-9.7c-1.1-4-3.5-7.4-7.8-6.1
				c-22.7,7.5-25.8-8.6-29.5-24.2c-0.6-2-1.2-4.1-1.7-6.1l-0.4,0.1c0.9-1.4,2.1-2.9,3-4.4c29.7-14.5,62.1-20.5,93.8-28.6
				c10.5-2.6,13.8,7.3,16.3,15.7c2.9,9.5,5.4,18.9-6.2,24.7c-6.1,3.1-13.4,5.6-10.3,14.8c2.9,8.7,10.2,5.6,16.1,5.3
				c95.3-3.1,178.9,57.2,204.3,147.3c25.5,90.6-13.9,185-97,232.1c-3.2,1.9-6.3,3.9-9.4,5.8c-3.5,0.8-7,1.4-8.4,5.3L861.3,578.5z
				 M823,533.9c78.2-22.6,124.4-105.7,102-184c-22.4-78.5-107.4-125.7-185.5-102.6c-77.9,23-124.2,106.6-101.9,184.5
				C660.4,510.8,743.6,556.8,823,533.9z"
          />
          <path
            id="XMLID_52_"
            class="st3"
            d="M861.5,579c1.4-3.9,4.9-4.5,8.4-5.3C867.9,576.8,864.8,578.3,861.5,579z"
          />
          <path
            id="XMLID_51_"
            class="st3"
            d="M662.4,156.3c-0.9,1.4-2.1,2.9-3,4.4C660.3,159.3,661.5,157.8,662.4,156.3z"
          />
          <path
            id="XMLID_47_"
            class="st4"
            d="M823,533.9c-79.3,22.9-162.6-23.1-185.3-102c-22.2-77.8,24-161.5,101.9-184.5
				c77.9-23,163,23.9,185.5,102.6C947.4,428.2,901.2,511.4,823,533.9z M754.3,378.7c-0.3,3.6-0.8,7.2-0.8,10.9
				c-0.3,14.1,8.9,26.2,22.2,28.6c14.1,2.6,25-2.9,31-15.9c6.1-13.2,2.6-24.8-8.3-33.9c-11-9.1-24-7.1-41.3,5.6
				c-31.7-17.8-63.2-35.6-94.9-53.5c-1,1.8-2.2,3.9-3.2,5.8C690.8,343.8,722.6,361.4,754.3,378.7z M770.8,278
				c-4.1-10.3-10.9-16.5-22.9-13.6c-11.3,2.6-18.3,13.6-14.5,24c3.8,10.7,12.1,16,23.6,13.1C768.6,298.3,772.1,289.5,770.8,278z"
          />
          <path
            id="XMLID_46_"
            class="st5"
            d="M756.9,374.1c17.3-12.7,30.4-14.7,41.3-5.6c11,9.1,14.5,20.7,8.3,33.9
				c-6,13-16.9,18.4-31,15.9c-13.3-2.4-22.4-14.6-22.2-28.6c0.1-3.7,0.6-7.4,0.8-10.9C755.3,377.3,756,375.7,756.9,374.1z"
          />
          <path
            id="XMLID_45_"
            class="st5"
            d="M770.8,278c1.3,11.5-2.2,20.2-14,23.2c-11.6,2.9-19.7-2.3-23.6-13.1
				c-3.7-10.4,3.2-21.3,14.5-24C759.9,261.5,766.5,267.8,770.8,278z"
          />
          <path
            id="XMLID_44_"
            class="st5"
            d="M756.9,374.1c-0.9,1.6-1.7,3-2.6,4.6c-31.8-17.5-63.8-34.8-95.6-52.3c1-1.8,2.2-3.9,3.2-5.8
				C693.7,338.5,725.2,356.3,756.9,374.1z"
          />
        </g>
        <path
          id="XMLID_6_"
          class="st6"
          d="M149.6,868.5c10.4,6.4,33.5,18.6,63.5,17c11.1-0.6,20.7-3,28.1-5.5c8-5.4,21.3-16.4,29.8-34.5
			c2.7-6,6.3-15.4,7.7-34.2c1.7-24-0.6-46.4-6-69.8c-4.6-21-13.5-50.7-30.6-84.5c-8-22.4-26-34.5-37-31.6
			c-12.3,3.1-23.5,26.3-13.8,53.5c-1.1,21.3-3.9,65.2-12.7,108.5c-4.1,19.8-8.3,35-14.6,50.8C159.5,849.6,154.6,859.7,149.6,868.5z"
        />

        <ellipse
          id="XMLID_5_"
          transform="matrix(-0.2748 -0.9615 0.9615 -0.2748 -343.6169 980.5698)"
          class="st4"
          cx="198"
          cy="619.9"
          rx="48.9"
          ry="48.6"
        />
      </g>
      <g>
        <g>
          <g>
            <g>
              <path
                class="st7"
                d="M423.5,731.9c-3.2,3.5-5.9,7-8.7,10.6c-7.9,10.1-15.2,20.7-23.2,30.8c-3.3,4.1-6.5,8.3-10.3,12
						c-1.9,1.8-3.9,3.5-6.3,4.6c-3.3,1.6-5.7,1-7.9-1.8c-2.6-3.4-4.1-7.3-5.5-11.3c-3.5-9.8-6.8-19.6-10.3-29.4
						c-1.1-3-2.2-6-3.7-9.1c-0.8,1.4-1,2.8-1.2,4.1c-3.7,19.1-8.5,38-12.3,57.1c-1,5-1.9,10-2.5,15.1c-0.4,3.5-5.3,6-8.5,4.4
						c-2.9-1.5-4.8-5.1-4.5-8.3c0.7-8.5,3.1-16.7,5.2-24.9c3.8-14.8,8.1-29.4,10.9-44.4c1.2-6.4,2-12.9,2.3-19.4
						c0.1-1.4,0.1-2.7,0.2-4.1c0.1-1.8,1-3.2,2.6-4.1c2.2-1.3,4.6-1.5,7.1-1.5c1.1,0,2,0.5,2.7,1.3c2.1,2.3,3.5,4.9,4.6,7.8
						c3.4,9.5,6.8,19,10.2,28.4c2.5,6.9,4.7,13.9,7.5,20.7c0.5,1.2,1,2.4,1.8,3.4c1,1.3,1.5,1.3,2.7,0.2c3.1-2.7,5.7-6,8.2-9.2
						c9.6-11.9,18.1-24.5,27.4-36.6c3.9-5.1,7.9-10.3,12.3-15c0.8-0.8,1.6-1.5,2.5-2.2c0.8-0.6,1.7-0.8,2.7-0.6c2.9,0.6,5.4,2,7.5,4
						c0.9,0.9,1.3,1.9,1.1,3.2c-1.6,12.2-3.2,24.4-4.8,36.7c-1.1,8.4-1.7,16.9-2.3,25.3c-0.3,4.1-0.7,8.1-0.9,12.2
						c-0.1,2.8,0.4,5.6,1.2,8.2c1.3,4,1.4,8,0.5,12.2c-0.7,3.1-1.7,4.1-4.8,4.7c-4.7,0.9-7.6-0.8-9-5.4c-0.4-1.5-0.7-3.1-0.7-4.7
						c-0.2-6.6,0.4-13.1,1-19.6c0.6-6.6,1.2-13.2,1.9-19.8c0.6-6,1.4-12,1.9-18c0.4-4.8,1.5-9.4,1.9-14.1
						C423.9,734.4,423.8,733.3,423.5,731.9z"
              />
              <path
                class="st7"
                d="M790.4,791.4c1.3-1.2,2.3-2.7,3.4-4.1c4.8-6.5,8.6-13.7,13-20.5c3.4-5.3,6.9-10.7,11-15.5
						c3-3.6,6.5-6.5,11.2-7.5c4.7-1.1,7.7,0.3,10,4.6c1.8,3.5,2.3,7.4,2.8,11.3c0.7,6.3,1,12.5,1.1,18.8c0.1,3.5,0.2,6.9,1.5,10.2
						c0.7,1.7,1.1,1.9,2.5,0.5c2.7-2.8,4.5-6.2,6.4-9.5c3.7-6.3,6.5-13.1,10.2-19.4c2.3-4,4.8-7.8,8-11.2c2.9-3.1,6.4-5.3,10.7-5.8
						c4.2-0.5,7.6,1.3,9.6,5.2c1.6,3.3,2.4,6.9,3.1,10.4c1,5.5,1.2,11.1,2.1,16.7c0.8,5.2,1.5,10.4,3.1,15.5
						c1.4,4.5,3.8,8.2,8.3,10.1c0.5,0.2,1,0.6,1.5,1c1.8,1.5,2.2,3.5,0.8,5.4c-4.2,5.6-11,8.2-17,1.4c-3.4-3.8-4.8-8.4-5.9-13.2
						c-1.5-6.3-2.2-12.7-2.9-19.2c-0.5-4.6-0.9-9.3-2-13.8c-1-4.1-2.9-4.7-6.1-2c-2.4,2-4.1,4.7-5.7,7.4
						c-4.4,7.2-8.3,14.7-12.2,22.2c-2.5,4.6-5,9.2-7.9,13.5c-1.3,1.9-2.7,3.7-4.6,5c-2.9,2-6.3,1.8-9-0.6c-2.3-2.1-3.5-4.6-3.8-7.6
						c-0.7-6.4-1-12.8-1.3-19.2c-0.4-6-0.7-12.1-2-18c-0.2-1-0.5-2-0.9-2.9c-0.6-1.5-1-1.6-2.4-0.8c-1.5,0.9-2.7,2.1-3.8,3.4
						c-5.1,5.9-9.2,12.5-13.3,19.1c-5.2,8.5-10,17.2-14.8,25.9c-2.3,4.2-5.6,6.2-10.1,7c-3.7,0.6-5.1-0.2-6.1-3.8
						c-1-3.4-1.2-6.9-1.3-10.4c-0.3-9.1,0.9-18,2.2-27c1.2-8.1,2.5-16.2,3.7-24.4c0.3-2,0.4-4.1,0.4-6.1c0.1-2.5,0.9-4.1,2.7-4.5
						c2.9-0.6,6.2,0,7.9,3.1c1.3,2.2,1.6,4.6,1.7,7c0.1,4.2-0.5,8.4-1.1,12.5c-1.3,9.5-3.2,18.8-4.4,28.3c-0.1,0.5-0.1,1-0.1,1.5
						C790.2,791.3,790.3,791.3,790.4,791.4z"
              />
              <path
                class="st7"
                d="M535.2,810.6c0.2-6,0.7-12,1.3-17.9c0.8-7.4,1.8-14.8,2.7-22.2c1.3-10.3,2.9-20.6,4.5-30.9
						c0.5-3.1,0.9-6.3,1.4-9.4c0.2-1.7-0.5-3.2-0.9-4.8c-0.3-1.2-0.7-2.3-0.9-3.5c-0.7-3.7,3.2-7.9,7-7.5c4.1,0.4,8.2,1.2,12.3,0.8
						c5.2-0.5,10.3-1.1,15.4-1.6c5.8-0.6,11.6-1.3,17.4-1.7c3.8-0.3,7.6-0.5,11.4,0c2.3,0.3,4.4,1.1,6.1,2.8
						c1.3,1.2,1.7,2.7,1.4,4.4c-0.8,4.7-4.9,7.5-9.6,6.7c-4-0.7-8-1.3-12-1.3c-5.5,0-11,0.4-16.5,1c-4.4,0.5-8.8,1-13.1,1.9
						c-2.9,0.6-4.5,1.8-5.2,4.9c-2,8.7-3.9,17.5-4.7,26.4c-0.2,2-0.3,4-0.5,6c0,0.6-0.1,1.2,0.9,1.1c8.5-1.1,17-2,25.5-3.2
						c4.7-0.6,9.2-2.3,13.9-3.2c2.6-0.5,5.2-0.6,7.7,0.4c4.3,1.6,5.2,6.6,1.7,9.5c-2.9,2.5-6.4,3.2-10,3.7
						c-10.3,1.5-20.8,2.3-31.1,3.9c-2.6,0.4-5.2,0.8-7.8,1.8c-1.7,0.6-2.7,1.7-2.9,3.5c-0.5,3.8-0.8,7.7-1.1,11.5
						c-0.2,3.2-0.5,6.5-0.5,9.7c0,3.7,0.1,7.4,0.2,11.2c0,1.1-0.1,2.2-0.5,3.3c-0.7,2.2-2.3,3.4-4.7,3.5c-4.8,0.1-8.9-3.9-9-8.9
						C535.2,811.8,535.2,811.2,535.2,810.6z"
              />
              <path
                class="st7"
                d="M971,789.9c-1.7,2.1-3.2,4.1-4.8,5.9c-6.1,7.1-12.9,13.4-21.5,17.4c-3,1.4-6.2,2.3-9.5,2.6
						c-5.7,0.4-10.9-3.1-13.3-8.8c-1.6-4-1.6-8.1-1.2-12.3c1.1-10.3,5.4-19.5,11.1-28.1c5.1-7.7,10.7-14.9,17.9-20.7
						c4.9-4,10.3-7,16.6-8c2.4-0.4,4.8-0.6,7.1,0.3c2.7,1.1,4.4,3.2,5.5,5.8c0.7,1.7,1.7,2.8,3.6,2.6c0.2,0,0.4,0.1,0.6,0.1
						c3.5,0.5,4.9,2.3,4.5,5.8c-0.4,3.2-1,6.3-1.6,9.5c-1.4,8.7-3,17.3-2.9,26.1c0.1,4.3,0.4,8.6,1.8,12.7c1,2.7,2.4,4.9,5,6.4
						c3.4,1.9,3.6,5.1,0.7,7.7c-2.4,2.1-5,3.6-8.4,3c-2.9-0.5-4.8-2.5-6.3-4.9c-2.6-4-3.6-8.6-4.3-13.2
						C971.3,796.7,971.4,793.4,971,789.9z M973.4,758.9c0-1.5-0.1-3.6-0.9-5.7c-1-2.4-2.8-3.7-5.5-3.7c-1.8,0-3.5,0.5-5.2,1.2
						c-3.1,1.4-5.8,3.6-8.1,6c-7.3,7.7-13.2,16.3-17.5,26c-2.2,5-3.7,10.2-2.9,15.8c0.6,4.1,3.1,5.9,7.2,4.9
						c2.6-0.6,4.9-1.9,6.9-3.5c8.4-6.7,15.6-14.5,21.1-23.8C971.5,771,973.5,765.6,973.4,758.9z"
              />
              <path
                class="st7"
                d="M454.7,792.6c0.1-11.8,3.6-22.7,9.9-32.6c4.1-6.6,8.9-12.6,15.2-17.3c5.4-4,11.4-6.4,18.2-6.5
						c8.3-0.1,12.9,6.5,12.4,13c-0.6,8.5-5.1,14.7-11.1,20.2c-8.2,7.5-17.5,13.4-27.3,18.7c-0.3,0.2-0.7,0.4-1,0.6
						c-3.2,1.9-4,3.6-3.4,7.3c1.1,6.5,5.4,10.1,12.1,10.3c6.6,0.2,12.6-1.9,18.1-5.3c4.9-3,9.2-6.8,13.2-11c0.7-0.8,1.5-1.5,2.4-2.1
						c1.9-1.4,3.6-0.7,4.1,1.6c0.4,2-0.1,3.9-0.9,5.7c-1.9,4.2-5.1,7.4-8.5,10.4c-5.4,4.9-11.4,9-18.4,11.2
						c-6.5,2.1-13.2,2.6-19.8,0.6c-8.3-2.5-12.7-8.4-14.5-16.6C454.9,798.1,454.7,795.4,454.7,792.6z M469,779.4
						c0.6-0.3,0.9-0.5,1.2-0.7c3.4-2.1,6.8-4.2,10-6.5c4.9-3.4,9.6-7,13.6-11.5c2-2.3,3.9-4.8,4.4-8c0.8-4.3-1.3-6.3-5.6-5.3
						c-3.5,0.8-6.2,2.9-8.7,5.3c-6.6,6.3-11.2,13.9-13.9,22.6C469.6,776.6,469.1,777.8,469,779.4z"
              />
              <path
                class="st7"
                d="M616.4,793.6c0.1-12.4,3.6-22.5,9.2-31.9c3.5-5.9,7.5-11.3,12.9-15.5c8-6.2,16.9-8.7,26.9-6
						c5,1.3,8.9,4.5,12,8.6c5.2,7,6.9,15,6.2,23.6c-1.1,12.2-6.7,22.2-14.9,31c-4,4.2-8.3,7.9-13.4,10.7c-6.3,3.5-12.9,5.2-20.1,4.1
						c-5.4-0.8-9.6-3.6-13-7.9C617.9,805.1,616.3,799,616.4,793.6z M629.1,790c0,5.4,0.8,9.7,3.5,13.4c1.8,2.4,4.2,3.6,7.2,3.7
						c3.7,0,7.2-0.8,10.3-2.8C662,797,670,787,671.4,772.7c0.5-4.7,0-9.5-2.2-13.8c-3.3-6.5-7.8-8.4-13.1-8.3
						c-4.9,0.1-9.1,2.3-12.6,5.7c-3.3,3.2-5.7,7-7.8,10.9C631.8,774.6,629.2,782.4,629.1,790z"
              />
              <path
                class="st7"
                d="M714.8,785.3c0.4-0.8,0.8-1.7,1.2-2.5c4-9.4,8.9-18.3,15.3-26.2c4.4-5.5,9-10.7,15.3-14.1
						c5-2.7,10.2-3.6,15.6-1.4c4.7,1.9,8,6.6,7.9,11.3c-0.1,4.6-4,7.1-8.2,5.4c-1.2-0.5-2.1-1.2-3-2.1c-2.9-2.9-5.5-3.3-9.2-1.4
						c-3.2,1.6-5.8,4-8.1,6.6c-5.9,6.5-11.1,13.6-15.2,21.4c-4.2,8.1-7,16.6-8.4,25.7c-0.3,2.2-0.6,4.3-1.2,6.4
						c-1.3,4.2-5.2,5-8.3,4.6c-1.6-0.2-2.6-1.4-3.3-2.8c-1.4-2.6-1.8-5.5-2.1-8.4c-0.4-4.1-0.6-8.3-0.5-12.4
						c0.3-10.4,0.4-20.7,1.5-31c0.6-5.4,1.2-10.7,2-16c0.6-4,3.6-6.9,7.3-7.7c3.8-0.7,6.7,1.6,6.6,5.5c0,3.6-1.3,6.9-2.1,10.3
						c-1.5,5.8-2.3,11.7-2.8,17.7c-0.2,3.1-0.5,6.2-0.5,9.4c0,0.6,0,1.2,0,1.9C714.6,785.3,714.7,785.3,714.8,785.3z"
              />
            </g>
          </g>
        </g>
        <g class="st8">
          <path
            class="st4"
            d="M421.8,732.6c-4.7,5.2-11.9,14.4-21.6,27.8c-9.7,13.3-16.6,21.8-20.6,25.6c-4,3.8-7.2,5.7-9.7,5.7
				c-1.9,0-3.8-1.4-5.6-4.3c-1.8-2.9-4.2-8.7-7.1-17.6c-6-17.9-9.8-28.3-11.4-31c-0.6,1.6-1.2,4.2-1.9,7.8c-1.5,7.6-3.5,16.9-6,27.9
				c-4.9,21-7.6,35-8.3,41.8c-0.1,0.8-0.9,1.8-2.3,2.8c-1.4,1-2.6,1.5-3.5,1.5c-1.7,0-3.3-0.8-4.7-2.5c-1.4-1.7-2.2-3.3-2.2-4.9
				c0-4.1,1.4-11.4,4.1-21.7l6.1-23.2c3.8-14.6,6.1-25.3,7-32.2c0.9-6.9,1.3-12.3,1.3-16.2c0-4.1,3.2-6.1,9.7-6.1
				c1.4,0,2.8,1.2,4.3,3.6c1.5,2.4,2.5,4.5,3,6.3c0.6,1.8,1.3,3.8,2.2,6l9.5,26.5c3.7,10.8,6,16.9,6.9,18.3c0.9,1.4,1.6,2.1,2.2,2.1
				c2.4-0.5,10.9-10.9,25.5-31c16.2-22.4,25.5-33.5,27.8-33.5c1.9,0,4,0.7,6.3,2.2c2.3,1.5,3.4,2.8,3.4,3.8c0,0.5-1.4,10.8-4.1,31
				c-1,7.5-1.9,16.5-2.8,26.9c-0.8,10.5-1.3,16.4-1.3,17.7c0,2.4,0.4,5.1,1.3,8.1c0.8,2.6,1.1,4.8,1.1,6.4c0,1.3-0.2,3.1-0.7,5.5
				c-0.5,2.4-1.5,3.8-3.1,4.3c-1.6,0.4-2.8,0.6-3.8,0.6c-4.9,0-7.4-4.1-7.4-12.4c0-7.5,1.6-26,4.9-55.3c0.2-2.4,0.4-3.8,0.4-4.4
				c0-0.4,0.3-2,0.8-4.7c0.4-2.4,0.6-4.3,0.6-5.9C422.2,734.2,422,733.2,421.8,732.6z"
          />
          <path
            class="st4"
            d="M515.9,792c0,4.8-4.3,10.6-12.7,17.4c-8.5,6.8-17.3,10.2-26.3,10.2c-7.6,0-13.4-2.2-17.6-6.7
				c-4.1-4.5-6.2-10.7-6.2-18.7c0-13.5,4.5-26.3,13.6-38.4c9.1-12.1,19-18.2,29.8-18.2c3.5,0,6.4,1.2,8.7,3.5
				c2.2,2.3,3.4,5.1,3.4,8.3c0,7.3-3.6,14.2-10.7,20.8c-7.1,6.6-16.6,13.2-28.5,19.6c-2.4,1.3-3.7,2.9-3.7,4.9c0,3.9,1.1,7,3.4,9.4
				c2.3,2.4,5.6,3.5,10,3.5c10.1,0,20.5-5.6,31-16.8c1.6-1.6,2.7-2.3,3.5-2.3C515.1,788.4,515.9,789.6,515.9,792z M466.8,781
				c20-11.9,29.9-21.4,29.9-28.6c0-2.8-1.3-4.2-3.8-4.2c-4.3,0-9.2,3.2-14.7,9.5S469,771.8,466.8,781z"
          />
          <path
            class="st4"
            d="M557.3,729.8c-0.9,1.6-2.2,6.7-3.9,15.4c-1.7,8.7-2.5,16-2.5,21.9c3.2-0.5,6.6-1,10-1.3l14.8-1.7
				c2.4-0.3,5.7-1.1,9.9-2.2c4.3-1.1,7.2-1.7,8.8-1.7c5.6,0,8.3,2.3,8.3,6.8c0,0.9-0.8,2-2.4,3.4c-1.6,1.4-3.7,2.3-6.3,2.9
				c-2.6,0.6-8.9,1.4-18.9,2.5c-12.2,1.4-19.5,2.5-21.8,3.2c-2.3,0.7-3.6,1.5-4.1,2.2c-0.4,0.7-0.9,4.1-1.3,10.2
				c-0.5,6.1-0.7,11.1-0.7,14.9l0.3,8.8c0,4.8-1.7,7.2-5.2,7.2c-2.2,0-4.2-0.8-6-2.5c-1.8-1.6-2.8-3.9-2.8-6.6
				c0-10.9,2.7-33.9,8.1-69.1c1.3-8.2,1.9-12.6,1.9-13.2c0-0.9-0.3-2.3-0.8-4.1c-0.8-2.3-1.1-3.9-1.1-4.8c0-1.3,0.7-2.6,2.1-4.1
				c1.4-1.5,2.8-2.2,4-2.2l9.3,1.1c2.9,0,8.2-0.5,16-1.4c13.3-1.6,22.4-2.4,27.3-2.4c4.3,0,7.4,0.7,9.3,2c1.9,1.3,2.8,2.6,2.8,3.9
				c0,2.3-0.8,4.2-2.3,5.8c-1.5,1.6-3.2,2.4-4.9,2.4c-0.8,0-1.7-0.1-2.6-0.3c-5.1-0.8-9-1.3-11.7-1.3c-5,0-11.6,0.5-19.6,1.4
				C563.4,727.7,558.7,728.7,557.3,729.8z"
          />
          <path
            class="st4"
            d="M637,819.4c-6.5,0-11.8-2.4-16-7.3c-4.2-4.9-6.3-10.9-6.3-18.2c0-12.1,4.1-24.1,12.3-35.9
				c8.2-11.8,18-17.7,29.3-17.7c7.5,0,13.6,2.9,18.3,8.7c4.7,5.8,7,12.7,7,20.7c0,12.2-4.8,23.6-14.5,34
				C657.5,814.2,647.5,819.4,637,819.4z M627.2,792.1c0,4.8,1,8.6,3,11.7c2,3,4.6,4.5,7.9,4.5c7,0,14.1-3.8,21.1-11.5
				s10.6-16.3,10.6-25.7c0-5.8-1.4-10.5-4.1-14.1c-2.7-3.6-6.3-5.4-10.9-5.4c-7.6,0-14.1,4.7-19.5,13.9
				C629.9,774.8,627.2,783.7,627.2,792.1z"
          />
          <path
            class="st4"
            d="M712.4,787.9c0.5-0.8,1-2,1.7-3.5c3.2-7.3,6.1-13.1,8.8-17.3c2.7-4.2,5.6-8.2,8.9-12
				c3.2-3.8,6.2-6.7,8.8-8.7c2.7-2,5.1-3.4,7.2-4.1c2.2-0.8,4.3-1.2,6.3-1.2c4.1,0,7.4,1.2,10,3.7c2.6,2.5,3.9,5.4,3.9,8.7
				c0,1.5-0.5,2.8-1.4,4c-0.9,1.2-2.3,1.8-4.2,1.8c-2,0-3.8-0.9-5.4-2.6c-1.7-1.7-3.5-2.6-5.3-2.6c-4.9,0-11.7,5.9-20.3,17.8
				c-8.7,11.9-13.9,24.9-15.8,39.2c-0.5,3.7-1.4,6.1-2.7,7.3c-1.3,1.2-3.2,1.8-5.6,1.8c-2.3,0-4-1.7-5-5.2c-1-3.4-1.6-9.2-1.6-17.4
				c0-2.2,0.1-7.1,0.4-14.6c0.3-8.9,0.8-16.1,1.7-21.5c0.1-0.9,0.4-3,0.7-6.3c0.3-3.6,0.7-6.1,1.2-7.6s1.5-2.7,3-4
				c1.5-1.2,3.2-1.8,5-1.8c1.7,0,3,0.6,3.9,1.7c0.9,1.1,1.3,2.3,1.3,3.6c0,1.8-0.5,4.4-1.4,7.8c-1.1,3.7-1.7,6.3-1.9,7.7
				c-0.1,1.6-0.4,3.3-0.8,5c-0.5,2-0.9,7.3-1.4,16l-0.1,2.6V787.9z"
          />
          <path
            class="st4"
            d="M826.6,760c-4.4,0.1-13.7,13.1-28.1,38.9c-4.8,8.7-7.7,13.5-8.5,14.2c-0.8,0.8-2.3,1.5-4.4,2.1
				c-2.1,0.6-3.9,0.9-5.3,0.9c-3.2,0-4.7-6.1-4.7-18.4c0-6.9,1.5-19.4,4.4-37.6c1.2-7.3,1.8-12.6,1.8-15.8c0-3.1,1.4-4.7,4.2-4.7
				c5.2,0,7.8,3.8,7.8,11.3c0,3.5-0.9,10.5-2.6,20.8c-2,11.8-3.1,18.9-3.1,21.5c2.9-2.8,7.2-9,12.9-18.6c8-13.1,13.9-21.4,17.7-24.9
				c3.8-3.5,7.7-5.3,11.8-5.3c3.1,0,5.5,2,7.3,6.1c1.8,4.1,2.9,13.9,3.3,29.3c0.1,3.9,0.4,6.7,1.1,8.6c0.6,1.9,1.1,2.8,1.3,2.8
				c2.2,0,7-7.5,14.4-22.6c4-8,7.8-14,11.5-18.2c3.7-4.1,7.9-6.2,12.5-6.2c3.8,0,6.6,2,8.3,5.9c1.7,3.9,3,9.8,3.7,17.5
				c0.7,7.8,1.8,15,3.3,21.5c1.5,6.5,4.2,10.7,8.2,12.5c2.7,1.3,4,2.8,4,4.4c0,1.6-1.1,3.3-3.4,5.1c-2.3,1.7-4.3,2.6-6.2,2.6
				c-3.8,0-7.1-2.3-10.1-6.9c-3-4.6-5.2-14.6-6.7-29.9c-0.7-6.2-1.3-10.5-1.9-13.1c-0.6-2.6-1.6-3.9-2.9-3.9c-2,0-4.3,1.9-7,5.7
				c-2.7,3.8-7.1,11.4-13.1,23c-4.7,9-8.3,15-10.7,18c-2.4,3-4.8,4.5-7,4.5c-2,0-3.8-0.8-5.4-2.4c-1.6-1.6-2.6-3.6-3-5.9
				c-0.4-2.3-0.9-7.9-1.3-16.8C829.7,771.2,828.4,762.5,826.6,760z"
          />
          <path
            class="st4"
            d="M969.5,790.2c-6.6,8.9-13.1,15.5-19.6,19.9c-6.5,4.4-12.4,6.6-17.7,6.6c-3.6,0-6.8-1.5-9.4-4.6
				c-2.6-3.1-4-7-4-11.8c0-12.4,5.5-25.7,16.6-40c11.1-14.3,22.1-21.4,33-21.4c4,0,6.9,2.3,8.8,6.8c0.6,1.5,1.6,2.3,2.9,2.3
				c3.8,0,5.7,1.5,5.7,4.4c0,1.5-0.2,3.5-0.7,6c-2.5,13.9-3.8,23.7-3.8,29.4c0,11.4,2.2,18.2,6.5,20.5c2.1,1.1,3.1,2.5,3.1,4.2
				c0,1.5-1,2.9-3,4.4s-4,2.2-5.9,2.2c-3.6,0-6.6-2.5-9-7.5C970.7,806.5,969.5,799.4,969.5,790.2z M965.2,750.4
				c-6.2,0-13.4,5.8-21.7,17.5c-8.2,11.7-12.4,21.3-12.4,29c0,5.4,1.8,8.1,5.4,8.1c5.2,0,12.4-5.2,21.5-15.7
				c9.2-10.5,13.8-20.1,13.8-28.8C971.8,753.8,969.6,750.4,965.2,750.4z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'meforma'
}
</script>
<style lang="stylus" scoped>
.st0 {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 46;
  stroke-miterlimit: 10;
}

.st1 {
  fill: #151d34;
}

.st2 {
  fill: #f9dc5c;
}

.st3 {
  fill: #cb5551;
}

.st4 {
  fill: #e72a4f;
}

.st5 {
  fill: #f5fafa;
}

.st6 {
  fill: #dcbb2b;
}

.st7 {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 19.2842;
  stroke-miterlimit: 10;
}

.st8 {
  enable-background: new;
}
</style>
