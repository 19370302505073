<template>
  <BottomMessage>
    <p><b>Política de cookies</b></p>
    <Spacing top="1">
      <p>
        Utilizamos cookies para rastrear as páginas que você viu, monitorar
        padrões de tráfego e para medir a popularidade dos serviços que
        oferecemos. Utilizamos essas informações para fornecer conteúdo e
        serviços relevantes para você.
      </p>
      <Spacing top="1">
        <p>
          A lei diz que podemos armazenar cookies no seu dispositivo se forem
          estritamente necessários para o funcionamento deste site. Para outros
          tipos de cookies precisamos da sua permissão.
        </p>
      </Spacing>
      <Spacing top="1">
        Estes são os cookies extra que utilizamos. Desmarque os que não
        concordar.
      </Spacing>
      <Spacing top="2">
        <label for="crisp">
          <input
            @change="change($event, 'crisp')"
            id="crisp"
            type="checkbox"
            checked
          />
          Crisp
        </label>
        <div>
          <small
            >O crisp é o serviço de chat que utilizamos no MeForma, através
            dele, você consegue falar conosco rapidamente.</small
          >
        </div>
      </Spacing>
      <Spacing top="1">
        <label for="ga">
          <input
            @change="change($event, 'google')"
            id="ga"
            type="checkbox"
            checked
          />
          Google Analytics
        </label>
        <div>
          <small>
            O Google Analytics é o serviço de rastreamento que utilizamos para
            saber quais as funcionalidade mais utilizadas, a fim de melhorar
            cada vez mais a sua experiência com o MeForma.
          </small>
        </div>
      </Spacing>
      <Spacing top="1">
        <label for="hotjar">
          <input
            @change="change($event, 'hotjar')"
            id="hotjar"
            type="checkbox"
            checked
          />
          Hotjar
        </label>
        <div>
          <small>
            O Hotjar cria mapas de calor que nos permite saber as regiões mais
            acessadas de nossas páginas.
          </small>
        </div>
      </Spacing>
    </Spacing>
    <template v-slot:buttons>
      <div class="v--flex v--flex-align-middle" style="gap: 16px">
        <Spacing top="2">
          <a @click.prevent="$store.auth.logout()">Não aceito</a>
        </Spacing>
        <Spacing top="2">
          <Button
            style="width: 130px"
            @click="ok"
            :loading="$wait.is('user/settings')"
          >
            Ok, eu aceito
          </Button>
        </Spacing>
      </div>
    </template>
  </BottomMessage>
</template>
<script>
export default {
  name: 'CookiesBanner',
  data() {
    return {
      form: {
        google: true,
        hotjar: true,
        crisp: true
      }
    }
  },
  methods: {
    change($event, type) {
      this.form[type] = $event.checked ? 1 : 0
    },
    ok() {
      this.$store.auth.settings(this.form).catch(() => {
        this.$toast.error('Houve um erro. Por favor, recarregue a página.')
      })
    }
  }
}
</script>
