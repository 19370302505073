import { reactive } from 'vue'

export default class Discipline {
  constructor(ctx) {
    this.ctx = ctx
    this.state = reactive({
      discipline: null,
      relateds: null
    })
  }

  reset() {
    this.state.relateds = null
    this.state.discipline = null
  }

  get() {
    return this.state.discipline
  }

  getDiscipline(payload) {
    this.ctx.config.globalProperties.$wait.start(`discipline/get`)

    const { university, discipline } = payload
    return this.ctx.config.globalProperties.$axios
      .get(`disciplines/${university}/${discipline}`)
      .then((result) => {
        this.setDiscipline(result.data && result.data.data)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`discipline/get`)
      })
  }

  getRelateds(payload) {
    this.ctx.config.globalProperties.$wait.start(`discipline/relateds`)
    const { university, discipline } = payload

    return this.ctx.config.globalProperties.$axios
      .get(`disciplines/${university}/${discipline}/relateds`)
      .then((result) => {
        this.setRelateds(result.data && result.data.items)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`discipline/relateds`)
      })
  }

  setRelateds(data) {
    this.state.relateds = data
  }

  setDiscipline(data) {
    this.state.discipline = data
  }

  search(payload) {
    this.ctx.config.globalProperties.$wait.start(`discipline/search`)
    return this.ctx.config.globalProperties.$axios
      .get('disciplines/search', {
        params: payload
      })
      .then((result) => {
        return result.data && result.data.items
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`discipline/search`)
      })
  }
}
