<template>
  <div v-if="!replied" class="c-nps">
    <div class="c-nps__content">
      <div class="c-nps__label">
        O quanto você indicaria o MeForma a uma pessoa amiga?
      </div>
      <div class="c-nps__body">
        <Button
          :class="{ 'c-nps__btn--active': selected === i - 1 }"
          v-for="i in 11"
          :key="i"
          @click="selected = i - 1"
        >
          {{ i - 1 }}</Button
        >
      </div>
      <div>
        <Button @click="reply"> Responder </Button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Nps',
  data() {
    return {
      selected: null,
      replied: false
    }
  },
  methods: {
    reply() {
      this.$store.auth.replyNps({ score: this.selected })
      this.$toast.success('Obrigado!')
      this.replied = true
    }
  }
}
</script>
<style src="./style.styl" lang="stylus" scoped />
