<template>
  <div class="c-absence-counter">
    <Tag class="c-absence-counter__tag" :class="classes"
      >FALTAS: {{ absence }}%</Tag
    >
    <div class="c-absence-counter__controllers">
      <Button @click="change(-1)" v-tooltip:bottom="'Remover 1 falta'">
        <Icon name="minus" size="13" />
      </Button>
      <div class="c-absence-counter__value">
        {{ value }}
      </div>
      <Button @click="change(+1)" v-tooltip:bottom="'Adicionar 1 falta'">
        <Icon name="plus" size="13" />
      </Button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AbsenceCounter',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      adittion: 0
    }
  },
  computed: {
    value() {
      return this.item.absence ? parseInt(this.item.absence) + this.adittion : 0
    },
    absence() {
      let a = Number(this.value)
      let b = Number(this.item.hours)
      return b ? `${((100 * a) / b).toFixed(1)}`.replace('.', ',') : '0,0'
    },
    classes() {
      let absence = parseFloat(this.absence)
      if (absence >= 18 && absence < 25) {
        return 'v--bg-warning'
      }
      return absence >= 25 ? 'v--bg-fail' : 'v--bg-light'
    }
  },
  methods: {
    change(charge) {
      this.adittion = this.adittion + charge
      this.$store.semester.updateDisciplineAbsence({
        id: this.item.semester_discipline_id,
        absence: this.value
      })
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
