<template>
  <div class="p-profile">
    <ProfileMenu />
    <div>
      <p>
        Aqui você pode solicitar um relatório com todos os dados que o MeForma
        tem sobre você, inclusive seus likes, deslikes e comentários. Essa
        operação vai acontecer nessa tela mesmo, por tanto, após solicitar, não
        feche a página.
      </p>
    </div>
  </div>
</template>
<script>
import ProfileMenu from '@/components/profile/ProfileMenu/ProfileMenu.vue'
export default {
  title: 'Dados - MeForma!',
  name: 'data',
  route: '/perfil/dados',
  layout: 'painel',
  middleware: ['auth'],
  components: { ProfileMenu }
}
</script>

<style lang="stylus" scoped>
.p-profile {
  max-width: 1016px;
  margin: 0 auto;
  display grid
  grid-template-columns 200px 1fr
}
</style>
