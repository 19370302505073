<template>
  <div class="c-create-post">
    <template v-if="disciplineId">
      <Field
        class="c-create-post__input"
        placeholder="O que você deseja publicar?"
        @click="openModal()"
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        readonly
        name="title"
      />
      <Spacing :vertical="2">
        <Line />
      </Spacing>
      <div class="c-create-post__buttons">
        <Button
          v-for="option in options"
          :key="option.value"
          @click="openModal(option.value)"
        >
          {{ option.name }}
        </Button>
      </div>
    </template>
    <div v-else class="c-create-post__loading">
      <div class="c-create-post__loading-input" />
      <Spacing :vertical="2">
        <Line />
      </Spacing>
      <div class="c-create-post__loading-buttons">
        <div
          v-for="option in options"
          :key="option.value"
          class="c-create-post__loading-button"
        ></div>
      </div>
    </div>
    <Modal v-if="modal" small @close="closeModal">
      <form id="create-post" @submit="submit">
        <FileUpload
          required
          label="Arquivo*"
          block
          name="file"
          @load="onLoad"
          @size-exceeded="onSizeExceeded"
        />

        <Field
          required
          label="Nome do arquivo*"
          name="name"
          placeholder="Ex: Exercício resolvido"
          v-model="form.name"
        />
        <FieldText
          name="description"
          label="Descrição*"
          rows="4"
          placeholder="Ex: Exercícios resolvidos sobre derivada e integral"
          v-model="form.description"
        />
        <FieldSelect
          label="Tipo de contribuição*"
          :options="options"
          :selected="selected"
          name="category"
          @change="selected = $event.target.value"
        />
        <Field
          label="Semestre de referência"
          class="c-create-post__input"
          placeholder="Ex: 2019.2"
          name="semester"
          v-model="form.semester"
        />
      </form>
      <template v-slot:footer>
        <div class="v--flex v--flex-align-right">
          <Button @click="closeModal">Cancelar</Button>
          <Spacing left="1">
            <Button
              form="create-post"
              class="v--bg-orange-yellow-crayola"
              :disabled="$wait.is('uploading')"
              :loading="$wait.is('uploading')"
              style="width: 176px"
            >
              Contribuir com arquivo
            </Button>
          </Spacing>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
export default {
  name: 'CreatePost',
  props: {
    disciplineId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      load: null,
      modal: false,
      selected: '',
      options: [
        { name: 'Prova', value: 'PROVA' },
        { name: 'Trabalho', value: 'TRABALHO' },
        { name: 'Exercício', value: 'LISTA' },
        { name: 'Resumo/Anotação', value: 'RESUMO' },
        { name: 'Outra coisa', value: 'OUTRO' }
      ],
      form: {
        name: '',
        description: '',
        category: '',
        semester: ''
      }
    }
  },
  methods: {
    onSizeExceeded() {
      this.$toast.error('Arquivo muito grande!')
    },
    onLoad(load) {
      this.load = load
    },
    openModal(selected) {
      this.selected = selected || 'LISTA'
      this.modal = true
    },
    closeModal() {
      this.selected = ''
      this.modal = false
    },
    submit(e) {
      e.preventDefault()
      if (!this.load || !this.load.data) {
        this.$toast.error('Você precisa adicionar algum arquivo!')
        return
      }

      this.$wait.start('uploading')
      this.form.category = this.selected
      this.form.discipline_id = this.disciplineId
      this.form.file = this.load.data
      this.form.local_name = this.load.localName
      this.form.extension = this.load.extension

      this.$store.post
        .post(this.form)
        .then(() => {
          return this.$store.post.getPosts(this.$route.params).then(() => {
            this.$toast.success(`Publicado!`)
            this.closeModal()
          })
        })
        .catch((e) => {
          let message = 'Houve um erro, por favor recarregue a página'

          if (e.response && e.response.data && e.response.data.message) {
            message = e.response.data.message
          }

          this.$toast.error(message)
        })
        .finally(() => this.$wait.end('uploading'))
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
