import { reactive } from 'vue'
import { $axios } from '../../plugins/axios'

export default class Auth {
  ctx
  constructor(ctx = null) {
    this.ctx = ctx

    this.state = reactive({
      user: localStorage.user ? JSON.parse(localStorage.user || {}) : {},
      token: localStorage.token || null,
      progress: {},
      posts: []
    })
  }

  reset() {
    this.state.user = {}
    this.state.token = null
    this.state.progress = {}
    this.state.posts = []
  }

  logout() {
    return this.ctx.$axios.get('auth/logout').finally(() => {
      this.reset()
      this.ctx.config.globalProperties.$store.settings.unsetDarkMode()
      this.ctx.config.globalProperties.$store.component.reset()
      this.ctx.config.globalProperties.$store.discipline.reset()
      this.ctx.config.globalProperties.$store.semester.reset()
      localStorage.clear()
      this.ctx.config.globalProperties.$router.push('/login')
    })
  }

  setUser(user) {
    this.state.user = user
    return localStorage.setItem('user', JSON.stringify(user))
  }

  setToken(token) {
    this.state.token = token
    return localStorage.setItem('token', token)
  }

  setProgress(progress) {
    this.state.progress = progress
  }

  setPosts(posts) {
    this.state.posts = posts
  }

  updateProfile(payload) {
    this.ctx.config.globalProperties.$wait.start('user/update')
    return this.ctx.$axios
      .put('user', payload)
      .then((result) => {
        if (result) {
          this.setUser(result.data && result.data.data)
        }
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end('user/update')
      })
  }

  login(payload) {
    return this.ctx.$axios.post('auth', payload).then((result) => {
      this.setUser(result.data && result.data.data && result.data.data.user)
      return this.setToken(
        result.data && result.data.data && result.data.data.token
      )
    })
  }

  register(payload) {
    return this.ctx.$axios.post('auth/register', payload).then((result) => {
      this.setUser(result.data && result.data.data && result.data.data.user)
      return this.setToken(
        result.data && result.data.data && result.data.data.token
      )
    })
  }

  recovery(payload) {
    return this.ctx.$axios.post('auth/recovery', payload)
  }

  resetPassword(payload) {
    return this.ctx.$axios.post('auth/reset', payload)
  }

  verifyEmailAddress({ expires, hash, id, signature }) {
    this.ctx.config.globalProperties.$wait.start('auth/verifyEmail')

    return this.ctx.$axios
      .get('auth/verify-email-address', {
        params: {
          expires,
          hash,
          id,
          signature
        }
      })
      .then((result) => {
        if (result) {
          this.setUser(result.data && result.data.data)
        }
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end('auth/verifyEmail')
      })
  }

  resendEmail() {
    this.ctx.config.globalProperties.$wait.start('auth/resendEmail')
    return this.ctx.$axios.post('auth/resend-email').finally(() => {
      this.ctx.config.globalProperties.$wait.end('auth/resendEmail')
    })
  }

  getUser() {
    const axios = this.ctx ? this.ctx.$axios : $axios
    return axios.get('user').then((result) => {
      if (result) {
        this.setUser(result.data && result.data.data)
      }
      return result
    })
  }

  changePassword(payload) {
    this.ctx.config.globalProperties.$wait.start('auth/password')

    return this.ctx.$axios.put('auth/password', payload).finally(() => {
      this.ctx.config.globalProperties.$wait.end('auth/password')
    })
  }

  getProgress() {
    this.ctx.config.globalProperties.$wait.start('user/progress')
    return this.ctx.$axios
      .get('user/progress')
      .then((result) => {
        this.setProgress(result.data && result.data.data)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end('user/progress')
      })
  }

  getPosts() {
    this.ctx.config.globalProperties.$wait.start('user/posts')

    return this.ctx.$axios
      .get('user/posts')
      .then((result) => {
        this.setPosts(result.data && result.data.items)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end('user/posts')
      })
  }

  remove(payload) {
    this.ctx.config.globalProperties.$wait.start('user/delete')

    return this.ctx.$axios.post('user/delete', payload).finally(() => {
      this.ctx.config.globalProperties.$wait.end('user/delete')
    })
  }

  settings(payload) {
    this.ctx.config.globalProperties.$wait.start('user/settings')
    return this.ctx.$axios
      .post('user/settings', payload)
      .then((result) => {
        if (result) {
          this.setUser(result.data && result.data.data)
        }
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end('user/settings')
      })
  }

  replyNps(payload) {
    let user = localStorage.user ? JSON.parse(localStorage.user || {}) : {}
    if (user.id) {
      user.settings.nps = true
      this.setUser(user)
    }
    return this.ctx.$axios.post('user/nps', payload)
  }
}

export const auth = new Auth()
