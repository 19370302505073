export function hourToMinute(hour) {
  const h = hour.split(':')
  return parseInt(h[0]) * 60 + parseInt(h[1])
}

export const days = {
  SEG: 'd1',
  TER: 'd2',
  QUA: 'd3',
  QUI: 'd4',
  SEX: 'd5',
  SAB: 'd6'
}

export const hours = {
  '07:00': 'h1',
  '07:55': 'h2',
  '08:50': 'h3',
  '09:45': 'h4',
  '10:40': 'h5',
  '11:35': 'h6',
  '12:30': true,
  '13:00': 'h7',
  '13:55': 'h8',
  '14:50': 'h9',
  '15:45': 'h10',
  '16:40': 'h11',
  '17:35': 'h12',
  '18:30': 'h13',
  '19:25': 'h14',
  '20:20': 'h15',
  '21:15': 'h16',
  '22:10': true
}

export const hoursArray = [
  '07:00',
  '07:55',
  '08:50',
  '09:45',
  '10:40',
  '11:35',
  '13:00',
  '13:55',
  '14:50',
  '15:45',
  '16:40',
  '17:35',
  '18:30',
  '19:25',
  '20:20',
  '21:15'
]

export function getCoordinate(hour, day, numOfClasses) {
  let coordinates = []
  if (day === 'CMB') {
    coordinates.push('d0h0')
  } else {
    for (let i = 0; i < numOfClasses; i++) {
      coordinates.push(`${days[day]}${hours[hour]}`)
      hour = hoursArray[hoursArray.indexOf(hour) + 1]
    }
  }

  return coordinates
}

export function getSchedule(group) {
  let items = []
  const keys = Object.keys(hours)

  group.schedules.forEach((item) => {
    const day = days[item.day]
    const hoursItems = item.hour.split(' ').filter((i) => hours[i.trim()])

    if (hoursItems.length) {
      const start = keys.indexOf(hoursItems[0])
      const end = keys.indexOf(hoursItems[hoursItems.length - 1])

      for (let i = start; i < end; i++) {
        const h = hours[keys[i].trim()]
        items.push(`${day}${h}`)
      }
    }
  })

  return items
}
