<template>
  <div>
    <Spacing :bottom="3">
      <Toolbar
        ref="toolbar"
        @change-template="template = $event"
        @change-discipline-type="selectDisciplineType"
        @filter-disciplines="filter = $event.value"
        @click-discipline="discipline = $event"
      />
    </Spacing>
    <LoadingList v-if="$wait.is(`component/getAll`)" />
    <component
      v-else
      :id="pageStyle"
      :is="pageStyle"
      :horizontal="template === 'list'"
      :filter="filter"
      :type="type"
      @click-discipline="discipline = $event"
    />
    <DisciplineModal
      v-if="discipline"
      :item="discipline"
      @close="discipline = null"
    />
  </div>
</template>
<script>
import RequiredsContainer from '@/components/disciplines/RequiredsContainer'
import OptionalsContainer from '@/components/disciplines/OptionalsContainer'
import Toolbar from '@/components/disciplines/Toolbar/Toolbar'
import DisciplineModal from '@/components/disciplines/DisciplineModal/DisciplineModal'
import LoadingList from '../../components/disciplines/LoadingList/LoadingList.vue'

export default {
  title: 'Disciplinas - MeForma!',
  name: 'Disciplines',
  route: '/disciplinas',
  layout: 'painel',
  middleware: ['auth'],
  components: {
    Toolbar,
    RequiredsContainer,
    OptionalsContainer,
    DisciplineModal,
    LoadingList
  },
  beforeMount() {
    this.$store.component.getComponents()
  },
  data() {
    return {
      currentDisciplineType: null,
      currentPageStyle: 'RequiredsContainer',
      template: 'grid',
      discipline: null,
      filter: null
    }
  },
  computed: {
    pageStyle() {
      if (this.$route && this.$route.query && this.$route.query.tipo) {
        const type = this.$refs.toolbar?.dropdown.find(
          (item) => item.name === this.$route.query.tipo
        )
        if (type && type.value) {
          return type.value
        }
      }

      return this.currentPageStyle
    },
    type() {
      if (this.$route && this.$route.query && this.$route.query.tipo) {
        const type = this.$refs.toolbar?.dropdown.find(
          (item) => item.name === this.$route.query.tipo
        )
        return type
      }
      return this.$refs.toolbar?.dropdown[0]
    }
  },
  created() {
    this.template = localStorage.getItem('disciplines-template')
  },
  methods: {
    selectDisciplineType(e) {
      if (e.value) {
        this.currentDisciplineType = e
        this.$router.replace({
          ...this.$route,
          query: {
            tipo: e.name
          }
        })
        this.currentPageStyle = e.value
      }
    }
  },
  head() {
    return {
      title: 'Disciplinas - MeForma!'
    }
  }
}
</script>
