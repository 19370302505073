<template>
  <div class="c-schedule">
    {{ item.day }}: <b>{{ item.hour }}</b> com <b>{{ item.professor.name }}</b>
  </div>
</template>
<script>
export default {
  name: 'DisciplineSchedule',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="stylus" scoped>
.c-schedule
    font-size 11px
    margin 4px 0
</style>
