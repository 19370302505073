import { auth } from '@/store/auth'
export default async ({ redirect, next }) => {
  let user = null
  let token = null

  await auth.getUser().then((result) => {
    user = (result.data && result.data.data) || null
    token = localStorage.token ? localStorage.token : null
  })

  if (user && token && user.email_verified_at && user.curriculum) {
    redirect('/inicio')
  } else {
    next()
  }
}
