<template>
  <div class="c-hashtag">
    <span class="c-hashtag__hash" :style="{ color: hashColor }"> # </span>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Hashtag',
  props: {
    hashColor: {
      type: String,
      default: 'inherit'
    }
  }
}
</script>
<style src="./style.styl" lang="stylus" scoped />
