<template>
  <div
    class="c-discipline-resume"
    v-if="relateds && relateds.length"
    :class="{
      'v--dark__card': $store.settings.darkMode
    }"
  >
    <h2 class="c-discipline-resume__title">Disciplinas Relacionadas</h2>
    <DisciplinesContainer horizontal>
      <Discipline
        v-for="discipline in relateds"
        :key="discipline.id"
        :item="discipline"
        @click="$emit('click-discipline', $event)"
      />
    </DisciplinesContainer>
    <!-- :loading="loading" -->
    <!-- :horizontal="horizontal" -->
  </div>
</template>
<script>
import Discipline from '@/components/disciplines/Discipline'
import DisciplinesContainer from '@/components/disciplines/DisciplinesContainer/DisciplinesContainer.vue'
export default {
  name: 'DisciplineRelateds',
  props: {
    relateds: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Discipline,
    DisciplinesContainer
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
