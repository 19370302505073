import { reactive } from 'vue'
export default class Planning {
  constructor(ctx) {
    this.ctx = ctx
    this.state = reactive({
      types: [
        {
          key: 'required',
          type: 'OB',
          name: 'Disciplinas Obrigatórias',
          value: 'RequiredsContainer'
        },
        {
          key: 'optional',
          type: 'OP',
          name: 'Disciplinas Optativas',
          value: 'OptionalsContainer'
        },
        {
          key: 'scientific_optional',
          type: 'OY',
          name: 'Disciplinas Op. Científicas',
          value: 'OptionalsContainer'
        },
        {
          key: 'humanistic_optional',
          type: 'OH',
          name: 'Disciplinas Op. Humanísticas',
          value: 'OptionalsContainer'
        },
        {
          key: 'artistic_optional',
          type: 'OZ',
          name: 'Disciplinas Op. Artísticas',
          value: 'OptionalsContainer'
        },
        {
          key: 'large_area_optional',
          type: 'OG',
          name: 'Disciplinas Op. Grande Área',
          value: 'OptionalsContainer'
        },
        {
          key: 'concentration_area_optional',
          type: 'OC',
          name: 'Op. Área de Concentração',
          value: 'OptionalsContainer'
        },
        {
          key: 'free',
          type: 'LV',
          name: 'Disciplinas livres',
          value: 'OptionalsContainer'
        }
      ],
      userPlanning: null,
      truthTable: null,
      semesters: null,
      optional: null,
      required: null,
      guide: null
    })
  }

  reset() {
    this.state.guide = null
    this.state.truthTable = null
    this.state.semesters = null
    this.state.types.forEach((item) => {
      this.state[item.key] = null
    })
  }

  typesWithContent(filter = null) {
    return (
      (this.state &&
        this.state.types &&
        this.state.types.filter(
          (item) =>
            this.state[item.key] && this.filtereds(item.key, filter).length
        )) ||
      []
    )
  }

  setComponentsMap(truthTable) {
    this.state.truthTable = truthTable
  }

  isOpened(discipline) {
    const prerequistes = discipline.prerequisites?.filter((item) => !item.done)
    return !prerequistes?.length
  }

  filtereds(type = 'required', filter = null) {
    if (this.state && this.state[type]) {
      if (filter) {
        let disciplines = this.state[type].filter((discipline) => {
          if (filter === 'opened') {
            return this.isOpened(discipline) && !discipline.done
          } else if (filter === 'done') {
            return discipline.done
          } else if (filter === 'pendent') {
            return !discipline.done
          } else if (filter === 'independent') {
            return (
              !discipline.prerequisites.length && !discipline.dependents.length
            )
          } else {
            return discipline
          }
        })
        return disciplines
      }
      return this.state && this.state[type]
    }
    return this.state && this.state['required']
  }

  semesters(filter = null) {
    if (filter) {
      const output = {}

      if (this.state && this.state.semesters) {
        for (let key in this.state.semesters) {
          let disciplines = this.state.semesters[key].filter((discipline) => {
            if (filter === 'done') {
              return discipline.done
            } else if (filter === 'pendent') {
              return !discipline.done
            } else if (filter === 'independent') {
              return (
                !discipline.prerequisites.length &&
                !discipline.dependents.length
              )
            }
          })
          if (disciplines.length) {
            output[key] = disciplines
          }
        }
      }
      return output
    }
    return this.state && this.state.semesters
  }

  requiredsToDo() {
    return (
      this.state &&
      this.state.required &&
      this.state.required.filter((i) => !i.done)
    )
  }

  optionalsByDepartment(key, filter) {
    const groupedOptionals = {}

    this.state[key].forEach((component) => {
      const department = component.department || 'Sem categoria'
      if (!groupedOptionals[department]) {
        groupedOptionals[department] = []
        groupedOptionals[department].push(component)
      } else {
        groupedOptionals[department].push(component)
      }
    })

    if (filter) {
      const output = {}

      for (let i in groupedOptionals) {
        let disciplines = groupedOptionals[i].filter((discipline) => {
          if (filter === 'done') {
            return discipline.done
          } else if (filter === 'pendent') {
            return !discipline.done
          } else if (filter === 'independent') {
            return (
              !discipline.prerequisites.length && !discipline.dependents.length
            )
          }
        })
        if (disciplines.length) {
          output[i] = disciplines
        }
      }

      return output
    }

    return groupedOptionals
  }

  getComponentsMap() {
    this.ctx.config.globalProperties.$wait.start(`component/map`)
    return this.ctx.$axios
      .get('user/components/map')
      .then((result) => {
        this.setComponentsMap(result.data && result.data.data)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`component/map`)
      })
  }

  setComponents(components) {
    let semesters = {}

    this.state.types.forEach((item) => {
      this.state[item.key] = components.filter((i) => i.type === item.type)
    })

    this.state.required.forEach((component) => {
      if (!semesters[component.semester]) {
        semesters[component.semester] = []
        semesters[component.semester].push(component)
      } else {
        semesters[component.semester].push(component)
      }
    })

    this.state.semesters = semesters
  }

  getComponents() {
    this.ctx.config.globalProperties.$wait.start(`planning/getComponents`)

    return this.ctx.$axios
      .get('user/planning/components')
      .then((result) => {
        this.setComponents(result.data && result.data.items)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`planning/getComponents`)
      })
  }

  setEnrollmentGuide(guide) {
    this.state.guide = guide
  }

  getEnrollmentGuide() {
    this.ctx.config.globalProperties.$wait.start(`planning/getGuide`)

    this.ctx.$axios
      .get('/planning/enrollment-guide')
      .then((result) => {
        this.setEnrollmentGuide(result.data.data)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`planning/getGuide`)
      })
  }

  setUserPlanning(data) {
    this.state.userPlanning = data
  }

  search({ q }) {
    this.ctx.config.globalProperties.$wait.start(`planning/search`)
    return this.ctx.$axios
      .get('/planning/search', { params: { q } })
      .then((result) => {
        return result?.data?.items
        // this.setUserPlanning(result.data && result.data.items)
        // return result.data && result.data.items
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`planning/search`)
      })
  }

  getUserPlanning() {
    this.ctx.config.globalProperties.$wait.start(`planning/get`)
    return this.ctx.$axios
      .get('/user/planning')
      .then((result) => {
        this.setUserPlanning(result.data && result.data.items)
        return result.data && result.data.items
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`planning/get`)
      })
  }

  saveUserPlanning(payload) {
    this.ctx.config.globalProperties.$wait.start(`planning/save`)
    return this.ctx.$axios.post('/planning', payload).finally(() => {
      this.ctx.config.globalProperties.$wait.end(`planning/save`)
    })
  }
}
