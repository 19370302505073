<template>
  <div class="c-loading-list">
    <div v-for="j in 2" :key="`i-${j}`">
      <div class="c-loading-list__disciplines">
        <div class="c-loading-list__item">
          <div class="c-loading-list__header">
            <div>
              <div class="c-loading-list__item-title" />
              <div class="c-loading-list__item-subtitle" />
              <div class="c-loading-list__item-hashtag" />
            </div>
            <div class="c-loading-list__item-button" />
          </div>
          <div>
            <div class="c-loading-list__item-paragraph" />
            <div class="c-loading-list__item-paragraph" />
          </div>
          <div class="c-loading-list__item-actions">
            <div class="c-loading-list__item-button" />
            <div class="c-loading-list__item-button" />
            <div class="c-loading-list__item-button" />
            <div class="c-loading-list__item-button" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loading'
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
