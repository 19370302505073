import { reactive } from 'vue'
export default class Settings {
  constructor(ctx) {
    this.ctx = ctx
    this.state = reactive({
      darkMode: localStorage.getItem('darkMode') === '1' || false
    })
  }

  setDarkMode() {
    localStorage.setItem('darkMode', 1)
    this.state.darkMode = true
  }

  unsetDarkMode() {
    localStorage.setItem('darkMode', 0)
    this.state.darkMode = false
  }
}
