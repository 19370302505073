<template>
  <div class="c-home-switcher" :class="{ 'v--dark': $store.settings.darkMode }">
    <Button
      v-for="item in items"
      :key="item.name"
      class="c-home-switcher__item v--bg-white"
      :class="{ 'c-home-switcher__item--active': selected === item.name }"
      @click="$emit('select', item)"
    >
      {{ item.name }}
    </Button>
  </div>
</template>
<script>
export default {
  name: 'KanbanSwitcher',
  props: {
    selected: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
