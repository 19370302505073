import { reactive } from 'vue'

export default class Semester {
  constructor(ctx) {
    this.ctx = ctx
    this.state = reactive({
      semester: null,
      codes: null,
      semestersMap: null
    })
  }

  reset() {
    this.state.semester = null
    this.state.codes = null
    this.state.semestersMap = null
  }

  resetSemester() {
    this.state.semester = null
  }

  mapTable() {
    return this.state.semestersMap?.filter((i) => i.code && i.map?.length)
  }

  getSemester(payload) {
    this.ctx.config.globalProperties.$wait.start(`semester/get`)
    const { code } = payload
    return this.ctx.config.globalProperties.$axios
      .get(`semesters/${code}`)
      .then((result) => {
        this.setSemester(result.data && result.data.data)
        return result.data && result.data.data
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`semester/get`)
      })
  }

  removeSemester(payload) {
    const { code } = payload
    return this.ctx.config.globalProperties.$axios
      .delete(`semesters/${code}`)
      .finally(() => {})
  }

  getSemestersMap() {
    return this.ctx.config.globalProperties.$axios
      .get('user/semesters/map')
      .then((result) => {
        this.setSemestersMap(result.data && result.data.data)
      })
  }

  setSemestersMap(data) {
    this.state.semestersMap = data
  }

  getCodes() {
    this.ctx.config.globalProperties.$wait.start(`semester/codes`)
    return this.ctx.config.globalProperties.$axios
      .get(`semesters/codes`)
      .then((result) => {
        this.setCodes(result.data && result.data.items)
        return Promise.resolve(result)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`semester/codes`)
      })
  }

  setCodes(data) {
    this.state.codes = data
  }

  setSemester(data) {
    this.state.semester = data
  }

  updateDisciplineAbsence({ id, absence }) {
    return this.ctx.config.globalProperties.$axios.put(
      `semesters/disciplines/${id}/absence`,
      { absence }
    )
  }

  create({ code, disciplines }) {
    this.ctx.config.globalProperties.$wait.start(`semester/create`)

    return this.ctx.config.globalProperties.$axios
      .post('semesters', {
        code,
        disciplines
      })
      .then(() => {
        return this.getCodes()
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`semester/create`)
      })
  }

  update({ code, disciplines }) {
    this.ctx.config.globalProperties.$wait.start(`semester/update`)

    return this.ctx.config.globalProperties.$axios
      .put('semesters/update', {
        code,
        disciplines
      })
      .then(() => {
        return this.getCodes()
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`semester/update`)
      })
  }
}
