<template>
  <div class="c-loading-list">
    <div class="c-loading-list__disciplines">
      <div class="c-loading-list__item">
        <div class="c-loading-list__header">
          <div>
            <div class="c-loading-list__item-title" />
            <div class="c-loading-list__item-subtitle" />
            <div class="c-loading-list__item-hashtag" />
          </div>
          <div class="c-loading-list__item-button" />
        </div>
        <div>
          <div class="c-loading-list__item-paragraph" />
          <div class="c-loading-list__item-paragraph" />
        </div>
        <div class="c-loading-list__item-actions">
          <div class="c-loading-list__item-button" />
          <div class="c-loading-list__item-button" />
          <div class="c-loading-list__item-button" />
          <div class="c-loading-list__item-button" />
        </div>
      </div>
    </div>
    <div class="c-loading-list__message">
      <p class="c-loading-list__message-title">
        Essa disciplina ainda não tem arquivos
      </p>
      <Spacing top="1">
        <Button
          class="v--bg-star-command-blue"
          @click="$emit('button-click', $event)"
          >Seja a primeira pessoa a compartilhar conteúdo</Button
        >
      </Spacing>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Empty'
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
