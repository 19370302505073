<template>
  <div class="c-post" :class="{ 'v--dark__card': $store.settings.darkMode }">
    <div class="c-post__header">
      <div>
        <router-link
          class="c-post__title"
          :to="{
            path: `/disciplinas/${$route.params.university}/${
              $route.params.discipline
            }/${item.id}/${slug(item.name)}`
          }"
        >
          {{ item.name }}
        </router-link>
        <span class="c-post__subtitle">
          <Icon name="clock" size="12" />
          <Spacing left="1">
            {{ item.created_at }}
          </Spacing>
        </span>
        <Spacing top="1" class="v--flex v--flex-align-middle">
          <Tag :style="background">
            {{ item.category }}
          </Tag>
          <Spacing left="1">
            <Hashtag v-if="item.semester">
              {{ item.semester }}
            </Hashtag>
          </Spacing>
        </Spacing>
      </div>
      <div>
        <Button
          class="v--bg-star-command-blue"
          @click="download"
          style="width: 94px"
          :loading="$wait.is(`post/download/${item.id}`)"
        >
          <Icon name="download-cloud" size="14" />
          <Spacing left="1">Baixar</Spacing>
        </Button>
      </div>
    </div>
    <Spacing class="c-post__body">
      <pre v-html="description" />
    </Spacing>
    <div class="c-post__actions">
      <Button
        class="c-post__actions-button"
        :class="{ 'c-post__actions-button--like': liked }"
        @click="react('LIKE')"
      >
        <Icon name="thumbs-up" size="16" />
        <Spacing left="1">{{ likes.length }}</Spacing>
      </Button>
      <Button
        class="c-post__actions-button"
        :class="{ 'c-post__actions-button--deslike': desliked }"
        @click="react('DESLIKE')"
      >
        <Icon name="thumbs-down" size="16" />
        <Spacing left="1">{{ deslikes.length }}</Spacing>
      </Button>
      <Button class="c-post__actions-button" @click="showComments">
        <Icon name="message-square" size="16" />
        <Spacing left="1">{{ item.count.comments }}</Spacing>
      </Button>
      <Sharer
        class="c-post__actions-button"
        :url="`${$route.href}/${item.id}/${slug(item.name)}`"
        :url-title="`${item.discipline_code} - ${item.name}`"
      />
    </div>
    <template v-if="canShowComments">
      <div class="c-post__comments">
        <Comment
          v-for="comment in this.$store.post.comments"
          :key="comment.id"
          :item="comment"
          :file-id="item.id"
        />
      </div>
      <Field
        :ref="`comment-file-${item.id}`"
        :name="`comment-file-${item.id}`"
        placeholder="Escreva um comentário sobre o arquivo"
        :value="comment"
        v-model="comment"
        @enter="doComment()"
      />
    </template>
  </div>
</template>
<script>
import Comment from './Comment.vue'
export default {
  components: { Comment },
  name: 'Post',
  emits: ['showComments', 'show-comments'],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    canShowComments: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      comment: '',
      like: null,
      deslike: null
    }
  },
  beforeMount() {
    if (this.canShowComments) {
      this.showComments()
    }
  },
  computed: {
    background() {
      const options = {
        Prova: { 'background-color': '#e40066', color: '#FFF' },
        Trabalho: { 'background-color': '#345995', color: '#FFF' },
        Exercício: { 'background-color': '#03cea4', color: '#FFF' },
        'Resumo/Anotação': { 'background-color': '#fb4d3d', color: '#FFF' },
        'Outra coisa': { 'background-color': '#333', color: '#FFF' }
      }
      return options[this.item.category]
    },
    description() {
      return this.item && this.item.description
    },
    likes() {
      if (this.like === null) {
        return this.item.reactions.filter((i) => i.type === 'LIKE')
      }

      const items = this.item.reactions.filter(
        (i) =>
          i.type === 'LIKE' &&
          (i.author && i.author.id) !== this.$store.auth.user.id
      )

      if (this.like === 0 || this.deslike === 1) {
        return items
      }

      return [
        ...items,
        { type: 'LIKE', author: { id: this.$store.auth.user.id } }
      ]
    },
    deslikes() {
      if (this.deslike === null) {
        return this.item.reactions.filter((i) => i.type === 'DESLIKE')
      }
      const items = this.item.reactions.filter(
        (i) =>
          i.type === 'DESLIKE' &&
          (i.author && i.author.id) !== this.$store.auth.user.id
      )

      if (this.deslike === 0 || this.like === 1) {
        return items
      }
      return [
        ...items,
        { type: 'DESLIKE', author: { id: this.$store.auth.user.id } }
      ]
    },
    liked() {
      if (this.like === null) {
        return this.likes.find(
          (i) => (i.author && i.author.id) === this.$store.auth.user.id
        )
          ? 1
          : 0
      }
      return this.like
    },
    desliked() {
      if (this.deslike === null) {
        return this.deslikes.find(
          (i) => (i.author && i.author.id) === this.$store.auth.user.id
        )
          ? 1
          : 0
      }
      return this.deslike
    }
  },
  methods: {
    slug(str) {
      return encodeURIComponent(`${str} vamsover`)
    },
    showComments() {
      this.$store.post.resetComments()
      this.$store.post.getComments({ id: this.item.id }).then(() => {
        this.$emit('show-comments', this.item.id)
        this.$nextTick(() => {
          const to = document.getElementById(
            `field-comment-file-${this.item.id}`
          )
          to.scrollIntoView()
          to.focus()
        })
      })
    },
    doComment() {
      if (this.comment) {
        const form = {
          comment: this.comment,
          file_id: this.item.id
        }

        this.$store.post.createComment(form).then(() => {
          return this.$store.post.getComments({ id: this.item.id }).then(() => {
            this.comment = ''
            this.$nextTick(() => {
              this.$refs[`comment-file-${this.item.id}`].$refs.input.value = ''
            })
          })
        })
      }
    },
    react(type) {
      const lcType = type.toLowerCase()
      const reaction = this[`${lcType}d`]

      const opposites = {
        like: 'deslike',
        deslike: 'like'
      }

      this[lcType] = reaction ? 0 : 1

      this[opposites[lcType]] = 0

      if (this[lcType] === 0) {
        this.$store.post.unreact({ type, file_id: this.item.id })
      } else {
        this.$store.post.react({ type, file_id: this.item.id })
      }
    },
    download() {
      this.$store.post.download(this.item)
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
