<template>
  <div class="c-loading-list">
    <div class="c-loading-list__message">
      <div>
        <p class="c-loading-list__message-title">
          Você ainda não cadastrou semestres
        </p>
        <Button class="v--beal-blue" @click="$emit('button-click', $event)">
          Crie seu primeiro semestre
        </Button>
      </div>
    </div>
    <div v-for="j in 4" :key="`i-${j}`">
      <div class="c-loading-list__disciplines">
        <div class="c-loading-list__item">
          <div class="c-loading-list__item-title" />
          <div class="c-loading-list__item-subtitle" />
          <div class="c-loading-list__item-hashtag" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmptyList',
  emits: ['button-click']
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
