<template>
  <div class="p-post">
    <Spacing bottom="2" class="v--flex">
      <router-link
        class="v--flex-align-right"
        :to="{
          path: `/disciplinas/${$route.params.university}/${$route.params.discipline}`
        }"
      >
        <span> Ir para página da disciplina </span>
      </router-link>
    </Spacing>
    <Post
      v-if="!$wait.is(`post/get`)"
      can-show-comments
      :item="$store.post.state.post"
    />
  </div>
</template>
<script>
import Post from '../../../../../components/posts/Post/Post.vue'
export default {
  components: { Post },
  name: 'File',
  route: '/disciplinas/:university/:discipline/:file/:url',
  layout: 'painel',
  beforeMount() {
    this.$store.post.getPost({ id: this.$route.params.file })
  }
}
</script>
<style lang="stylus" scoped>
.p-post
  max-width 900px
  margin 0 auto
</style>
