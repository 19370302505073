<template>
  <div class="p-siac">
    <div v-if="step === 2">
      <Spacing bottom="4" class="v--align-center">
        <h1>SIAC UFBA</h1>
        <Spacing :top="1">
          <p>
            Nem sempre vamos conseguir recuperar seus dados do SIAC na primeira
            tentativa. Às vezes você vai precisar tentar mais de uma vez.
          </p>
        </Spacing>
      </Spacing>
      <ul class="p-siac__works">
        <li class="p-siac__works-item">
          <div class="p-siac__works-item-header">
            <Icon v-if="$wait.is('curriculum')" class="v--spin" name="loader" />
            <Icon v-else-if="error.curriculum" name="x-circle" />
            <Icon v-else name="check-circle" />
            <Spacing left="1">Dados do currículo</Spacing>
          </div>
          <ul v-if="alert.curriculum">
            <li>{{ alert.curriculum }}</li>
          </ul>
          <ul v-if="error.curriculum">
            <li>{{ error.curriculum }}</li>
          </ul>
        </li>
        <li class="p-siac__works-item">
          <div class="p-siac__works-item-header">
            <Icon v-if="$wait.is('history')" class="v--spin" name="loader" />
            <Icon v-else-if="error.history" name="x-circle" />
            <Icon v-else name="check-circle" />
            <Spacing left="1">Lista de disciplinas cursadas</Spacing>
          </div>
          <ul v-if="alert.history">
            <li>{{ alert.history }}</li>
          </ul>
          <ul v-if="error.history">
            <li>{{ error.history }}</li>
          </ul>
        </li>
        <li class="p-siac__works-item">
          <div class="p-siac__works-item-header">
            <Icon v-if="$wait.is('semester')" class="v--spin" name="loader" />
            <Icon v-else-if="error.semester" name="x-circle" />
            <Icon v-else-if="alert.semester" name="alert-triangle" />
            <Icon v-else name="check-circle" />
            <Spacing left="1">Lista de disciplinas do semestre atual</Spacing>
          </div>
          <ul v-if="alert.semester">
            <li>{{ alert.semester }}</li>
          </ul>
          <ul v-if="error.semester">
            <li>{{ error.semester }}</li>
          </ul>
        </li>
      </ul>
    </div>
    <form v-else @submit.prevent="submit">
      <Spacing bottom="4" class="v--align-center">
        <h1>SIAC UFBA</h1>
        <Spacing :top="1">
          <p>
            Nem sempre vamos conseguir recuperar seus dados do SIAC na primeira
            tentativa. Às vezes você vai precisar tentar mais de uma vez.
          </p>
        </Spacing>
      </Spacing>
      <Field
        label="CPF"
        name="cpf"
        type="text"
        v-model="cpf"
        :disabled="step === 2"
        required
      />
      <Field
        label="Senha"
        name="password"
        type="password"
        v-model="password"
        visibility-switch
        :disabled="step === 2"
        required
      />
      <Button sprawled class="v--bg-amaranth" :loading="step === 2">
        Recuperar meus dados
      </Button>
      <Spacing :vertical="3">
        <Line />
      </Spacing>
      <p>
        Nós não guardamos suas credenciais. Por isso, sempre que houver uma
        mudança no seu SIAC, você precisará importar seus dados novamente.
      </p>
    </form>
    <Spacing top="2" v-if="step === 2" class="v--flex v--flex-align-right">
      <Button :disabled="!!$wait.any()" @click="back">
        Tentar novamente
      </Button>
      <Spacing left="1">
        <Button
          :disabled="!!$wait.any()"
          class="v--bg-orange-yellow-crayola"
          @click="goToOrigin"
        >
          Concluir
        </Button>
      </Spacing>
    </Spacing>
  </div>
</template>
<script>
export default {
  name: 'Siac',
  route: '/siac',
  layout: 'painel',
  middleware: ['auth'],
  data() {
    return {
      cpf: '',
      password: '',
      step: 1,
      error: {
        history: false,
        curriculum: false,
        semester: false
      },
      alert: {
        history: false,
        curriculum: false,
        semester: false
      }
    }
  },
  methods: {
    back() {
      this.step = 1

      this.error.history = false
      this.error.curriculum = false
      this.error.semester = false

      this.alert.history = false
      this.alert.curriculum = false
      this.alert.semester = false
    },
    goToOrigin() {
      const path = this.$route.query?.origem || '/inicio'
      this.$router.push({ path })
    },
    submit() {
      this.step = 2
      this.$wait.start('curriculum')
      this.$wait.start('history')
      this.$wait.start('semester')

      this.$store.siac
        .curriculum({ cpf: this.cpf, password: this.password })
        .then(() => {
          return this.$store.auth.getUser().then(() => {
            this.$store.siac
              .history({ cpf: this.cpf, password: this.password })
              .catch((e) => {
                if (e.response && e.response.data && e.response.data.message) {
                  this.error.history = e.response.data.message
                }
              })
              .finally(() => this.$wait.end('history'))

            this.$store.siac
              .semester({ cpf: this.cpf, password: this.password })
              .then((result) => {
                if (result && result.data && result.data.message) {
                  this.alert.semester = result.data.message
                }
              })
              .catch((e) => {
                if (e.response && e.response.data && e.response.data.message) {
                  this.error.semester = e.response.data.message
                }
              })
              .finally(() => this.$wait.end('semester'))

            this.$store.semester.getSemestersMap()
          })
        })
        .catch((e) => {
          this.$wait.end('history')
          this.$wait.end('semester')

          this.error.history = 'Não conseguimos recuperar o currículo'
          this.error.semester = 'Não conseguimos recuperar o currículo'

          if (e.response && e.response.data && e.response.data.message) {
            this.error.curriculum = e.response.data.message
          }
        })
        .finally(() => {
          this.$wait.end('curriculum')
        })
    }
  }
}
</script>
<style lang="stylus" scoped>
.p-siac
  max-width 500px
  margin 0 auto
  &__works
    list-style none
    padding 0
    &-item
      padding 8px 0
      &-header
        display flex
        align-items center
</style>
