<template>
  <div v-if="!$wait.any()">
    <Tag class="v--beal-blue">Obrigatórias</Tag>
    <Spacing v-if="requiredsToDo && requiredsToDo.length" top="1">
      <p>Nós fizemos o seguinte:</p>
      <ul>
        <li>
          Analisamos as disciplinas do seu curso e verificamos que o ideal é
          cursar <b>{{ disciplinesPerSemester }} disciplinas por semestre</b>.
        </li>
        <li>Pegamos as disciplinas obrigatórias que você ainda não cursou;</li>
        <li>Distribuímos por semestre, respeitando os pré-requisitos.</li>
        <li>
          Concluímos que
          <b
            >você precisa de no mínimo
            {{ minSemestersToCompleteRequireds }} semestre(s) para concluir as
            {{ requiredsToDo.length }} obrigatórias que faltam</b
          >.
        </li>
      </ul>
    </Spacing>
    <Spacing v-else top="1" class="v--flex v--flex-align-middle">
      Você concluiu todas as obrigatórias. Sugerimos que comemore!
      <img width="25" src="@/assets/confetti.png" />
      <img width="25" src="@/assets/confetti.png" />
    </Spacing>
    <template v-for="hour in hours">
      <Spacing
        v-if="$store.auth.progress && $store.auth.progress[hour.key].total"
        :key="hour.key"
        top="3"
      >
        <Tag class="v--beal-blue">{{ hour.name }}</Tag>
        <Spacing v-if="remaining($store.auth.progress[hour.key])" top="1">
          <p>
            Você ainda precisa cumprir
            <b>{{ remaining($store.auth.progress[hour.key]) }} horas</b>, que
            normalmente podem ser completadas {{ hour.via }}.
          </p>
          <template v-if="hour.via.includes('disciplina')">
            <p>Caso você opte por cursar disciplinas, você pode cursar:</p>
            <ul>
              <li
                v-for="(item, index) in combinations(
                  remaining($store.auth.progress[hour.key])
                )"
                :key="`${hour.key}-${index}`"
              >
                {{ item }}
              </li>
            </ul>
          </template>
        </Spacing>
        <Spacing v-else top="1" class="v--flex v--flex-align-middle">
          Você concluiu todas as
          {{ $store.auth.progress[hour.key].total }} horas. Sugerimos que
          comemore!
          <img width="25" src="@/assets/confetti.png" />
          <img width="25" src="@/assets/confetti.png" />
        </Spacing>
      </Spacing>
    </template>
  </div>
  <Loading v-else />
</template>
<script>
import calc from '@/helpers/calc'
import Loading from './Loading/Loading.vue'

export default {
  components: { Loading },
  name: 'SuggestionsAndPredictions',
  beforeMount() {
    this.$store.auth.getProgress()
    this.$store.component.getComponents()
    this.$store.component.getComponentsMap()
  },
  computed: {
    hours() {
      return [
        {
          name: 'Optativas',
          key: 'optional',
          theme: 'horas',
          via: 'via disciplina ou via aproveitamento de carga horária'
        },
        {
          name: 'Op. Científicas',
          key: 'scientific_optional',
          theme: 'horas',
          via: 'via disciplina'
        },
        {
          name: 'Op. Grande Área',
          key: 'large_area_optional',
          theme: 'horas',
          via: 'via disciplina'
        },
        {
          name: 'Op. Humanísticas',
          key: 'humanistic_optional',
          theme: 'horas',
          via: 'via disciplina'
        },
        {
          name: 'Op. Artísticas',
          key: 'artistic_optional',
          theme: 'horas',
          via: 'via disciplina'
        },
        {
          name: 'Op. Area de Concentração',
          key: 'concentration_area_optional',
          theme: 'horas',
          via: 'via disciplina'
        },
        {
          name: 'Complementares',
          key: 'supplementary',
          theme: 'horas',
          via: 'via aproveitamento de carga horária'
        },
        {
          name: 'Livres',
          key: 'free',
          theme: 'horas',
          via: 'via disciplina ou via aproveitamento de carga horária'
        }
      ]
    },
    requiredsToDo() {
      return this.$store.component.requiredsToDo()
    },
    disciplinesPerSemester() {
      const semestersWeights = this.$store.component.truthTable.map(
        (i) => i.length
      )

      return calc.arrayMode(semestersWeights)
    },
    minSemestersToCompleteRequireds() {
      const levels = this.requiredsToDo.map((component) => {
        component.level = this.depthDependents(component)
        return component
      })

      return levels.reduce((a, b) => {
        return (a = Math.max(a, b.level))
      }, 0)
    }
  },
  methods: {
    remaining(hour) {
      const remaining = hour.total - hour.done
      return Math.max(0, remaining)
    },
    depthDependents(component, i = 1) {
      component = this.requiredsToDo.find((j) => j.id === component.id)
      if (
        !component ||
        !component.dependents ||
        component.dependents.length === 0
      ) {
        return i
      }

      const pendents = component.dependents.filter(
        (d) => d.type === 'OB' && !d.done
      )

      if (pendents.length === 0) {
        return i // if don't have dependents, can be done in i semesters
      }

      let depth = i
      pendents.forEach((c) => {
        let newDepth = this.depthDependents(c, i + 1)
        depth = Math.max(depth, newDepth)
      })
      return depth
    },

    combinations(value) {
      let results = []

      function testValue(test) {
        const a1 = Math.ceil(value / test)
        if (a1 > 0) {
          const noum = a1 === 1 ? 'disciplina' : 'discipinas'
          results.push(`${a1} ${noum} de ${test} horas`)
        }
      }

      function testCombination(a, b) {
        // const a1 = value - a;
        const ac = Math.trunc(value / a)
        const aux = value - ac * a
        const bc = Math.ceil(aux / b)
        if (ac > 0 && bc > 0) {
          const noum = ac === 1 ? 'disciplina' : 'discipinas'

          results.push(`${ac} ${noum} de ${a} horas e ${bc} de ${b} horas`)
        }
      }

      testValue(51)
      testValue(68)
      testValue(102)
      testCombination(102, 68)
      testCombination(68, 51)
      testCombination(102, 51)

      return results
    }
  }
}
</script>
