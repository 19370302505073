<template>
  <div class="p-login">
    <main class="p-login__main">
      <form class="p-login__form" @submit.prevent="submit">
        <div class="v--align-center">
          <Icon name="meforma" :size="120" />
        </div>
        <Field
          label="E-mail"
          name="email"
          type="email"
          v-model="email"
          :disabled="!!$wait.is('login')"
          required
        />
        <Field
          label="Senha"
          name="password"
          type="password"
          forgot-path="/recuperar-senha"
          v-model="password"
          visibility-switch
          :disabled="!!$wait.is('login')"
          required
        />
        <Button sprawled class="v--bg-amaranth" :loading="!!$wait.is('login')"
          >Entrar</Button
        >
        <Spacing :vertical="1.5">
          <Line />
        </Spacing>
        <p>
          Ainda não tem uma conta?
          <router-link to="/cadastro">Cadastre-se</router-link>
        </p>
      </form>
    </main>
  </div>
</template>
<script>
export default {
  title: 'Login - MeForma!',
  name: 'Login',
  middleware: ['guest'],
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    submit() {
      this.$wait.start('login')
      this.$store.auth
        .login({ email: this.email, password: this.password })
        .then(() => this.$router.push('/inicio'))
        .catch(() => this.$toast.error('E-mail ou senha incorretos!'))
        .finally(() => this.$wait.end('login'))
    }
  }
}
</script>
<style lang="stylus" scoped>
.p-login {
  display: grid;
  min-height: 100vh;
  // background-image: url('../../assets/bg.png');
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding 0 5%
  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {
    margin-top: -120px;
    width: 400px;
    display: block;
    @media (max-width 768px) {
      width 100%
      max-width: 400px;
    }
  }
}
</style>
