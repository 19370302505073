<template>
  <div class="c-checkbox">
    <Icon name="check" size="14" />
  </div>
</template>
<script>
export default {
  name: 'Checkbox'
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
