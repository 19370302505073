import { reactive } from 'vue'

export default class Post {
  constructor(ctx) {
    this.ctx = ctx
    this.state = reactive({
      post: null,
      posts: null,
      comments: null
    })
  }

  reset() {
    this.state.posts = null
  }

  resetComments() {
    this.state.comments = null
  }

  getPosts(payload) {
    this.ctx.config.globalProperties.$wait.start(`post/getAll`)
    const { university, discipline } = payload

    return this.ctx.config.globalProperties.$axios
      .get(`disciplines/${university}/${discipline}/posts`)
      .then((result) => {
        this.setPosts(result.data && result.data.items)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`post/getAll`)
      })
  }

  getPost(payload) {
    this.ctx.config.globalProperties.$wait.start(`post/get`)
    const { id } = payload

    return this.ctx.config.globalProperties.$axios
      .get(`/posts/${id}`)
      .then((result) => {
        this.setPost(result.data && result.data.data)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`post/get`)
      })
  }

  setPost(data) {
    this.state.post = data
  }
  setPosts(data) {
    this.state.posts = data
  }

  setComments(data) {
    this.state.comments = data
  }

  post(payload) {
    return this.ctx.config.globalProperties.$axios
      .post('posts', payload)
      .then((result) => {
        return result.data && result.data.items
      })
  }

  createComment(payload) {
    const { file_id } = payload
    return this.ctx.config.globalProperties.$axios.post(
      `posts/${file_id}/comments`,
      payload
    )
  }

  react(payload) {
    const { file_id } = payload
    return this.ctx.config.globalProperties.$axios.post(
      `posts/${file_id}/react`,
      payload
    )
  }

  unreact(payload) {
    const { file_id } = payload
    return this.ctx.config.globalProperties.$axios.post(
      `posts/${file_id}/unreact`,
      payload
    )
  }

  download(payload) {
    const { id, name } = payload
    this.ctx.config.globalProperties.$wait.start(`post/download/${id}`)
    return this.ctx.config.globalProperties
      .$axios({
        method: 'get',
        url: `posts/${id}/download`,
        responseType: 'blob'
      })
      .then((response) => {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        const blob = response.data
        const url = window.URL.createObjectURL(blob)
        const ext = blob.type.split('/')[1]
        a.href = url
        a.download = name + '.' + ext
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`post/download/${id}`)
      })
  }

  getComments(payload) {
    const { id } = payload
    return this.ctx.config.globalProperties.$axios
      .get(`posts/${id}/comments`)
      .then((result) => {
        this.setComments(result.data && result.data.items)
      })
  }

  removeComment({ id, file }) {
    this.ctx.config.globalProperties.$wait.start(`post/comment/remove/${id}`)
    return this.ctx.$axios
      .delete(`posts/comments/${id}`)
      .then(() => {
        return this.getComments({ id: file })
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`post/comment/remove/${id}`)
      })
  }

  reportComment(payload) {
    this.ctx.config.globalProperties.$wait.start(`comments/report`)
    return this.ctx.$axios.post(`comments/report`, payload).finally(() => {
      this.ctx.config.globalProperties.$wait.end(`comments/report`)
    })
  }
}
