<template>
  <div
    class="c-post__comment"
    :class="{ 'v--dark__card': $store.settings.darkMode }"
  >
    <div class="c-post__comment-body">
      <div class="c-post__comment-header">
        <h2 class="c-post__comment-title">
          {{ item.author.name }}
        </h2>
        <span class="c-post__comment-hour">
          <Icon name="clock" size="11" />
          <Spacing :left="1 / 2">
            {{ item.created_at }}
          </Spacing>
        </span>
      </div>
      <p class="c-post__comment-comment">
        {{ item.comment }}
      </p>
    </div>
    <div class="c-post__comment-answer">
      <span class="c-post__comment-answer-content" @click="showAnswerInput">
        <Icon name="message-square" size="11" />
        <span> Responder </span>
      </span>
      <span
        class="c-post__comment-answer-content"
        @click="showReportModal(item)"
      >
        <Icon name="alert-triangle" size="11" />
        <span> Reportar </span>
      </span>
      <span
        v-if="item.author.id === $store.auth.user.id"
        class="c-post__comment-answer-content"
        @click="showDeleteModal(item)"
      >
        <Icon name="trash" size="11" />
        <span> Excluir </span>
      </span>
    </div>
    <Spacing
      v-if="item.comments.length || answer"
      left="4"
      bottom="1"
      class="c-post__comment-comments"
    >
      <div v-for="comment in item.comments" :key="comment.id">
        <div class="c-post__comment-body">
          <div class="c-post__comment-header">
            <h2 class="c-post__comment-title">
              {{ comment.author?.name }}
            </h2>
            <span class="c-post__comment-hour">
              <Icon name="clock" size="11" />
              <Spacing :left="1 / 2">
                {{ comment.created_at }}
              </Spacing>
            </span>
          </div>
          <p class="c-post__comment-comment">
            {{ comment.comment }}
          </p>
        </div>
        <div class="c-post__comment-answer">
          <span
            class="c-post__comment-answer-content"
            @click="showReportModal(comment)"
          >
            <Icon name="alert-triangle" size="11" />
            <span> Reportar </span>
          </span>
          <span
            v-if="item.author.id === $store.auth.user.id"
            class="c-post__comment-answer-content"
            @click="showDeleteModal(comment)"
          >
            <Icon name="trash" size="11" />
            <span> Excluir </span>
          </span>
        </div>
      </div>

      <Field
        v-if="answer"
        :ref="`file-comment-comment-${item.id}`"
        :name="`file-comment-comment-${item.id}`"
        placeholder="Escreva um comentário sobre o arquivo"
        :value="comment"
        :disabled="$wait.is(`comment-comment-${item.id}`)"
        v-model="comment"
        @enter="doComment"
      />
    </Spacing>
    <DeleteModal
      v-if="commentToDelete"
      :id="commentToDelete.id"
      name="comentário"
      :loading="$wait.is(`post/comment/remove/${commentToDelete.id}`)"
      @close="commentToDelete = null"
      @remove="remove"
    />
    <ReportModal
      v-if="reportComment"
      :comment="reportComment"
      @close="closeReportModal"
    />
  </div>
</template>
<script>
import ReportModal from './ReportModal.vue'
export default {
  components: { ReportModal },
  name: 'comment',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    fileId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      comment: '',
      answer: false,
      commentToDelete: null,
      reportComment: null
    }
  },
  methods: {
    showReportModal(comment) {
      this.reportComment = comment
    },
    closeReportModal() {
      this.reportComment = null
    },
    showAnswerInput() {
      this.answer = true
      this.$nextTick(() => {
        const to = document.getElementById(
          `field-file-comment-comment-${this.item.id}`
        )
        to.scrollIntoView()
        to.focus()
      })
    },
    doComment() {
      if (this.comment) {
        const form = {
          comment: this.comment,
          file_id: this.fileId,
          comment_id: this.item.id
        }

        this.$wait.start(`comment-comment-${this.item.id}`)

        this.$store.post.createComment(form).then(() => {
          this.comment = ''
          return this.$store.post.getComments({ id: this.fileId }).then(() => {
            this.$wait.end(`comment-comment-${this.item.id}`)
            this.$nextTick(() => {
              this.$refs[
                `file-comment-comment-${this.item.id}`
              ].$refs.input.value = ''
            })
          })
        })
      }
    },
    showDeleteModal(item) {
      this.commentToDelete = item
    },
    remove() {
      this.$store.post
        .removeComment({
          ...this.commentToDelete,
          file: this.fileId
        })
        .then(() => {
          this.$toast.success('Excluído!')
        })
        .catch(() => {
          this.$toast.success('Houve um erro. Tente novamente.')
        })
        .finally(() => {
          this.commentToDelete = null
        })
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
