<template>
  <div class="c-profile-menu">
    <div class="c-profile-menu__item" v-for="item in items" :key="item.name">
      <component
        class="c-profile-menu__item-content"
        :is="element(item)"
        :to="item && item.to"
      >
        <Icon v-if="item.icon" :name="item.icon" size="14" />

        <Spacing :left="item.icon ? 1 : 0">
          <span>
            {{ item.name }}
          </span>
        </Spacing>
      </component>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProfileMenu',
  data() {
    return {
      items: [
        { name: 'Editar perfil', to: '/perfil/configuracoes' },
        { name: 'Alterar senha', to: '/perfil/alterar-senha' },
        { name: 'Conta', to: '/perfil/conta' }
        // { name: 'Dados', to: '/perfil/dados' }
      ]
    }
  },
  methods: {
    element(item) {
      return item && item.to ? 'router-link' : 'div'
    }
  }
}
</script>
<style src="./style.styl" lang="stylus" scoped />
