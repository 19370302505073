<template>
  <div
    class="c-disciplines-container"
    :class="{ 'c-disciplines-container--horizontal': horizontal }"
  >
    <div v-if="$slots.title" class="c-disciplines-container__title">
      <slot name="title" />
    </div>
    <div class="c-disciplines-container__grid">
      <slot class="c-disciplines-container__body" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'DisciplinesContainer',
  props: {
    horizontal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
