<template>
  <div
    :id="`hour-${item.id}`"
    class="c-hour"
    :class="{
      'v--dark__card': $store.settings.darkMode
    }"
  >
    <div class="c-hour__content">
      <div class="c-hour__content-info c-hour__content-header">
        <h2 class="c-hour__title">
          {{ item.title }}
          <small>
            <span>•</span>
            {{ item.hours }}hs
          </small>
        </h2>
        <pre
          v-if="item.description"
          class="c-hour__subtitle"
          v-html="item.description"
        />
        <Spacing v-if="item.status" top="1" class="c-hour__hashtag">
          <Hashtag :hash-color="background">
            {{ item.status }}
          </Hashtag>
        </Spacing>
      </div>

      <div class="c-hour__content-info">
        <Dropdown
          hide-indicator
          placement="bottom-end"
          :changeNameOnSelect="false"
          :items="options"
          tooltip="Opções"
          @change="selectOption"
        >
          <Icon name="more-horizontal" />
        </Dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import color from '@/helpers/color'
export default {
  name: 'Discipline',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['delete', 'edit'],
  computed: {
    background() {
      return color.string2Hex(this.item.status)
    },
    options() {
      return [
        { name: 'Editar', value: 'edit', icon: 'edit' },
        { name: 'Excluir', value: 'delete', icon: 'trash' }
      ]
    }
  },
  methods: {
    selectOption(item) {
      if (item.value === 'delete') {
        this.$emit('delete', this.item)
      } else if (item.value === 'edit') {
        this.$emit('edit', this.item)
      }
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
