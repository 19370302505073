<template>
  <div>
    <Navbar v-if="$store.auth?.user?.id" />
    <SiteNavbar v-else />
    <section class="p-site__section">
      <div
        class="p-site__section-content p-site__section-content--grid p-site__section-content--grid-hide-right-xs"
      >
        <div>
          <h1 class="p-site__title">
            Otimize suas escolhas, e se forme o quanto antes!
          </h1>
          <Spacing vertical="4">
            <Line />
          </Spacing>
          <p class="p-site__text">
            Planeje o semestre seguinte, contabilize suas faltas, acompanhe seu
            progresso no curso, compartilhe arquivos com seus colegas, veja
            taxas de aprovação das disciplinas do seu curso, e muito mais! Tudo
            pensado para te ajudar a concluir a graduação.
          </p>
          <Spacing vertical="4">
            <Button class="v--bg-meforma-pink p-site__ca" to="/cadastro"
              >Comece agora!</Button
            >
          </Spacing>
        </div>
        <div>
          <div class="p-site__video">
            <Icon class="p-site__video-bg" name="smartphone" size="652"> </Icon>
            <video
              id="smartphone-video"
              src="@/assets/video.mp4"
              autoplay
              loop
              muted
              pause
              alt="MeForma"
            ></video>
          </div>
        </div>
      </div>
    </section>
    <section class="p-site__section p-site__section--second">
      <div class="p-site__section-content">
        <div class="v--text-center">
          <h1 class="p-site__title" style="margin: auto">
            Te ajudamos a planejar o SEU HORÁRIO na UFBA
          </h1>
          <Spacing vertical="2">
            <p class="p-site__text">Planeje e salve para ver depois!</p>
            <p>
              As disciplinas já são automaticamente filtradas para exibir apenas
              o necessário!
            </p>
          </Spacing>
        </div>
        <img
          class="p-site__image--big"
          src="@/assets/planning.png"
          alt="Tela de planejamento de semestre"
        />
      </div>
    </section>
    <footer class="p-site__footer">
      <p>Copyright {{ year }}. MeForma. Todos os direitos reservados.</p>
      <Spacing vertical="1">
        <p>
          Um projeto criado por
          <a
            target="_blank"
            rel="nofollow"
            href="https://github.com/jprodrigues70"
          >
            JP Rodrigues
          </a>
          e que agora conta com os trabalhos de
          <a
            target="_blank"
            rel="nofollow"
            href="https://github.com/jeffersonrnb"
            >Jeff Brito</a
          >
          e
          <a target="_blank" rel="nofollow" href="https://github.com/leocostaba"
            >Léo Costa</a
          >.
        </p>
      </Spacing>
      <p>
        O conteúdo referente às disciplinas, cursos e nomes das universidades
        são obtido através de dados públicos disponíveis na WEB.
      </p>
      Alunos UFBA FAQ
    </footer>
  </div>
</template>
<script>
import Navbar from '../../components/Navbar/Navbar.vue'
import SiteNavbar from '../../components/Navbar/SiteNavbar.vue'
export default {
  name: 'Site',
  route: '/',
  components: {
    SiteNavbar,
    Navbar
  },
  computed: {
    year() {
      return new Date().getFullYear()
    }
  },
  mounted() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    if (!isMobile) {
      const video = document.getElementById('smartphone-video')

      if (video?.paused) {
        try {
          const p = video.play()
          if (p) {
            p.then(() => {
              console.log(
                'O MeForma é um sistema de acompanhamento e gerenciamento online de vida acadêmica, que proporciona uma experiência diferente e eficiente pra quem não quer ter dor de cabeça na hora de se formar.'
              )
            }).catch((err) => {
              console.dir(err)
            })
          }
        } catch (e) {
          console.log(e)
        }
      }
    } else {
      document.getElementById('smartphone-video')?.remove()
    }
  }
}
</script>
<style lang="stylus" scoped>
.p-site
    &__ca
        font-size 24px !important
        padding 24px 36px !important
    &__section
        margin 0 auto
        padding 60px 5%
        @media (max-width 768px)
            padding 30px 5%

        &-content
            margin 0 auto
            max-width 1200px
            &--grid
                display grid
                grid-template-columns 1fr 1fr
                align-items center
            &--grid-hide-right-xs
                @media (max-width 768px)
                    display grid
                    grid-template-columns 1fr
                    align-items center
            &--grid-big-image
                align-items center
                display grid
                grid-template-columns 500px 1fr
                grid-gap 24px
        &--second
            background-color #f3faff
    &__title
        font-size 40px
        font-weight 500
        max-width 500px
        @media (max-width 768px)
            font-size 32px

    &__text
        font-size 18px
        font-weight 300
    &__video
        position relative
        #smartphone-video
            position absolute
            left 187px
            width 268px
            bottom 64px
            height 533px
        @media (max-width 768px)
            display none


    &__image
        &--big
            // max-width 800px
            width 100%
    &__footer
        margin 0 auto
        padding 60px 5%
        max-width calc(1200px + 10%)
</style>
