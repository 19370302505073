<template>
  <Modal
    :title="item.code"
    :subtitle="`${item.name} • ${item.hours}hs`"
    @close="close"
  >
    <template v-slot:right-header>
      <div class="v--flex">
        <Sharer
          :url-title="`${item.code} ${item.name}`"
          :url="`/disciplinas/UFBA/${item.code}`"
        />
      </div>
    </template>
    <div class="v--flex">
      <Spacing bottom="1">
        <Hashtag
          v-if="item.department"
          :hash-color="background"
          class="v--flex-align-right"
          >{{ item.department }}</Hashtag
        >
      </Spacing>
    </div>
    <div class="c-discipline-modal">
      <div v-if="item.enrollments !== undefined">
        <canvas
          ref="canvas"
          style="height: 180px; max-width: 320px; margin: 0 auto"
        />
        <ul class="c-discipline-modal__legend">
          <li
            class="c-discipline-modal__legend-item"
            v-for="item in legend"
            :key="item.background"
          >
            <div
              class="c-discipline-modal__legend-item-square"
              :style="{ background: item.background }"
            />
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div v-else>
        Essa disciplina <b>não faz parte do seu currículo</b>, por isso, não
        conseguimos computar índices de aprovação, tampouco dependências.
      </div>
      <div class="c-discipline-modal__line" />
      <div>
        <div class="c-discipline-modal__relateds">
          <Button
            class="c-discipline-modal__relateds-button"
            :to="`/disciplinas/UFBA/${item.code}`"
          >
            <div class="v--flex">
              <Icon name="file-text" size="14" />
              <Spacing left="1"> Ir para arquivos da disciplina </Spacing>
            </div>
            <Icon class="v--flex-align-right" name="chevron-right" size="14" />
          </Button>
          <!-- <Button
            class="c-discipline-modal__relateds-button"
            :to="`/disciplinas/UFBA/${item.code}`"
          >
            <div class="v--flex">
              <Icon name="grid" size="14" />
              <Spacing left="1"> Ver turmas ofertadas </Spacing>
            </div>
            <Icon class="v--flex-align-right" name="chevron-right" size="14" />
          </Button> -->
        </div>
        <template v-if="item.enrollments">
          <div class="c-discipline-modal__relateds">
            <h2 class="c-discipline-modal__relateds-title">Pré-requisitos</h2>
            <ul
              v-if="item.prerequisites && item.prerequisites.length"
              class="c-discipline-modal__relateds-list"
            >
              <li
                v-for="prerequisite in item.prerequisites"
                :key="prerequisite.id"
                class="c-discipline-modal__relateds-list-item"
              >
                <Tag
                  :class="{
                    'v--bg-caribbean-green': prerequisite.done,
                    'v--bg-amaranth': !prerequisite.done
                  }"
                  >{{ prerequisite.code }}</Tag
                >
                <span>
                  {{ prerequisite.name }}
                </span>
              </li>
            </ul>
            <p v-else class="c-discipline-modal__relateds-list">
              Não há pré-requisito
            </p>
          </div>
          <div class="c-discipline-modal__relateds">
            <h2 class="c-discipline-modal__relateds-title">Dependentes</h2>
            <template v-if="requiredsDependents && requiredsDependents.length">
              <h3 class="c-discipline-modal__relateds-subtitle">
                Obrigatórias
              </h3>
              <ul
                v-if="requiredsDependents.length"
                class="c-discipline-modal__relateds-list"
              >
                <li
                  v-for="dependent in requiredsDependents"
                  :key="dependent.id"
                  class="c-discipline-modal__relateds-list-item"
                >
                  <Tag class="v--beal-blue">{{ dependent.code }}</Tag>
                  <span>
                    {{ dependent.name }}
                  </span>
                </li>
              </ul>
            </template>

            <template v-if="optionalsDependents && optionalsDependents.length">
              <h3 class="c-discipline-modal__relateds-subtitle">Optativas</h3>
              <ul class="c-discipline-modal__relateds-list">
                <li
                  v-for="dependent in optionalsDependents"
                  :key="dependent.id"
                  class="c-discipline-modal__relateds-list-item"
                >
                  <Tag class="v--beal-blue">{{ dependent.code }}</Tag>
                  <span>
                    {{ dependent.name }}
                  </span>
                </li>
              </ul>
            </template>
            <p v-if="!hasDependents" class="c-discipline-modal__relateds-list">
              Não há dependentes
            </p>
          </div>
        </template>
      </div>
    </div>
    <template v-slot:footer>
      <div class="v--flex v--flex-align-right">
        <Button @click="close">Cancelar</Button>
        <Spacing left="1">
          <Button
            v-if="done === true || (item.done && done === null)"
            class="v--bg-amaranth c-discipline-modal__button"
            @click="disapprove"
            :loading="$wait.is('disapproving')"
          >
            Remover aprovação
          </Button>
          <Button
            v-else
            class="v--bg-orange-yellow-crayola c-discipline-modal__button"
            @click="approve"
            :loading="$wait.is('approving')"
          >
            Marcar aprovação
          </Button>
        </Spacing>
      </div>
    </template>
  </Modal>
</template>
<script>
import { Bar } from '@/mixins/BarChart.js'
import color from '@/helpers/color'

export default {
  name: 'DisciplineModal',
  mixins: [Bar],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    background() {
      return color.string2Hex(this.item.department)
    },
    requiredsDependents() {
      return (
        this.item.dependents &&
        this.item.dependents.filter((item) => item.type === 'OB')
      )
    },
    optionalsDependents() {
      return (
        this.item.dependents &&
        this.item.dependents.filter((item) => item.type !== 'OB')
      )
    },
    hasDependents() {
      return this.item.dependents && this.item.dependents.length
    }
  },
  data() {
    const { enrollments } = this.item

    return {
      done: null,
      legend: [
        { background: '#6bd06b', name: 'Aprovações' },
        { background: '#ff5d5d', name: 'Reprovações por nota' },
        { background: '#6e8392', name: 'Trancamentos' },
        { background: '#fde050', name: 'Reprovações por falta' },
        { background: 'rgba(153, 102, 255, 0.2)', name: 'Alunos cursando' }
      ],
      data: enrollments
        ? {
            labels: ['AP', 'RR', 'TR', 'RF', 'AC'],
            datasets: [
              {
                label: `Total de matrículas na disciplina: ${
                  (enrollments && enrollments.TOTAL) || 0
                }`,
                maxBarThickness: 40,
                data: [
                  ((enrollments.AP * 100) / (enrollments.TOTAL || 1)).toFixed(
                    3
                  ),
                  ((enrollments.RR * 100) / (enrollments.TOTAL || 1)).toFixed(
                    3
                  ),
                  ((enrollments.TR * 100) / (enrollments.TOTAL || 1)).toFixed(
                    3
                  ),
                  ((enrollments.RF * 100) / (enrollments.TOTAL || 1)).toFixed(
                    3
                  ),
                  ((enrollments.AC * 100) / (enrollments.TOTAL || 1)).toFixed(3)
                ],
                backgroundColor: [
                  '#6bd06b',
                  '#ff5d5d',
                  '#6e8392',
                  '#fde050',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)'
                ]
              }
            ]
          }
        : null,
      options: {
        animation: {
          duration: 0
        },
        title: {
          display: true,
          text: 'Desempenho dos estudantes cadastrados (%)',
          fontColor: this.$store.settings.darkMode ? '#c9d1d9' : '#2c3e50'
        },
        legend: {
          labels: {
            boxWidth: 0,
            fontColor: this.$store.settings.darkMode ? '#c9d1d9' : '#2c3e50'
          }
        },
        responsive: false,
        maintainAspectRatio:
          Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
          ) > 768,
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: this.$store.settings.darkMode ? '#c9d1d9' : '#2c3e50'
              }
            }
          ],
          yAxes: [
            {
              padding: 50,
              scaleLabel: {
                padding: 20
              },
              stacked: false,
              ticks: {
                fontColor: this.$store.settings.darkMode
                  ? '#c9d1d9'
                  : '#2c3e50',
                min: 0,
                max: 100,
                stepSize: 20,
                callback: function (value) {
                  return `${value}%`
                }
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            title(data) {
              switch (data[0].xLabel) {
                case 'AC':
                  return 'Cursando'
                case 'RR':
                  return 'Reprovações'
                case 'RF':
                  return 'Reprovações por Falta'
                case 'TR':
                  return 'Trancamentos'
                case 'AP':
                  return 'Aprovações'
              }

              return 'title'
            },
            label(item) {
              return item.yLabel === 1 ? ` ${item.yLabel}%` : ` ${item.yLabel}%`
            }
          }
        }
      }
    }
  },
  mounted() {
    if (this.data) {
      this.renderChart(this.data, this.options)
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    approve() {
      this.$wait.start('approving')
      this.$axios
        .post(`/disciplines/${this.item.id}/approve`, {
          curriculum_id: this.$store.auth.user.curriculum.id
        })
        .then(() => {
          return this.$store.component.getComponents().then(() => {
            this.done = true
          })
        })
        .catch((e) => this.onError(e))
        .finally(() => this.$wait.end('approving'))
    },
    disapprove() {
      this.$wait.start('disapproving')
      this.$axios
        .post(`/disciplines/${this.item.id}/disapprove`, {
          curriculum_id: this.$store.auth.user.curriculum.id
        })
        .then(() => {
          return this.$store.component.getComponents().then(() => {
            this.done = false
          })
        })
        .catch((e) => this.onError(e))
        .finally(() => this.$wait.end('disapproving'))
    },
    onError(e) {
      let message = 'Houve um erro, por favor recarregue a página'

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message
      }

      this.$toast.error(message)
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped />
