<template>
  <div class="c-card">
    <div class="c-card__header">
      <slot name="header" />
    </div>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Card'
}
</script>
<style src="./style.styl" lang="stylus" scoped />
