<template>
  <div class="c-user-posts">
    <Post v-for="post in $store.auth.posts" :key="post" :item="post" />
  </div>
</template>
<script>
import Post from '@/components/posts/Post/Post.vue'
export default {
  components: { Post },
  name: 'UserPosts',
  beforeMount() {
    this.$store.auth.getPosts()
  }
}
</script>
<style src="./style.styl" lang="stylus" scoped />
