<template>
  <div
    class="c-navbar"
    :class="{
      'v--dark': $store.settings.darkMode
    }"
  >
    <router-link to="/inicio">
      <Icon class="c-navbar__logo" name="meforma" :size="50" />
    </router-link>
    <nav class="c-navbar__nav">
      <Button to="/login"> Entrar </Button>
      <Button class="v--bg-meforma-pink" to="/cadastro"> Cadastrar </Button>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'SiteNavbar'
}
</script>

<style src="./style.styl" lang="stylus" scoped />
