<template>
  <div class="p-planning">
    <Spacing bottom="1">
      <p v-if="$store.planning.guide">
        Referência <b>{{ $store.planning.guide?.code }}</b
        >, atualizado em
        {{ $store.planning.guide?.created_at }}
      </p>
    </Spacing>
    <Spacing bottom="4">
      <Toolbar
        @filter-disciplines="filterDisciplines"
        @change-discipline-type="changeDisciplineType"
        @show-report="showReport"
        @search="search"
      />
    </Spacing>
    <div class="p-planning__grid">
      <div class="p-planning__disciplines">
        <LoadingList
          v-if="
            $wait.is('planning/search') ||
            $wait.is(`planning/getComponents`) ||
            $wait.is(`planning/getGuide`) ||
            $wait.is('planning/get') ||
            $wait.is('planning/save')
          "
        />
        <div v-else-if="disciplines && !disciplines?.length">
          <p>Você já concluiu todas as disciplinas dessa lista!</p>
        </div>
        <template v-else>
          <Discipline
            v-for="discipline in disciplines"
            :key="discipline.id"
            :ref="`d-${discipline.id}`"
            :item="discipline"
            :disabled="!discipline.groups?.length"
            @schedule="schedule"
          />
        </template>
      </div>
      <ScheduleGrid
        ref="grid"
        :loading="
          $wait.is(`planning/getComponents`) ||
          $wait.is(`planning/getGuide`) ||
          $wait.is('planning/get') ||
          $wait.is('planning/save')
        "
      />
      <SelectionBar
        v-show="
          !(
            $wait.is(`planning/getComponents`) ||
            $wait.is(`planning/getGuide`) ||
            $wait.is('planning/get') ||
            $wait.is('planning/save')
          )
        "
        ref="selections"
        :options="[{ name: 'Salvar', value: 'save', icon: 'check' }]"
        @click="selectionBarClick"
        @clear="clearSelections"
      >
        <template v-slot:selecteds>
          <div class="p-planning__report">
            <div v-if="!$refs.selections?.items.length">
              Você ainda não selecionou disciplinas.
            </div>
            <template v-else>
              <div
                class="p-planning__report-discipline"
                v-for="item in $refs.selections?.items"
                :key="item.id"
              >
                <input
                  :id="`r-${item.id}-${item.group.id}`"
                  type="checkbox"
                  checked
                  @change="schedule(item)"
                />
                <div>
                  <div class="p-planning__report-discipline-title">
                    {{ item.code }} - {{ item.group.code }}
                  </div>
                  <div class="p-planning__report-discipline-subtitle">
                    {{ item.name }}
                  </div>
                  <div
                    class="p-planning__report-discipline-group"
                    v-for="schedule in item.group.schedules"
                    :key="schedule.id"
                  >
                    <b>{{ schedule.day }}</b
                    >: {{ schedule.hour }} com
                    {{ schedule.professor.name }}
                  </div>
                </div>
              </div>
              <Spacing top="1">
                <Button class="v--bg-meforma-pink" @click="copy">Copiar</Button>
              </Spacing>
            </template>
          </div>
        </template>
      </SelectionBar>
    </div>
  </div>
</template>
<script>
import Discipline from '@/components/planning/Discipline/Discipline.vue'
import ScheduleGrid from '@/components/planning/ScheduleGrid/ScheduleGrid.vue'
import Toolbar from '@/components/planning/Toolbar/Toolbar.vue'
import { getSchedule } from '@/helpers/planning.js'
import LoadingList from '@/components/planning/LoadingList/LoadingList.vue'

export default {
  title: 'Planejamento - MeForma!',
  components: { ScheduleGrid, Toolbar, Discipline, LoadingList },
  name: 'planning',
  route: '/planejamento',
  layout: 'painel',
  middleware: ['auth'],
  beforeMount() {
    this.$store.planning.getEnrollmentGuide()
    this.$store.planning.getComponents().then(() => {
      this.$store.planning.getUserPlanning().then((items) => {
        items.forEach((item) => {
          const schedule = getSchedule(item.group)
          this.schedule({
            id: item.id,
            code: item.code,
            name: item.name,
            group: item.group,
            schedule
          })
        })
      })
    })
  },
  data() {
    return {
      items: [],
      q: '',
      type: 'required',
      filter: 'opened',
      selecteds: [],
      report: false
    }
  },
  computed: {
    disciplines() {
      if ((this.items && this.items.length) || this.type) {
        return this.q && this.type
          ? this.items
          : this.$store.planning.filtereds(this.type, this.filter)
      }
      return null
    }
  },
  methods: {
    search(q) {
      if (!q) {
        this.q = ''
        this.items = []
        this.$wait.end('planning/hasSearch')
      }
      if (q.length >= 5) {
        this.q = q
        this.$store.planning.search({ q }).then((items) => {
          this.$wait.start('planning/hasSearch')
          this.items = items

          this.$nextTick(() => {
            this.checkSelections()
          })
        })
      }
    },
    clearSelections(e) {
      e.forEach((item) => {
        this.schedule(item, true)
      })
      this.$toast.show('A grade foi limpa, não esqueça de salvar!')
    },
    selectionBarClick(e) {
      if (e.type === 'save' && !this.$wait.is('planning/save')) {
        this.$store.planning
          .saveUserPlanning({
            semester: this.$store.planning.guide.code,
            groups: e.items.map((i) => {
              return i.group.id
            })
          })
          .then(() => {
            this.$toast.success('Planejamento salvo!')
          })
          .catch(() => {
            this.$toast.error('Houve um erro. Tente novamente.')
          })
      }
    },
    showReport() {
      this.$refs.selections.showList = true
    },
    changeDisciplineType(e) {
      this.type = e.key
      this.$nextTick(() => {
        this.checkSelections()
      })
    },
    checkSelections() {
      this.$refs.selections.items.forEach((item) => {
        if (this.$refs[`d-${item.id}`]?.$refs[`s-${item.group.id}`]) {
          this.$refs[`d-${item.id}`].$refs[`s-${item.group.id}`].checked = true
        }
      })
    },
    filterDisciplines(e) {
      this.filter = e.value
      this.$nextTick(() => {
        this.checkSelections()
      })
    },
    schedule(item, remove = false) {
      if (this.$refs[`d-${item.id}`]?.$refs[`s-${item.group.id}`]) {
        this.$refs[`d-${item.id}`].$refs[`s-${item.group.id}`].checked = true
      }

      let sameDiscipline = []

      Object.keys(this.$refs.grid.planningGrid).forEach((column) => {
        if (this.$refs.grid.planningGrid[column]?.code === item.code) {
          this.$refs.grid.planningGrid[column].schedule.forEach((key) => {
            sameDiscipline.push(key)
          })
          if (
            this.$refs.grid.planningGrid[column]?.group.id === item.group.id
          ) {
            remove = true
          }
        }
      })

      let conflicts = false

      item.schedule.forEach((column) => {
        if (
          this.$refs.grid.planningGrid[column] &&
          this.$refs.grid.planningGrid[column]?.code !== item.code
        ) {
          conflicts = true
        }
      })

      if (conflicts) {
        if (sameDiscipline.length) {
          const { id, group } = this.$refs.grid.planningGrid[sameDiscipline[0]]
          if (
            this.$refs &&
            this.$refs[`d-${id}`] &&
            this.$refs[`d-${id}`].$refs &&
            this.$refs[`d-${id}`].$refs[`s-${group.id}`]
          ) {
            this.$refs[`d-${id}`].$refs[`s-${group.id}`].click()
          }
        }
        if (
          this.$refs &&
          this.$refs[`d-${item.id}`] &&
          this.$refs[`d-${item.id}`].$refs &&
          this.$refs[`d-${item.id}`].$refs[`s-${item.group.id}`]
        ) {
          this.$refs[`d-${item.id}`].$refs[`s-${item.group.id}`].checked = false
        }

        this.$toast.error('Conflito de horários! Não houve alteração.')
        return
      }

      if (sameDiscipline.length) {
        const { id, group } = this.$refs.grid.planningGrid[sameDiscipline[0]]
        if (
          this.$refs &&
          this.$refs[`d-${id}`] &&
          this.$refs[`d-${id}`].$refs &&
          this.$refs[`d-${id}`].$refs[`s-${group.id}`]
        ) {
          this.$refs[`d-${id}`].$refs[`s-${group.id}`].checked = false
        }

        sameDiscipline.forEach((column) => {
          delete this.$refs.grid.planningGrid[column]
        })
      }

      if (remove) {
        this.$refs.selections.remove(item)

        if (
          this.$refs &&
          this.$refs[`d-${item.id}`] &&
          this.$refs[`d-${item.id}`].$refs &&
          this.$refs[`d-${item.id}`].$refs[`s-${item.group.id}`]
        ) {
          this.$refs[`d-${item.id}`].$refs[`s-${item.group.id}`].checked = false
        }

        return
      }

      this.$refs.selections.add(item)

      item.schedule.forEach((column) => {
        this.$refs.grid.planningGrid[column] = item
      })
    },
    copy() {
      let text = ''
      this.$refs.selections.items.forEach((item) => {
        text += `${item.code}: ${item.name}\n`
        text += `Turma: ${item.group.code}\n`
        item.group.schedules.forEach((schedule) => {
          text += `\t${schedule.day}: ${schedule.hour} com ${schedule.professor.name}\n`
        })
      })
      this.$copyText(text)
        .then(() => this.$toast.success('Copiado!'))
        .catch(() => this.$toast.error('Falha ao copiar!'))
    }
  }
}
</script>
<style lang="stylus" scoped>
.p-planning
  max-width 1680px
  margin 0 auto
  padding 0 72px
  @media (max-width 768px)
    padding 0

  &__report
    display grid
    grid-template-columns 1fr
    grid-gap 16px
    margin-top 24px !important
    &-discipline
      display flex
      gap 8px
      &-title
        font-size 13px
        font-weight 700
      &-subtitle
        font-size 11px
        margin-bottom 8px
      &-group
        margin 2px 16px
        font-size 12px
  &__disciplines
    display grid
    grid-gap 8px
    grit-template-columns 1fr
    max-height 660px
    overflow auto
    grid-template-rows min-content
    @media (max-width 768px)
      max-height none

  &__grid
    display grid
    grid-template-columns 450px 1fr
    grid-gap 24px
    grid-template-rows min-content
    &--vertical
      display grid
      grid-gap 16px
      grid-template-rows min-content

    &-container
      grid-template-columns .85fr 1.15fr
      @media(max-width 1200px)
        grid-template-columns 1fr

    @media(max-width: 768px)
      grid-template-columns 1fr
</style>
