<template>
  <div class="c-optionals-container">
    <!-- {{ component.state[type.key] }} -->
    <DisciplinesContainer
      v-for="(semester, key) in $store.component.optionalsByDepartment(
        type.key,
        filter
      )"
      :key="key"
      :horizontal="horizontal"
    >
      <template v-slot:title>
        <div class="c-optionals-container__title">
          <Tag
            :style="{ 'background-color': background(key), color: color(key) }"
          >
            #
          </Tag>
          <span> {{ key }} ({{ semester.length }}) </span>
        </div>
      </template>
      <Discipline
        v-for="discipline in semester"
        :key="discipline.id"
        :loading="loading"
        :item="discipline"
        :horizontal="horizontal"
        @click="$emit('click-discipline', $event)"
      />
    </DisciplinesContainer>
  </div>
</template>
<script>
import Discipline from '@/components/disciplines/Discipline'
import DisciplinesContainer from '@/components/disciplines/DisciplinesContainer'
import color from '@/helpers/color'
export default {
  name: 'OptionalsContainer',
  components: {
    Discipline,
    DisciplinesContainer
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    filter: {
      type: String,
      default: ''
    },
    type: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    background(name) {
      return color.string2Hex(name)
    },
    color(name) {
      return color.colorContrast(this.background(name), '#FFF', '#484848')
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
