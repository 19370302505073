<template>
  <div class="c-toolbar">
    <Dropdown
      title="Tipos de disciplina"
      @change="$emit('change-semester', $event)"
      :items="codes"
      :initial="type"
    >
      Semestre
    </Dropdown>
    <div class="v--flex-align-right c-toolbar__right">
      <Button @click="$emit('edit')">
        <Icon name="edit" size="14" />
        <Spacing left="1" hide-xs>Editar Semestre</Spacing>
      </Button>
      <Button class="v--bg-bright-blue" @click="$emit('create')">
        <Icon name="plus" size="14" />
        <Spacing left="1" hide-xs>Novo semestre</Spacing>
      </Button>
      <Button @click="$emit('delete')">
        <Icon name="trash" size="14" />
      </Button>
      <Button
        v-tooltip:bottom="
          `1 falta equivale a 1 hora-aula. Se você tem 2 aulas em 1 dia, e falta 1 dia, você precisa acrescentar 2 faltas.`
        "
      >
        <Icon name="help-circle" size="16" />
      </Button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DisciplinesToolbar',
  emits: ['change-semester', 'create', 'edit'],
  computed: {
    codes() {
      const codes = this.$store.semester.codes
      if (!codes) {
        return []
      }
      return (
        codes &&
        codes.map((item) => {
          return {
            name: item.code,
            value: item.code
          }
        })
      )
    },
    type() {
      const type =
        this.codes &&
        this.codes.find(
          (item) =>
            item.value ===
            (this.$route && this.$route.query && this.$route.query.chave)
        )
      return type ? type : this.codes && this.codes[0]
    }
  },
  methods: {
    setTemplate(type) {
      this.template = type
      localStorage.setItem('disciplines-template', type)
      this.$emit('change-template', type)
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped />
