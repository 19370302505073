import { reactive } from 'vue'

export default class Auth {
  ctx
  constructor(ctx) {
    this.ctx = ctx

    this.state = reactive({
      courses: [],
      curriculums: []
    })
  }

  setCourses(courses) {
    this.state.courses = courses
  }

  setCurriculums(curriculums) {
    this.state.curriculums = curriculums
  }

  getCourses() {
    this.ctx.config.globalProperties.$wait.start(`university/courses`)

    return this.ctx.config.globalProperties.$axios
      .get(`courses`)
      .then((result) => {
        this.setCourses(result.data && result.data.items)
        return result
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`university/courses`)
      })
  }

  getCourseCurriculums({ id }) {
    this.setCurriculums([])
    this.ctx.config.globalProperties.$wait.start(`university/curriculums`)

    return this.ctx.config.globalProperties.$axios
      .get(`/courses/${id}/curriculums`)
      .then((result) => {
        this.setCurriculums(result.data && result.data.items)
      })
      .finally(() => {
        this.ctx.config.globalProperties.$wait.end(`university/curriculums`)
      })
  }
}
