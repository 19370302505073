<template>
  <div class="c-profile-card">
    <div class="c-profile-card__body">
      <div class="c-profile-card__left">
        <div class="c-profile-card__image">
          <Icon name="meforma" :size="80" />
        </div>
        <div v-if="user" class="c-profile-card__content">
          <h1 class="c-profile-card__title">
            {{ user.name }}
          </h1>
          <div v-if="user.curriculum">
            <p class="c-profile-card__subtitle">
              {{ user.curriculum.course.university.code }}
              <span class="c-profile-card__info-dot">•</span>
              {{ user.curriculum.course.name }}
            </p>
            <small class="c-profile-card__info">
              Currículo {{ user.curriculum.code }} •
              {{ user.curriculum.shift }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProfileCard',
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
