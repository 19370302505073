export default class Siac {
  constructor(ctx) {
    this.ctx = ctx
  }

  curriculum(payload) {
    return this.ctx.config.globalProperties.$axios.post(
      '/siac/curriculum',
      payload
    )
  }

  history(payload) {
    return this.ctx.config.globalProperties.$axios.post(
      '/siac/history',
      payload
    )
  }

  semester(payload) {
    return this.ctx.config.globalProperties.$axios.post(
      '/siac/semester',
      payload
    )
  }
}
