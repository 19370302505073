<template>
  <div class="p-profile">
    <ProfileMenu />
    <PasswordForm />
  </div>
</template>
<script>
import PasswordForm from '@/components/profile/form/PasswordForm.vue'
import ProfileMenu from '@/components/profile/ProfileMenu/ProfileMenu.vue'
export default {
  title: 'Alterar senha - MeForma!',
  name: 'changePassword',
  route: '/perfil/alterar-senha',
  layout: 'painel',
  middleware: ['auth'],
  components: { PasswordForm, ProfileMenu }
}
</script>

<style lang="stylus" scoped>
.p-profile {
  max-width: 1016px;
  margin: 0 auto;
  display grid
  grid-template-columns 200px 1fr
}
</style>
