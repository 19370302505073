<template>
  <Card
    class="c-components-truth-table"
    :class="{
      'v--dark__card': $store.settings.darkMode,
      'v--loading': loading
    }"
  >
    <template v-slot:header>
      <Spacing bottom="1">
        <p class="c-components-truth-table__title">{{ title }}</p>
        <p class="c-components-truth-table__subtitle">
          {{ done }}/{{ table && table.length }}
        </p>
      </Spacing>

      <Button
        circle
        fit
        v-tooltip="`Aqui você pode visualizar os semestres que já completou`"
        class="v--flex-place-self-start v--flex-align-right"
      >
        <Icon name="help-circle" />
      </Button>
    </template>
    <div class="c-components-truth-table__false-content"></div>
    <div
      v-for="(row, index) in table"
      :key="index"
      class="c-components-truth-table__row"
    >
      <span class="c-components-truth-table__row-title">
        {{ index + 1 }}º
      </span>
      <span
        class="c-components-truth-table__columns"
        :style="{ 'grid-template-columns': `repeat(${size}, 1fr)` }"
      >
        <span
          v-for="(column, c) in row"
          class="c-components-truth-table__column"
          :class="{ 'c-components-truth-table__column--done': column }"
          :key="`i-${index}-${c}`"
        />
        <span v-if="row.length < size" />
      </span>
    </div>
    <Spacing top="2">
      <Legend
        :items="[
          { name: 'Feito', color: '#5FDC8A' },
          { name: 'Não feito', color: '#D1D3D2' }
        ]"
      />
    </Spacing>
  </Card>
</template>
<script>
export default {
  name: 'ComponentsTruthTable',
  props: {
    title: {
      type: String,
      default: ''
    },
    table: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    done() {
      return (
        (this.table &&
          this.table.reduce((m, i) => {
            return i.reduce((a, b) => a + b, 0) === i.length ? m + 1 : m
          }, 0)) ||
        0
      )
    },
    size() {
      return (
        this.table &&
        this.table.reduce((a, b) => {
          a = b.length > a ? (a = b.length) : a
          return a
        }, 0)
      )
    }
  }
}
</script>
<style src="./style.styl" lang="stylus" scoped />
