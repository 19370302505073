<template>
  <div class="c-tag">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Tag'
}
</script>
<style src="./style.styl" lang="stylus" scoped />
