<template>
  <div
    class="c-progress-card"
    :class="{
      'v--dark__card': $store.settings.darkMode
    }"
  >
    <div class="c-progress-card__content" :class="{ 'v--loading': loading }">
      <div v-if="$slots.options" class="c-progress-card__options">
        <slot name="options"></slot>
      </div>

      <div class="c-progress-card__title">
        <Spacing right="1">
          <router-link v-if="to" :to="to">{{ title }}</router-link>
          <span v-else>
            {{ title }}
          </span>
        </Spacing>
        <Button
          v-if="help"
          circle
          fit
          v-tooltip="help"
          class="c-progress-card__help"
        >
          <Icon name="help-circle" />
        </Button>
      </div>
      <div class="c-progress-card__subtitle">
        {{ subtitle || `${value} / ${total} ${theme}` }}
        <span v-if="showPercentage" style="font-size: 12px">
          {{ `-  ${percentageCalculated && percentageCalculated}%` }}
        </span>
      </div>
      <Spacing :top="1">
        <ProgressBar
          :value="value"
          :total="total"
          :percentage="percentageCalculated"
        />
      </Spacing>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProgressCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: true
    },
    showPercentage: {
      type: Boolean,
      default: false
    },
    value: {
      type: null,
      default: undefined
    },
    total: {
      type: [Number, String],
      default: 0
    },
    percentage: {
      type: null,
      default: undefined
    },
    help: {
      type: String,
      default: ''
    },
    to: {
      type: null,
      default: null
    }
  },
  computed: {
    percentageCalculated() {
      if (this.percentage !== undefined) {
        return parseFloat(this.percentage).toFixed(2)
      }
      if (this.value && this.total) {
        return this.calcPercentage(
          parseFloat(this.value),
          parseFloat(this.total)
        ).toFixed(2)
      }
      return 0
    }
  },
  methods: {
    calcPercentage(value, max) {
      if (max === 0) return 0
      let result = (value * 100) / max

      return result >= 100 ? 100 : result
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
