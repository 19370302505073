<template>
  <div class="c-profile-card">
    <div class="c-profile-card__body">
      <div class="c-profile-card__content" v-if="discipline">
        <h1 class="c-profile-card__title">
          {{ discipline.code }}
        </h1>

        <p class="c-profile-card__subtitle">{{ discipline.name }}</p>
        <Hashtag :hash-color="background">
          {{ discipline.department }}
        </Hashtag>
      </div>
      <div v-else class="c-profile-card__loading">
        <div class="c-profile-card__loading-title"></div>
        <div class="c-profile-card__loading-subtitle"></div>
        <div class="c-profile-card__loading-hashtag"></div>
      </div>
    </div>
  </div>
</template>
<script>
import color from '@/helpers/color'
export default {
  name: 'DisciplineCard',
  props: {
    discipline: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    background() {
      return color.string2Hex(this.discipline.department)
    }
  },
  methods: {}
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
