<template>
  <div class="c-kanban">
    <Switcher
      class="c-kanban__switcher"
      :items="items"
      :selected="selected"
      @select="select"
    />
    <div class="c-kanban__board">
      <div
        class="c-kanban__column"
        v-for="item in items"
        :key="item.key"
        :class="{ 'c-kanban__column--selected': selected === item.name }"
      >
        <div class="c-kanban__header">{{ item.name }} ({{ item.type }})</div>
        <div
          class="c-kanban__column-body"
          @dragenter="dragEnter"
          @dragover="dragOver"
          @drop="drop($event, item)"
        >
          <Discipline
            v-for="(discipline, key) in disciplines(item.key)"
            :key="key"
            :item="discipline"
            draggable="true"
            @dragstart="dragStart($event, discipline)"
            :hoverable="false"
          >
            <template v-slot:more>
              <Dropdown
                title="Mover para:"
                hide-indicator
                style="float: right"
                placement="bottom-end"
                :changeNameOnSelect="false"
                :items="
                  items
                    .map((i) => (i.id = i.key) && i)
                    .filter((i) => i.key !== item.key)
                "
                @change="move($event, discipline.id)"
              >
                <Icon name="more-horizontal" />
              </Dropdown>
            </template>
          </Discipline>
        </div>
      </div>
    </div>
    <SelectionBar
      ref="selections"
      @click="clickSelectionBar"
      @clear="clear"
      :options="[{ name: 'Salvar', value: 'save', icon: 'check' }]"
    />
  </div>
</template>
<script>
import Discipline from '../disciplines/Discipline/Discipline.vue'
import Switcher from './Switcher/Switcher.vue'
export const CardDataType = 'text/x-kanban-card'
export default {
  components: { Discipline, Switcher },
  name: 'Kanban',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: 'Disciplinas Obrigatórias'
    }
  },
  methods: {
    select(item) {
      this.selected = item.name
    },
    clickSelectionBar(e) {
      if (e.type === 'save') {
        this.save()
      }
    },
    save() {
      const items = this.$refs.selections.items.map((item) => {
        return {
          id: item.id,
          kind: item.used_as
        }
      })
      if (!items?.length) {
        this.$toast.error('Você precisa mover alguma disciplina para salvar.')
        return
      }
      this.$store.organizer
        .updateComponents({ disciplines: items })
        .then(() => {
          this.$toast.success('Atualizado!')
        })
        .catch(() => {
          this.$toast.error('Houve um erro. Tente novamente!')
        })
    },
    disciplines(type) {
      return this.$store.organizer.filtereds(type, 'done')
    },
    dragStart(event, discipline) {
      const dataTransfer = event.dataTransfer
      dataTransfer.setData(CardDataType, discipline.id)
      dataTransfer.effectAllowed = 'move'
    },
    dragEnter(event) {
      if (event.dataTransfer.types.includes[CardDataType]) {
        event.preventDefault()
      }
    },
    dragOver(event) {
      event.dataTransfer.dropEffect = 'move'
      event.preventDefault()
    },
    drop(event, type) {
      const data = event.dataTransfer.getData(CardDataType)
      this.move(type, data)
    },
    move(type, disciplineId) {
      this.$store.organizer.setComponents(
        this.$store.organizer.components.map((item) => {
          if (item.id === parseInt(disciplineId)) {
            const old = item.used_as
            item.used_as = type.type
            this.$refs.selections.add({
              ...item,
              name: `${item.name}: de ${old} para ${type.type}`,
              from: old,
              to: type.type
            })
          }
          return item
        })
      )
    },
    clear(items) {
      this.$store.organizer.setComponents(
        this.$store.organizer.components.map((item) => {
          const exist = items.find((i) => i.id === item.id)
          if (exist) {
            item.used_as = exist.from
          }
          return item
        })
      )
    }
  }
}
</script>
<style lang="stylus" scoped>
.c-kanban
    &__switcher
      @media (min-width 769px)
        display none
    &__board
        border 1px solid #f3f3f4
        padding 16px
        border-radius 8px
        display grid
        grid-gap 8px
        grid-auto-flow column
        max-width 100%
        overflow-x auto
        @media (max-width 768px)
          padding 0
          border none
          overflow unset

    &__header
        padding 8px
        @media (max-width 768px)
          display none
    &__column
        height 600px
        width 320px
        background-color #f3f3f4
        border-radius 8px
        display flex
        flex-direction column
        max-width 100%
        @media (max-width 768px)
          height auto
          padding 0
          background-color transparent
          display none
          &--selected
            display flex
        &-body
            overflow auto
            display grid
            margin-bottom 8px
            grid-template-columns 1fr
            grid-gap 4px
            padding 8px
            height 100%
            grid-auto-rows min-content
            @media (max-width 768px)
              padding 0
              overflow unset
</style>
