<template>
  <div>
    <h1>404</h1>
    <p style="font-size: 18px">
      A página que você solicitou não foi encontrada.
    </p>
  </div>
</template>
<script>
export default {
  name: '404',
  route: '/:pathMatch(.*)*',
  layout: 'error'
}
</script>
