import { auth } from '@/store/auth'
export default async ({ redirect, next, from }) => {
  let user = null
  let token = null
  if (from?.name && auth?.state?.user) {
    user = auth?.state?.user || null
    token = localStorage.token ? localStorage.token : null
  } else {
    await auth.getUser().then((result) => {
      user = (result?.data && result.data.data) || null
      token = localStorage.token ? localStorage.token : null
    })
  }

  if (!(user && token)) {
    redirect('/login')
  } else if (!user.email_verified_at || !user.curriculum) {
    redirect('/completar-cadastro')
  } else {
    next()
  }
}
