<template>
  <div class="p-register">
    <main class="p-register__main">
      <form class="p-register__form" @submit.prevent="submit">
        <div class="v--align-center">
          <Icon name="meforma" :size="80" />
          <p>Junte-se à galera que quer formar</p>
        </div>
        <div class="p-register__grid">
          <div>
            <Field
              label="Seu nome"
              sublabel="Esse é seu nome público. É a forma como vai aparecer para outros usuários."
              name="name"
              v-model="form.name"
            />
            <Field
              label="E-mail"
              name="email"
              type="email"
              v-model="form.email"
              :disabled="!!$wait.is('register')"
              autocomplete="off"
              required
            />
            <Field
              label="Senha"
              sublabel="Mínimo de 6 caracters. Utitlize maiúsculas, minúsculas, números e caracteres especiais"
              name="password"
              type="password"
              autocomplete="off"
              v-model="form.password"
              visibility-switch
              :disabled="!!$wait.is('register')"
              required
            />
            <Field
              label="Repita a senha"
              name="password_confirmation"
              type="password"
              v-model="form.passwordConfirmation"
              visibility-switch
              :disabled="!!$wait.is('register')"
              required
            />
          </div>
        </div>
        <Spacing vertical="2">
          <label for="accordance">
            <input id="accordance" type="checkbox" v-model="form.confirm" />
            Criar uma conta significa que você <b>leu e está de acordo</b> com
            nossos
            <a
              target="_blank"
              href="https://docs.google.com/document/d/1k5Usl_Nq-n7sWtAoooLXW7I4GNQTUUVUEvuC5tnasdk/edit?usp=sharing"
              rel="nofollow"
            >
              Termos e condições de uso
            </a>
            e com a nossa
            <a
              target="_blank"
              rel="nofollow"
              href="https://docs.google.com/document/d/1pwSrzu_onRkOcUClje8o9W6flfCA1GYbRKG4TME46AA/edit?usp=sharing"
            >
              Política de privacidade
            </a>
          </label>
        </Spacing>
        <Spacing top="1">
          <Button
            sprawled
            class="v--bg-amaranth"
            :loading="!!$wait.is('register')"
            >Cadastrar</Button
          >
        </Spacing>
        <Spacing :vertical="1.5">
          <Line />
        </Spacing>
        <p>
          Já possui conta?
          <router-link to="/login">Faça login</router-link>
        </p>
      </form>
    </main>
  </div>
</template>
<script>
export default {
  title: 'Cadastro - MeForma!',
  name: 'Register',
  route: '/cadastro',
  middleware: ['guest'],
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        confirm: false
      }
    }
  },
  methods: {
    submit() {
      if (!this.form.confirm) {
        this.$toast.error('Você precisa concordar com os termos!')
        return
      }

      if (this.form.password !== this.form.passwordConfirmation) {
        this.$toast.error('As senhas estão diferentes!')
        return
      }

      this.$wait.start('register')
      this.$store.auth
        .register({
          ...this.form,
          password_confirmation: this.form.passwordConfirmation
        })
        .then(() => this.$router.push('/completar-cadastro'))
        .catch(() =>
          this.$toast.error('Algo está errado. Verifique os campos.')
        )
        .finally(() => this.$wait.end('register'))
    }
  }
}
</script>
<style lang="stylus" scoped>
.p-register
  display grid
  min-height 100vh
  padding 16px
  width 100%
  &__main
    display flex
    align-items center
    justify-content center

  &__form
    width 100%
    max-width 600px
    display block
</style>
