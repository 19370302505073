<template>
  <div class="p-hour">
    <Spacing :bottom="3">
      <Toolbar
        @change-hour-type="selectHourType"
        :dropdown="dropdown"
        @create-hour="showModal"
        :selected="type"
      />
    </Spacing>
    <LoadingList v-if="$wait.is(`hour/getAll`) && !hours?.length" />
    <Spacing v-else-if="hours.length" top="2">
      <div class="p-hour__hours">
        <Hour
          v-for="hour in hours"
          :key="hour.id"
          :item="hour"
          @edit="showEditModal"
          @delete="showDeleteModal"
        />
      </div>
    </Spacing>
    <EmptyList v-else @button-click="showModal" />
    <HourModal
      v-if="modal || editModal"
      :types="dropdown"
      :type="type"
      :hour="editModal"
      @close="closeModal"
    />
    <DeleteModal
      v-if="deleteModal"
      :name="deleteModal.title"
      :id="deleteModal.id"
      :loading="$wait.is('hour/remove')"
      @close="closeDeleteModal"
      @remove="remove"
    />
  </div>
</template>
<script>
import Toolbar from '@/components/hour/Toolbar/Toolbar.vue'
import Hour from '@/components/hour/Hour/Hour.vue'
import LoadingList from '@/components/hour/LoadingList/LoadingList.vue'
import EmptyList from '@/components/hour/EmptyList/EmptyList.vue'
import HourModal from '@/components/hour/HourModal/HourModal.vue'

export default {
  title: 'Horas - MeForma!',
  name: 'hours',
  route: '/horas',
  layout: 'painel',
  middleware: ['auth'],
  beforeMount() {
    this.$store.hour.getHours()
  },
  data() {
    return {
      hourType: null,
      modal: false,
      deleteModal: null,
      editModal: null
    }
  },
  components: { Toolbar, Hour, LoadingList, EmptyList, HourModal },
  computed: {
    hours() {
      return this.$store.hour.hours(this.type)
    },
    type() {
      if (this.$route && this.$route.query && this.$route.query.tipo) {
        const type = this.dropdown.find(
          (item) => item.name === this.$route.query.tipo
        )
        if (type && type.value) {
          return type.value
        }
      }

      return this.hourType
        ? this.hourType
        : this.dropdown && this.dropdown[0].value
    },
    dropdown() {
      const user = this.$store.auth.user
      return [
        {
          name: 'Complementares',
          value: 'supplementary'
        },
        {
          name: 'Optativas',
          value: 'optional'
        },
        {
          name: 'Livres',
          value: 'free'
        }
      ].filter(
        (item) =>
          user && user.curriculum && user.curriculum.workload[item.value] > 0
      )
    }
  },
  methods: {
    selectHourType(type) {
      this.hourType = type.value
      this.$router.replace({
        ...this.$route,
        query: {
          tipo: type.name
        }
      })
    },
    closeModal() {
      this.editModal = null
      this.modal = false
    },
    showModal() {
      this.modal = true
    },
    showEditModal(hour) {
      this.editModal = { ...hour }
    },
    closeEditModal() {
      this.editModal = null
    },
    showDeleteModal(hour) {
      this.deleteModal = hour
    },
    closeDeleteModal() {
      this.deleteModal = null
    },
    remove() {
      this.$store.hour
        .remove({ id: this.deleteModal.id, type: this.type })
        .then(() => {
          this.$toast.success(`${this.deleteModal.title} excluído!`)
          this.deleteModal = null
        })
        .catch((e) => {
          let message = 'Houve um erro, por favor recarregue a página'
          if (e.response && e.response.data && e.response.data.message) {
            message = e.response.data.message
          }
          this.$toast.error(message)
        })
    }
  }
}
</script>
<style lang="stylus" scoped>
.p-hour {
  max-width: 1016px;
  margin: 0 auto;
  text-align center
  &__hours {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px
    width: 100%;
  }
}
</style>
