<template>
  <div class="p-profile">
    <ProfileMenu />
    <PersonalDataForm />
  </div>
</template>
<script>
import PersonalDataForm from '@/components/profile/form/PersonalDataForm.vue'
import ProfileMenu from '@/components/profile/ProfileMenu/ProfileMenu.vue'
export default {
  title: 'Editar perfil - MeForma!',
  name: 'profile',
  route: '/perfil/configuracoes',
  layout: 'painel',
  middleware: ['auth'],
  components: { PersonalDataForm, ProfileMenu },
  beforeMount() {
    this.$store.university.getCourses()
    this.$store.university.getCourseCurriculums({
      id: this.$store.auth.user.curriculum.course.id
    })
  }
}
</script>

<style lang="stylus" scoped>
.p-profile {
  max-width: 1016px;
  margin: 0 auto;
  display grid
  grid-template-columns 200px 1fr

  @media (max-width: 768px) {
    grid-template-columns: 1fr
  }
}
</style>
