<template>
  <div class="fimcoakaoksa">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'blank'
}
</script>
