<template>
  <div class="c-requireds-container">
    <DisciplinesContainer
      v-for="(semester, key) in $store.component.semesters(this.filter)"
      :key="key"
      :horizontal="horizontal"
    >
      <template v-slot:title>
        <Tag class="c-requireds-container__title"> {{ key }}º período </Tag>
      </template>
      <Discipline
        v-for="discipline in semester"
        :key="discipline.id"
        :loading="loading"
        :item="discipline"
        :horizontal="horizontal"
        @click="$emit('click-discipline', $event)"
      />
    </DisciplinesContainer>
  </div>
</template>
<script>
import Discipline from '@/components/disciplines/Discipline'
import DisciplinesContainer from '@/components/disciplines/DisciplinesContainer'
export default {
  name: 'RequiredsContainer',
  components: {
    Discipline,
    DisciplinesContainer
  },
  emits: ['click-discipline'],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    filter: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
