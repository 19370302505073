<template>
  <div class="p-profile">
    <ProfileMenu />
    <div>
      <!-- <h2>Configurações da conta</h2>
      <Spacing vertical="2">
        <Field
          label="E-mail"
          name="email"
          v-model="form.email"
          sublabel="Ao alterar o e-mail, você vai precisar confirmar o e-mail novamente. Até lá, o e-mail atual permanece."
        />
      </Spacing>
      <Spacing vertical="2">
        <Button class="v--bg-meforma-pink">Salvar</Button>
      </Spacing>
      <Spacing vertical="4">
        <Line />
      </Spacing> -->
      <div>
        <h2>Excluir conta</h2>
        <Spacing vertical="2">
          <p>
            Se você excluir sua conta, vai deixar de contribuir com os dados de
            aprovação de uma disciplina, por exemplo, além de deixar um vazio em
            nosso coração. Por isso, pedimos que por favor não faça isso
            {{ `:)` }}.
          </p>
        </Spacing>
        <Spacing vertical="2">
          <Button @click="removeAccount = true">Excluir minha conta</Button>
        </Spacing>
      </div>
      <Modal
        v-if="removeAccount"
        title="Excluir conta"
        small
        @close="removeAccount = false"
      >
        <Spacing vertical="2">
          Se você tem certeza de que deseja excluir sua conta, e entende as
          consequências da sua ação, digite sua senha abaixo e, logo após, você
          perderá o acesso ao MeForma, porque sua conta será excluída.
        </Spacing>
        <form>
          <Field
            name="password"
            label="Senha"
            type="password"
            v-model="form.password"
          />
        </form>
        <template v-slot:footer>
          <Button
            :loading="$wait.is('user/delete')"
            class="v--bg-meforma-pink"
            style="width: 125px"
            @click="remove"
          >
            Excluir conta
          </Button>
        </template>
      </Modal>
    </div>
  </div>
</template>
<script>
import ProfileMenu from '@/components/profile/ProfileMenu/ProfileMenu.vue'
export default {
  title: 'Conta - MeForma!',
  name: 'account',
  route: '/perfil/conta',
  layout: 'painel',
  middleware: ['auth'],
  components: { ProfileMenu },
  data() {
    return {
      form: {},
      removeAccount: false
    }
  },
  methods: {
    remove() {
      this.$store.auth
        .remove(this.form)
        .then(() => {
          this.$toast.success('Até qualquer hora!')
          this.$store.auth.logout()
        })
        .catch(() => {
          this.$toast.error('Senha incorreta!')
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.p-profile {
  max-width: 1016px;
  margin: 0 auto;
  display grid
  grid-template-columns 200px 1fr
}
</style>
