<template>
  <Modal class="c-new-hour-modal" small @close="$emit('close')">
    <form class="c-new-hour-modal__form" id="new-hour" @submit.prevent="save">
      <Field
        label="Título"
        placeholder="Ex: Iniciação Científica 2018"
        name="title"
        required
        :value="form.title"
        v-model="form.title"
      />
      <Field
        label="Horas"
        required
        name="hours"
        type="number"
        :value="form.hours"
        v-model="form.hours"
      />
      <FieldText
        label="Descrição"
        name="description"
        rows="4"
        placeholder="Ex: O certificado está no Google Drive"
        :value="form.description"
        v-model="form.description"
      />
      <FieldSelect
        name="status"
        required
        label="Status de aproveitamento"
        :options="options"
        :selected="form.status"
        @change="form.status = $event.target.value"
      />
      <FieldSelect
        v-if="!(hour && hour.title)"
        name="type"
        required
        label="Tipo de aproveitamento"
        :options="types"
        :selected="form.type"
        @change="form.type = $event.target.value"
      />
    </form>
    <template v-slot:footer>
      <div class="v--flex v--flex-align-right">
        <Button @click="$emit('close')">Cancelar</Button>
        <Spacing left="1">
          <Button
            v-if="hour && hour.title"
            form="new-hour"
            class="v--bg-caribbean-green"
            :loading="$wait.is('hour/create') || $wait.is('hour/update')"
            style="width: 176px"
          >
            Atualizar
          </Button>
          <Button
            v-else
            form="new-hour"
            class="v--bg-caribbean-green"
            :loading="$wait.is('hour/create') || $wait.is('hour/update')"
            style="width: 176px"
          >
            Salvar
          </Button>
        </Spacing>
      </div>
    </template>
  </Modal>
</template>
<script>
export default {
  name: 'HourModal',
  emits: ['close'],
  props: {
    type: {
      type: String,
      default: ''
    },
    types: {
      type: Array,
      default: () => []
    },
    hour: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: [
        { name: 'Aproveitada', value: true },
        { name: 'Pendente', value: false }
      ],
      form:
        this.hour && this.hour.title
          ? {
              ...this.hour,
              status: this.hour.status === 'Aproveitado',
              type: this.type
            }
          : {
              title: '',
              description: '',
              type: this.type,
              status: 'true',
              hours: ''
            }
    }
  },
  methods: {
    save() {
      if (this.hour && this.hour.title) {
        this.update()
      } else {
        this.store()
      }
    },
    store() {
      this.$store.hour
        .create({
          ...this.form,
          status: this.form.status === 'true'
        })
        .then(() => {
          this.$toast.success(
            `${this.form.hours} horas de "${this.form.title}" adicionadas!`
          )
          this.$emit('close')
        })
        .catch((e) => this.onError(e))
    },
    update() {
      this.$store.hour
        .update({
          id: this.hour.id,
          ...this.form,
          status: this.form.status === 'true'
        })
        .then(() => {
          this.$toast.success(`Horas atualizadas!`)
          this.$emit('close')
        })
        .catch((e) => this.onError(e))
    },
    onError(e) {
      let message = 'Houve um erro, por favor recarregue a página'

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message
      }

      this.$toast.error(message)
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
