<template>
  <div
    :id="`discipline-${item.id}`"
    class="c-discipline"
    :disabled="disabled"
    :class="{
      'v--dark': $store.settings.darkMode,
      'v--checked': item.done && !loading,
      'c-discipline--horizontal': horizontal,
      'c-discipline--hoverable': !open,
      'v--disabled': disabled
    }"
    @click="click"
  >
    <div class="c-discipline__content" :class="{ 'v--loading': loading }">
      <h2 class="c-discipline__title">
        {{ item.code }}
        <small>
          <span>•</span>
          {{ item.hours }}hs
        </small>
      </h2>
      <p class="c-discipline__subtitle">
        {{ item.name }}
      </p>
      <Spacing top="1">
        <p class="c-discipline__info">{{ dependency }}</p>
        <p class="c-discipline__info">Índice de aprovação: {{ approves }}%</p>
      </Spacing>

      <Spacing v-if="item.department" top="1" class="c-discipline__hashtag">
        <Hashtag :hash-color="background">
          {{ item.department }}
        </Hashtag>
      </Spacing>
    </div>
    <div class="c-discipline__groups" v-show="open">
      <div class="c-discipline__group" v-if="!item.groups?.length">
        Essa disciplina não foi ofertada
      </div>
      <label
        v-else
        v-for="group in item.groups"
        :key="group.id"
        :for="`s-${group.id}`"
        class="c-discipline__group"
        @click.stop
      >
        <div class="v--flex v--flex-align-middle">
          <input
            :id="`s-${group.id}`"
            :ref="`s-${group.id}`"
            :name="`group-${item.id}`"
            type="checkbox"
            @change="selectGroup(group)"
          />
          <Spacing left="1">
            {{ group.code }}
          </Spacing>
        </div>
        <Spacing top="1">
          <Schedule
            v-for="schedule in group.schedules"
            :key="schedule.id"
            :item="schedule"
          />
        </Spacing>
      </label>
    </div>
  </div>
</template>
<script>
import color from '@/helpers/color'
import Schedule from './Schedule.vue'
import { getSchedule } from '@/helpers/planning.js'

export default {
  components: { Schedule },
  name: 'PlanningDiscipline',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'schedule'],
  data() {
    return {
      open: false
    }
  },
  computed: {
    background() {
      return color.string2Hex(this.item.department)
    },
    dependency() {
      if (this.item?.dependents) {
        if (this.item.dependents?.length === 1) {
          return `Libera 1 disciplina`
        }
        return `Libera ${this.item.dependents.length} disciplinas`
      }
      return ''
    },
    approves() {
      const { enrollments } = this.item
      if (!enrollments) {
        return 'Não calculado '
      }
      return (((enrollments.AP || 0) * 100) / (enrollments.TOTAL || 1)).toFixed(
        2
      )
    }
  },
  methods: {
    click() {
      this.open = !this.open
      this.$emit('click', this.item)
    },
    selectGroup(group) {
      const items = getSchedule(group)
      this.$emit('schedule', {
        id: this.item.id,
        code: this.item.code,
        name: this.item.name,
        group: group,
        schedule: items
      })
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
