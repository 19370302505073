<template>
  <div class="p-semester">
    <Spacing :bottom="3" v-if="$store.semester.codes?.length">
      <Toolbar
        @change-semester="selectSemester"
        @create="gotToCreateSemester"
        @edit="gotToEditSemester"
        @delete="openDeleteModal"
      />
    </Spacing>
    <LoadingList
      v-if="$wait.is(`semester/codes`) || $wait.is(`semester/get`)"
    />
    <Spacing v-else-if="components?.length" top="2" class="v--flex">
      <div class="p-semester__disciplines">
        <Discipline
          v-for="discipline in components"
          :key="discipline.id"
          :item="discipline"
          horizontal
          @click="$emit('click-discipline', $event)"
        />
      </div>
    </Spacing>
    <EmptyDisciplines
      v-else-if="$store.semester.codes?.length"
      @click="gotToEditSemester"
    />
    <EmptyList @click="gotToCreateSemester" v-else />
    <DeleteModal
      v-if="deleteModal"
      :name="item?.code"
      :id="item?.code"
      :loading="$wait.is('semester/remove')"
      @close="closeDeleteModal"
      @remove="remove"
    />
  </div>
</template>
<script>
import Toolbar from '@/components/semester/Toolbar/Toolbar.vue'
import Discipline from '@/components/semester/Discipline/Discipline.vue'
import LoadingList from '@/components/semester/LoadingList/LoadingList.vue'
import EmptyDisciplines from '@/components/semester/EmptyDisciplines/EmptyDisciplines.vue'
import EmptyList from '@/components/semester/EmptyList/EmptyList.vue'

export default {
  title: 'Semestre - MeForma!',
  name: 'semester',
  route: '/semestre',
  layout: 'painel',
  middleware: ['auth'],
  beforeMount() {
    const { query } = this.$route
    let semester = {}

    this.$store.semester.getCodes().then((result) => {
      semester = result.data.items.length && result.data.items[0]
      if (semester) {
        if (query && query.chave) {
          this.$store.semester.getSemester({ code: query.chave })
        } else {
          this.$store.semester.getSemester(semester)
        }
      }
    })
  },
  components: {
    Toolbar,
    Discipline,
    LoadingList,
    EmptyList,
    EmptyDisciplines
  },
  data() {
    return {
      deleteModal: false
    }
  },
  computed: {
    item() {
      return this.$store.semester.semester
    },
    code() {
      return this.item && this.item.code
    },
    components() {
      return this.item && this.item.components
    }
  },
  methods: {
    selectSemester(item) {
      this.$router.replace({
        ...this.$route,
        query: {
          chave: item.value
        }
      })
      this.$store.semester.resetSemester()
      this.$store.semester.getSemester({ code: item.value })
    },
    gotToCreateSemester() {
      this.$router.push('/semestre/novo')
    },
    closeDeleteModal() {
      this.deleteModal = false
    },
    openDeleteModal() {
      this.deleteModal = true
    },
    gotToEditSemester() {
      this.$router.push(`/semestre/editar/${this.item?.code}`)
    },
    remove(e) {
      this.$wait.start(`semester/remove`)
      this.$store.semester.removeSemester({ code: e.id }).finally(() => {
        this.$store.semester.reset()

        this.$router.replace({
          ...this.$route,
          query: {}
        })

        this.$store.semester.getCodes().then((result) => {
          const semester = result.data.items.length && result.data.items[0]
          if (semester) {
            this.$store.semester.getSemester(semester).then(() => {
              this.$wait.end(`semester/remove`)
              this.closeDeleteModal()
              this.$toast.success('Semestre removido!')
            })
          } else {
            this.$wait.end(`semester/remove`)
            this.closeDeleteModal()
            this.$toast.success('Semestre removido!')
          }
        })
      })
    }
  },
  beforeUnmount() {
    this.$store.semester.reset()
  }
}
</script>
<style lang="stylus" scoped>
.p-semester {
  max-width: 1016px;
  margin: 0 auto;
  text-align center
  &__disciplines {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px
    width: 100%;
  }
  &__grid {
    text-align left
    display: inline-grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-left auto!important
    margin-right auto!important
    &--vertical {
      grid-gap: 8px;
      display: grid;
    }
    &-resume {
      border 1px solid var(--default-grey)
      display flex
      flex-direction column
      & >>> > * {
        margin-top 16px
        &:first-child {
          margin-top 0
        }
      }
    }
  }
}
</style>
