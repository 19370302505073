<template>
  <div class="p-verify">
    <div class="p-verify__content">
      <div>
        <div class="v--align-center">
          <Icon name="meforma" :size="80" />
          <p v-if="$store.auth.user?.email_verified_at" class="p-verify__title">
            E-mail confirmado! Agora é só cadastrar seu curso.
          </p>
          <p v-else class="p-verify__title">
            E aí, {{ $store.auth.user.name.split(' ')[0] }}! Estamos quase lá...
            Faltam apenas 2 passos.
          </p>
        </div>
        <Spacing vertical="4" class="p-verify__breadcumb">
          <div
            :class="{
              'p-verify__inative': $store.auth.user.email_verified_at
            }"
          >
            Confirmar e-mail
          </div>
          <Icon name="chevron-right" />
          <div
            :class="{
              'p-verify__inative': !$store.auth.user.email_verified_at
            }"
          >
            Completar inscrição
          </div>
        </Spacing>
        <Spacing v-if="$store.auth.user.email_verified_at">
          <p>
            A melhor forma de continuar é importando seus dados do SIAC. Assim,
            conseguimos recuperar facilmente o seu curso, disciplinas cursadas,
            horas aproveitadas, etc. Mas você também pode optar por um cadastro
            manual difícil, chato e desgastante. Vocẽ pode importar dados do
            SIAC a qualquer momento.
          </p>
          <Spacing top="2" class="v--flex v--flex-align-center">
            <Button class="v--bg-meforma-pink" @click="openSiacModal">
              Importar dados do SIAC
            </Button>
          </Spacing>
          <Spacing vertical="2">
            <Line />
          </Spacing>
          <Spacing class="v--flex v--flex-align-center">
            <Button @click="openManualModal">Fazer do jeito difícil</Button>
          </Spacing>
          <Modal v-if="manual" @close="closeManualModal">
            <form id="course-register" @submit.prevent="register">
              <FieldSelect
                label="Curso"
                name="course"
                :disabled="
                  !!$wait.is('university/courses') || !!$wait.is('user/update')
                "
                :options="courses"
                @change="selectCourse"
              />
              <FieldSelect
                label="Currículo"
                name="curriculum"
                sublabel="Lembre-se de que o currículo não é necessariamente seu semestre de entrada na universidade."
                :disabled="
                  !!$wait.is('university/courses') ||
                  !!$wait.is('university/curriculums') ||
                  !!$wait.is('user/update')
                "
                :options="curriculums"
                @change="selectCurriculum"
              />
              <Field
                label="Semestre de entrada. Ex: 2014.1"
                sublabel="E aí? Você é de qual semestre? Eu fui de 2014.1."
                name="start"
                v-model="form.start"
                visibility-switch
                :disabled="!!$wait.is('user/update')"
                required
              />
              <Field
                label="Nº de matrícula"
                name="register"
                v-model="form.register"
                visibility-switch
                :disabled="!!$wait.is('user/update')"
                required
              />
            </form>
            <template v-slot:footer>
              <Button
                form="course-register"
                class="v--bg-meforma-pink"
                :loading="!!$wait.is('user/update')"
                style="width: 125px"
              >
                Cadastrar curso
              </Button>
            </template>
          </Modal>
        </Spacing>
        <Spacing v-else vertical="2">
          <Spacing vertical="2">
            <p>
              Enviamos um e-mail para você com as instruções necessárias para
              concluir o cadastro.
            </p>
            <Spacing vertical="1">
              <p>
                Caso não tenha recebido o e-mail,
                <a @click.prevent="resend">clique aqui para reenviar</a>.
              </p>
            </Spacing>
            <p>Se você já confirmou o email, clique no botão abaixo.</p>
            <Spacing top="4">
              <Button
                class="v--bg-meforma-pink"
                style="width: 165px"
                :loading="$wait.is('auth/checkUser')"
                @click="checkUser"
              >
                Já confirmei o email!
              </Button>
            </Spacing>
          </Spacing>
        </Spacing>
      </div>
    </div>
    <SiacModal v-if="siac" @close="closeSiacModal" @finish="finish" />
  </div>
</template>
<script>
import SiacModal from '@/components/SiacModal/SiacModal.vue'
export default {
  components: { SiacModal },
  name: 'VerifyEmail',
  title: 'Verifique seu e-mail - MeForma!',
  layout: 'blank',
  route: '/completar-cadastro',
  middleware: ['verify'],
  beforeMount() {
    if (this.$route.query && this.$route.query.id) {
      this.$store.auth.verifyEmailAddress(this.$route.query)
    }

    this.$store.university.getCourses().then((result) => {
      this.$store.university.getCourseCurriculums({
        id: result.data && result.data?.items[0]?.id
      })
    })

    if (
      this.$store.auth.user.email_verified_at &&
      this.$store.auth.user.curriculum
    ) {
      this.$router.push('/inicio')
    }
  },
  data() {
    return {
      form: {},
      siac: false,
      manual: false
    }
  },
  computed: {
    courses() {
      return this.$store.university.courses.map((item) => {
        item.name = `${item.code}: ${item.name}`
        item.value = item.id
        return item
      })
    },
    curriculums() {
      return this.$store.university.curriculums.map((item) => {
        item.name = `${item.code}`
        item.value = item.id
        return item
      })
    }
  },
  methods: {
    finish() {
      this.$router.push('/inicio')
    },
    openSiacModal() {
      this.siac = true
      this.manual = false
    },
    closeSiacModal() {
      if (this.$store.auth.user.curriculum) {
        this.$router.push('/inicio')
      }
      this.siac = false
      this.manual = false
    },
    openManualModal() {
      this.manual = true
      this.siac = false
    },
    closeManualModal() {
      this.siac = false
      this.manual = false
    },
    selectCourse(e) {
      this.form.course_id = parseInt(e.target.value)
      this.form.curriculum_id = ''

      this.$store.university.getCourseCurriculums({
        id: e.target.value
      })
    },
    selectCurriculum(e) {
      this.form.curriculum_id = parseInt(e.target.value)
    },
    startIsValid() {
      const arr = this.form.start.split('.')
      return (
        !!this.form.start &&
        arr.length === 2 &&
        arr[0].length === 4 &&
        arr[1].length === 1
      )
    },
    checkUser() {
      if (!this.$wait.is('auth/checkUser')) {
        this.$wait.start('auth/checkUser')
        this.$store.auth
          .getUser()
          .then((result) => {
            const user = result.data && result.data.data
            if (user.email_verified_at) {
              this.$toast.success('Email confirmado!')
            } else {
              this.$toast.error('Email não confirmado!')
            }
          })
          .finally(() => {
            this.$wait.end('auth/checkUser')
          })
      }
    },
    resend() {
      if (!this.$wait.is('auth/resendEmail')) {
        ;[
          this.$store.auth
            .resendEmail()
            .then(() => {
              this.$toast.success('E-mail enviado!')
            })
            .catch(() => {
              this.$toast.success('Não conseguimos enviar, tente novamente!')
            })
        ]
      }
    },
    register() {
      if (!this.curriculums.length) {
        this.$toast.error(
          'Desculpe, não podemos te cadastrar sem um currículo.'
        )
        return
      }

      if (this.startIsValid()) {
        if (!this.form.course_id) {
          this.form.course_id = this.courses[0].value
        }
        if (!this.form.curriculum_id) {
          this.form.curriculum_id = this.curriculums[0].value
        }
        this.$store.auth
          .updateProfile(this.form)
          .then(() => {
            this.$router.push('/inicio')
            this.$toast.success('Perfil atualizado!')
          })
          .catch(() => {
            this.$toast.error('Houver um erro. Tente novamente.')
          })
      } else {
        this.$toast.error('Certifique-se de ter preenchido tudo corretamente')
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.p-verify
  display flex
  align-items center
  justify-content center
  min-height 100vh
  &__title
    font-weight 700
  &__inative
    color #aaa
  &__content
    width 600px
    height 600px
    max-width 100%
    max-height 100vh
    padding 16px
  &__breadcumb
    display flex
    align-items center
    gap 8px
    grid-gap 8px
</style>
