<template>
  <div :class="{ 'v--disable-pointer-events': $wait.is('user/update') }">
    <Field
      label="Seu nome"
      sublabel="Esse é seu nome público. É a forma como vai aparecer para outros usuários."
      name="name"
      v-model="form.name"
    />
    <FieldSelect
      label="Curso"
      name="course"
      sublabel="Se seu curso não estiver na lista, importe os dados do SIAC diretamente."
      :disabled="$wait.is('university/courses') || $wait.is('user/update')"
      :selected="this.$store.auth.user.curriculum.course.id"
      :options="courses"
      @change="selectCourse"
    />
    <FieldSelect
      label="Currículo"
      name="curriculum"
      sublabel="Lembre-se de que o currículo não é necessariamente seu semestre de entrada na universidade."
      :disabled="
        $wait.is('university/courses') ||
        $wait.is('university/curriculums') ||
        $wait.is('user/update')
      "
      :selected="this.$store.auth.user.curriculum.id"
      :options="curriculums"
      @change="selectCurriculum"
    />
    <Field
      label="Semestre de entrada. Ex: 2014.1"
      sublabel="E aí? Você é de qual semestre? Eu fui de 2014.1."
      name="start"
      v-model="form.start"
      visibility-switch
      :disabled="$wait.is('user/update')"
      required
    />
    <Spacing vertical="2">
      <Button
        class="v--bg-meforma-pink"
        style="width: 94px"
        :disabled="!!$wait.any()"
        :loading="$wait.is('user/update')"
        @click="save"
      >
        Salvar
      </Button>
    </Spacing>
  </div>
</template>
<script>
export default {
  name: 'PersonalDataForm',
  data() {
    return {
      form: {
        name: this.$store.auth.user.name,
        start: this.$store.auth.user.start,
        course_id: this.$store.auth.user.curriculum.course.id,
        curriculum_id: this.$store.auth.user.curriculum.id
      }
    }
  },
  computed: {
    courses() {
      return this.$store.university.courses.map((item) => {
        item.name = `${item.code}: ${item.name}`
        item.value = item.id
        return item
      })
    },
    curriculums() {
      return this.$store.university.curriculums.map((item) => {
        item.name = `${item.code}`
        item.value = item.id
        return item
      })
    }
  },
  methods: {
    selectCourse(e) {
      this.form.course_id = parseInt(e.target.value)
      this.form.curriculum_id = ''

      this.$store.university.getCourseCurriculums({
        id: e.target.value
      })
    },
    selectCurriculum(e) {
      this.form.curriculum_id = parseInt(e.target.value)
    },
    startIsValid() {
      const arr = this.form.start.split('.')
      return (
        !!this.form.start &&
        arr.length === 2 &&
        arr[0].length === 4 &&
        arr[1].length === 1
      )
    },
    save() {
      if (!this.curriculums.length) {
        this.$toast.error(
          'Desculpe, não podemos te cadastrar sem um currículo.'
        )
        return
      }

      if (this.startIsValid() && this.form.name) {
        if (!this.form.course_id) {
          this.form.course_id = this.courses[0].value
        }
        if (!this.form.curriculum_id) {
          const isTheSame = this.curriculums.find(
            (i) => i.value == this.$store.auth.user.curriculum.id
          )
          this.form.curriculum_id = isTheSame
            ? this.$store.auth.user.curriculum.id
            : this.curriculums[0].value
        }
        this.$store.auth
          .updateProfile(this.form)
          .then(() => {
            this.$toast.success('Perfil atualizado!')
          })
          .catch(() => {
            this.$toast.error('Houver um erro. Tente novamente.')
          })
      } else {
        this.$toast.error('Certifique-se de ter preenchido tudo corretamente')
      }
    }
  }
}
</script>
