<template>
  <div
    :id="`discipline-${item.id}`"
    class="c-discipline"
    :class="{
      'v--dark__card': $store.settings.darkMode,
      'v--checked': item.done && !loading,
      'c-discipline--horizontal': horizontal
    }"
    @click="$emit('click', item)"
  >
    <div class="c-discipline__content" :class="{ 'v--loading': loading }">
      <div class="c-discipline__content-info c-discipline__content-header">
        <h2 class="c-discipline__title">
          <div class="c-discipline__status" :class="statusColor(item)"></div>
          {{ item.code }}
          <small>
            <span>•</span>
            {{ item.hours }}hs
          </small>
        </h2>
        <p class="c-discipline__subtitle">
          {{ item.name }}
        </p>
        <div v-if="item.status">
          <Icon name="bookmark" size="11" />

          {{ item.status }}
        </div>
        <div v-if="item.score && item.score !== '--'">
          <span class="c-discipline__score">
            <Icon name="award" size="11" />
            {{ item.score }}
          </span>
        </div>
      </div>
      <AbsenceCounter class="c-discipline__content-info" :item="item" />
    </div>
  </div>
</template>
<script>
import color from '@/helpers/color'

import AbsenceCounter from '@/components/semester/AbsenceCounter/AbsenceCounter'

export default {
  components: { AbsenceCounter },
  name: 'Discipline',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    background() {
      return color.string2Hex(this.item.department)
    }
  },
  methods: {
    statusColor(item) {
      if (
        (!item.score || item.score === '--') &&
        item.status === 'Dispensa UFBA'
      ) {
        return 'v--bg-grey'
      }

      const list = {
        Aprovou: 'v--bg-success',
        Dispensou: 'v--bg-success',
        'Dispensa UFBA': 'v--bg-success',
        Reprovou: 'v--bg-fail',
        Faltou: 'v--bg-fail',
        Trancou: 'v--bg-warning',
        AO: 'v--bg-success'
      }
      return list[item.status]
    }
  }
}
</script>
<style lang="stylus" src="./style.styl" scoped></style>
